import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const gotoLoginPage = () => {
    navigate('/');
  }
  return (
    <>
      <div class="container-fluid">
        <div onClick={gotoLoginPage}>
          <img src="../../Assets/Linqskills_Icons/breadcrumb-back.svg" class=" float-start display-desktop cursor-pointer hamburger-margin"></img>
          <img src="../../Assets/Linqskills_Icons/breadcrumb-back.svg" class=" float-end display-mobile  cursor-pointer hamburger-margin"></img>
        </div>

        <div class="container pb-3">

          <div class="mb-3 mt-3 ">
            <h3>LinQ Skills LMS Privacy Policy</h3>

          </div>
          <div>
            <h5>
              Last updated on - 19/04/2024
            </h5>
            <h4>
              Introduction
            </h4>

            <p>
              LinQ SkillsTM, registered Trademarks, and Linqskills.in, registered domains, are owned by Schoolnet India Ltd, subject to the following Privacy Notice.
            </p>

            <p>
              When you use LinQ Skills products and services, some of your Personal Data is collected by us. We are committed to protecting the security of this data and safeguarding your privacy. This Privacy Notice outlines the details of the Personal Data collected, the manner in which it is collected, and the purposes for which it is used.
            </p>

            <p>
              Throughout this document, the terms “LinQ Skills”, “we”, “us”, “our” & “ours” refer to  Schoolnet India Ltd. And the terms “you”, “your” & “yours” refer to YOU (the individual whose Personal Data we are referring to).
            </p>
            <p>
              We process any Personal Data we collect from you in accordance with the provisions of this Privacy Notice. Please read the following carefully to understand our practices regarding your Personal Data.
            </p>

            <h4>
              What Personal Data Do We Collect, Store and Process?
            </h4>
            <p>
              Categories of Personal Data that we might collect, store and process is as follows:
            </p>
            <p>
              1.	Demographic, Identity & Contact Data (for e.g., name, email address, contact number)
            </p>
            <p>
              2.	Socio-Demographic Details (for e.g., profession, education data)
            </p>
            <p>
              3.	Authentication Data (for e.g., username, password)
            </p>


            <h4>
              Where Do We Collect Your Personal Data From?
            </h4>


            <p>
              We collect your Personal Data in following ways:
              When you use our services, you provide us with two types of information: (i) information you submit via the Service and (ii) information regarding your use of the Service collected by us as you interact with the Service.

            </p>


            <p>
              When you use the Service you may submit information and content to your profile, generate Activity Data through engaging in educational activities on the Service, or send messages and otherwise transmit information to other users. We store this information so that we can provide you the Service and offer personalised features.

            </p>

            <p>
              When you use the Service to participate in the LinQ Skills online classes, you may be required to provide access to your computers’ microphone. We may record you during the period of the class and may store that information to use as we may deem fit to improve the Service.
            </p>

            <p>
              We may also receive your Personal Data from other people if they have shared your Personal Data with us. For e.g., if they provide your reference to us.
            </p>

            <h4>
              How Do We Use Your Personal Data?
            </h4>

            <p>
              We use your Personal Data for the following purposes:
            </p>
            <p>
              1.	To deliver our products and services
            </p>
            <p>
              2.	To communicate with you in the normal course of service delivery and provide information relevant to you as a customer including about additional products and services offered by us.
              1.	In some instances, you may avail of our product or service at the behest of a sponsor including your employer, educational institution and others. We may share information related to the use of the product or service as per agreement with such sponsor.

            </p>

            <p>
              3.	To respond to you when you contact us with your requests, enquiries, comments, and complaints
            </p>

            <p>
              4.	To comply with legal or regulatory requirements.
            </p>

            <p>
              5.	To evaluate, develop and improve our products and services
            </p>

            <h4>
              On What Lawful Grounds Do We Process Your Personal Data?
            </h4>

            <p>
              We process your Personal Data by relying on one or more of the following lawful grounds:
            </p>

            <p>
              1.	You have consented to us processing your Personal Data for specified reasons
            </p>

            <p>
              2.	The processing is necessary for compliance with a legal obligation we may have (for e.g., towards law enforcement agencies, government agencies, regulators, etc.)</p>

            <p>
              3.	The processing is necessary for provision of certain services to you. For e.g.,
              1.	To communicate with you in the normal course of service delivery and provide information relevant to you as a customer including about additional products and services offered by us.
              2.	to evaluate, develop and improve our products and services
            </p>

            <p>
              Where the processing is based on your consent, you have the right to withdraw your consent at any point in time. Upon receipt of your request to withdraw your consent, the consequences of withdrawal will be communicated to you. In many cases, upon such a withdrawal, we may not be able to continue offering our products and services to you.
            </p>



            <h4>Who Do We Share Your Personal Data With?</h4>

            <p>
              We may provide personal data information to service providers who help us bring you the Service, such as hosting the Service at a co-location facility or sending notifications.
            </p>
            <p>
              We may also share Personal Data with our partners for business and operational purposes. All such transactions will be subject to obligations consistent with this Privacy Notice and our contracts with them.
            </p>
            <p>
              We may also disclose your Personal Data to appropriate authorities if we believe that it is reasonably necessary to comply with a law, regulation, legal process. We may disclose your Personal Data to law enforcement agencies, government agencies, courts, dispute resolution bodies, regulators, auditors, and any party appointed or requested by applicable regulators to carry out investigations or audits of our activities.
            </p>

            <p>
              LinQ Skills™ may use or share aggregate or anonymous data collected through the Service, including Activity Data, for purposes such as understanding or improving the service.
              If the ownership of all or substantially all of the LinQ Skills™ business, or individual business units or assets owned by Schoolnet India Ltd that are related to the Service were to change, your personal data information may be transferred to the new owner. In any such transfer of information, your personal data information would remain subject to this section.

            </p>

            <h4>
              International Data Transfer
            </h4>
            <p>
              Personal Data we hold about you may be transferred to other countries outside your country of residence for any of the purposes described in this Privacy Notice. Any Personal Data that we transfer will be protected in accordance with this Privacy Notice.
            </p>

            <h4>
              How Do We Secure Your Personal Data?
            </h4>
            <p>
              We are committed to protecting your Personal Data in our custody. We take reasonable steps to ensure appropriate physical, technical, and managerial safeguards are in place to protect your Personal Data from unauthorized access, alteration, transmission, and deletion. We train our employees about the importance of maintaining the privacy and security of your Personal Data. We ensure that the third parties who provide services to us under appropriate contracts take appropriate security measures to protect your Personal Data in line with our policies.
            </p>

            <h4>
              How Long Do We Keep Your Personal Data?
            </h4>
            <p>
              We retain your Personal Data for as long as it is required to fulfil the purposes outlined in this Privacy Notice and for legal or regulatory reasons.
            </p>


            <h4>
              How Do We Ensure Children’s Privacy?
            </h4>

            <p>
              Our products and services are not designed or directed at children. We do not knowingly collect Personal Data from children. If you are a parent or guardian and aware that your Child has provided us with Personal Data, please contact us using the details in the ‘Contact us’ section of this notice.
            </p>



            <h4>
              How Do We Use Cookies?
            </h4>

            <p>
              We use cookies on our website and other digital properties to collect data about you.
              Cookies are small text files that are placed on your computer by websites that you visit. We also use performance/analytics cookies on our website. These cookies collect information about how visitors use our website and services, including which pages of the website are visited most often or if they receive error messages from certain web pages. It is used to improve the functions and performance of our website as the user interacts with it.

            </p>


            <h4>
              What Are Your Privacy Rights?
            </h4>

            <p>
              You have a right to access the data that you have provided.  You also have a right to update or make corrections to data that you have provided. The accuracy of such data is solely your responsibility.
              If you wish to make a request to exercise any of your rights, you can contact us by emailing us at info@linqskills.in.


            </p>


            <h4>
              Links to Other Websites
            </h4>

            <p>
              Our website includes hyperlinks to websites of other organizations. We have no control over, and we are not responsible for the privacy policies and practices of the other websites. This Privacy Notice does not cover the privacy practices of those organizations. We encourage you to read the Privacy Notices on the other websites you visit.


            </p>


            <h4>
              How Do We Keep This Notice Up To Date?
            </h4>

            <p>
              We regularly review and update our Privacy Notice to ensure it is up-to-date and accurate. Any changes we may make to this Privacy Notice in the future will be posted on this page. When we post changes to this Privacy Notice, we will revise the “last updated” date.

            </p>


            <h4>
              How Do You Contact Us?
            </h4>

            <p>
              For any further queries and complaints related to privacy, or exercising your rights, you could reach us at:<a href="#">info@linqskills.in
              </a>
            </p>

          </div>



        </div>
      </div>

    </>
  )
}

export default PrivacyPolicy

