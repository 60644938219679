

import React, { useState,useEffect } from "react";
import { Redirect,useSearchParams } from "react-router-dom";
import NavOne from '../NavOne';

import Cookies from 'universal-cookie';
import EligibilityCoursecard from '../EligibilityCheck/EligibilityCoursecard';
import EligibilityCheckContent from '../EligibilityCheck/EligibilityCheckContent';
import Breadcrumb from '../CourseIndex/Breadcrumb';
import AssignmentInstructions from '../CourseAssignments/AssignmentInstructions';

import { variables } from '../Shared/Variables'
import Accordion from "../Shared/Accordion.js";

/*import PopupOne from '../Shared/PopupOne';*/

export default function AssignmentsSessions() {


    return (
        <>
            {/*   <PopupOne/>*/}
            <NavOne />
            
            <div class="container-fluid body-wrapper bg-default">

            <div class="container-fluid desktop-padding">

                <div class="container">
                <Breadcrumb redirectLink="CourseIndex" />
                </div>
                 

                    <div class="row pb-4">
                      <AssignmentInstructions/>
                  
                        {/* <EligibilityCoursecard
                            sectorName={course.sectorName}
                            courseName={course.courseName}
                            description={course.description}
                            courseIcon={variables.AWSPath + variables.AWSFolder + course.icon}
                        /> */}
                      
                        {/*<div class="col-lg-9 col-sm-12 col-xs-12 content-cards">*/}
                        {/*    <Accordion courseId='1' courseName='Eligibility Check' data={data} />*/}
                        {/*    </div>*/}
                        {/* <EligibilityCheckContent/> */}
                        </div>
                   
                       
                            
                        </div>

            </div>
            </>
    )

}


