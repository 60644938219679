import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parentNode: '',
  childNode:'',
  extendedBreadCrumb: "false",
  grandchildNode: '',
  greatgrandchildNode: '',
  currenNodeIdAt : "",
};

const breadcrumbSlice = createSlice({
    name:"breadcrumb",
    initialState,
    reducers:{
        updateParent : (state,action)=>{
            state.parentNode = action.payload
        },
        updateChild : (state,action)=>{
            state.childNode = action.payload
        },
        updateExtended : (state,action)=>{
            state.extendedBreadCrumb = action.payload
        },
        updategrandChild : (state,action)=>{
            // // console.log("updating grand child at : ",action);
            state.grandchildNode = action.payload
        },
        updateggChild : (state,action)=>{
            // // console.log("updating gg child : ",action);
            state.greatgrandchildNode = action.payload
        },
        updateCurrentNodeIdAt : (state,action)=>{
            // // console.log("updating current node at : ",action);
            state.currenNodeIdAt = action.payload
        },
    }
})

export const { updateChild,updateParent,updateExtended,updateggChild,updategrandChild,updateCurrentNodeIdAt} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;