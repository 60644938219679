import React from 'react';

const ResourceLibraryIndex = ({ title }) => {
    return (
        <div>
            <div className="accordion mb-4 p-0" id="accordionExample">
                <div class="accordion-item box-shadow">
                    <div>
                        <div class="accordion-header topic-header s" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="pink-box me-2"><img src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png" class="me-1"></img></div>
                                <h5 class="m-0 text-truncate">Resource Library</h5>
                            </button>
                        </div>
                        <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="accordionExample"
                        >
                        <div class="accordion-body border-top pb-3">
                        <div class="row pb-3 ps-2"  >
                            <p class="bold-500">
                                There are no resources mapped to this course
                            </p>
                        </div>
                        </div>
                        </div>
                    </div >
                </div>
            </div>
        </div>
    );
}

export default ResourceLibraryIndex;
