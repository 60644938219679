const TimerFormat = (n) =>{
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if(rhours == 0){
      return rminutes + " Min";
    } else if(rminutes == 0) {
      if(rhours < 10){
        return `0${rhours}` + " Hr"
      } else {
        return rhours + " Hr"
      }
    } else {
      if(rhours < 10){
        return `0${rhours}` + " Hr"+ " " + rminutes + " Min";
      } else {
        return rhours + " Hr"+ " " + rminutes + " Min";
      }
    }
}


export {TimerFormat} 