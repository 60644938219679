import React, { useState, useEffect } from "react";
import { Redirect, useNavigate } from "react-router-dom";
import FooterRgsa from "../rgsaLogin/FooterRgsa";
import { useSelector,useDispatch } from "react-redux";
import {
    callingNextPrev,
    updateFilterRetained,
    updateNextPrevUpdated,
    updateIndex,
    updateVideo,
    updateNodeVal,
    callingDispose,
    updateOnlineLec
  } from "../../features/CourseIndex/courseIndexSlice";


export default function TitleHeader({title,url}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const goBackToMyCourse = async () => {
        // if(props.setIsBackBtnClicked){
        //     await props.setIsBackBtnClicked(true);
        //     // alert("working for back btn click on self study page")
        //     // dispatch(updateVideo(false));
        // }
       await dispatch(callingDispose(true));
       navigate(url)
    }

    return (
        <>     
           <div class="container-fluid pe-3 ps-3 Indexpage-Rgsa-header">
        <div class="container pt-3">
          <div class="col-md-12 p-0 d-flex mb-3">
              <div class="col-1 logo-rga p-0">
              <img onClick={goBackToMyCourse} src="../../Assets/Linqskills_icons/back-btn-rgsa.svg" class="height-btn"></img>
              </div>
              <div class="mt-1 col ms-3">
              <span class="heading-two-rgsa mb-4 line-clamp-one">{title}{/* રાષ્ટ્રીય ગ્રામ સ્વરાજ અભિયાન */}</span>        

              </div>
                   
          </div>

      
          </div>
                 
        </div>


        </>
    )
}

                                  
{/* <div className="container d-flex login-footer mobilefooter">
                                   
<div className="col-6 col-sm-12 ps-3 poweredby">
    <span>f <span className="span-bold">
      sss</span></span>
    <span className="ps-3 footertext">
    sdsd <span className="span-bold">
        dsdsss</span></span>
</div>
<div className="col-6 text-right pe-3 footerdetails display-desktop">
    <span className="ps-3">
    sdsd</span>
</div>

</div> */}