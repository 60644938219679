import {useEffect} from "react";

// Object in which we will save our scroll position state
const scrollPositions = {};

/* custom hook which will save the scroll state and also set 
the scroll position of our page */
const useScrollPosition = (page) => {

    useEffect(() => {

// Get the scroll state if it exists

        const pageScrollPosition = scrollPositions[page];

// If it exists then set the scroll position of our page

        if (pageScrollPosition) {

/* Using setTimeout to set the scroll position of the 
page as useEffect gets triggered before the page has 
actually rendered */

            setTimeout(() => {
                window.scrollTo(0, pageScrollPosition);
            }, 50)

        }

/* save function to save the scroll position in 
the scrollPositions object */

        const save = () => {
            scrollPositions[page] = window.scrollY;
        }

/* Attaching an event listener to listen for a scroll event 
and then fire the save function everytime a scroll event occurs */

        window.addEventListener('scroll', save)

/* Removing the event listener when the component unmounts*/

        return () => {
            window.removeEventListener('scroll', save)
        };
    }, [page]);
}

export default useScrollPosition;

















// // ScrollTracker.js
// import { useEffect, useState } from 'react';

// const ScrollTracker = () => {
//   const [scrollPosition, setScrollPosition] = useState(0);

//   const handleScroll = () => {
//     const currentPosition = window.pageYOffset;
//     setScrollPosition(currentPosition);
//     localStorage.setItem('scrollPosition', currentPosition.toString());
//   };

//   useEffect(() => {
//     // Retrieve the scroll position from localStorage when the component mounts
//     // console.log('Effect is running in scrool');
//     const savedScrollPosition = parseInt(localStorage.getItem('scrollPosition')) || 0;
//     setScrollPosition(savedScrollPosition);
//     window.scrollTo(0, savedScrollPosition);

//     // Attach the scroll event listener
//     window.addEventListener('scroll', handleScroll);

//     // Remove the event listener when the component is unmounted
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []); // empty dependency array means this effect will only run once

//   return (
//     <div>
//       <p>Scroll Position: {scrollPosition}</p>
//       {/* Your other components go here */}
//     </div>
//   );
// };

// export default ScrollTracker;
