

import React, { useState, useEffect } from "react";
import NavOne from '../../components/NavOne.js';
import Breadcrumb from '../CourseIndex/Breadcrumb';
import TrainerMessage from "../CourseIndex/TrainerMessage.js";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WelcomeMenu from "./WelcomeMenu.js";






const HelpVideos = () => {
    return (
        <>

            <NavOne ></NavOne>
           
            
            <div class="container-fluid body-w rapper bg-default">
                <div class="container pt-4 pb-4">
                    {/* <WelcomeMenu />         */}
                    {/* <TrainerMessage></TrainerMessage> */}
                    <TrainerMessage pageName='MyCourse' text="Before you begin, watch the video on a tour of the LMS" title="mycourse001" messageType="video" />
                    <TrainerMessage pageName="CourseContent" text="Before you begin, watch the video on how to view course content" title="coursecontent001" messageType="video" ></TrainerMessage>

                </div>
            </div>

         


        </>


    )
}
export default HelpVideos
{/* <Breadcrumb redirectLink="mycourses"  /> */}