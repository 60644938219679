

import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import NavOne from '../../components/NavOne.js';
import Translate from '../Shared/Translate';
import Cookies from 'universal-cookie';
import EGAList from './EGAList';
import InstructionsContent from './InstructionsContent';
//import PopupOne from '../Shared/PopupOne';
import Breadcrumb from '../CourseIndex/Breadcrumb';
import { updateChild, updateParent } from "../../features/breadcrumb/breadcrumbSlice";
import { useDispatch } from "react-redux";

//import  VirtualSession from '../Shared/VirtualSession';


export default function EGADetails() {
    var data = JSON.parse(sessionStorage.getItem('TestData'));
    var courseName = sessionStorage.getItem("courseName");
    var courseId = sessionStorage.getItem("courseId");
    var coursenodeId = sessionStorage.getItem("courseNodeId");
    var LessionPlanId = sessionStorage.getItem("lessionPlanId");
    // // console.log("data from session TestData : ",data)
    sessionStorage.setItem("userSelectedTestLan", data.selectedLanguage);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateParent('Eligibility Check'))
        dispatch(updateChild(data.testName))
    }, [])

    // console.log("on egaDeatils data.completedOn : ", data.completedOn)

    return (
        <>

            <NavOne mobileView={true} ></NavOne>

            <div class="container-fluid body-w rapper bg-default">
                <div class="container p-0">
                <Breadcrumb redirectLink="eligibility" />
                </div>
                <div class="container ">


               
                    <div class="pb-4">

                        {/* <EGAList UserId={data.userid} CourseId={courseId} ProjectId={data.projectId} CourseNodeId={coursenodeId} LessionPlanId={LessionPlanId} /> */}
                        <InstructionsContent courseName={courseName} UserId={data.userid} TestId={data.quizID} duration={data.duration} templateId={data.templateId} TestStatus={data.testStatus} ProjectId={data.projectId} CourseId={courseId} CourseNodeId={coursenodeId} LanguageId={1} LessionPlanId={LessionPlanId}
                            CompletedOn={data.completedOn}
                            TestName={data.testName}
                            TestLanguageIds={data.testLanguageIds}
                        />

                    </div>

                </div>

            </div>
        </>
    )

}


