import React from "react";



//import PopupOne from '../Shared/PopupOne';

export default function AdminNavbarTwo() {
    return (
        <>
           <nav class="navbar navbar-expand-lg navbar-light bg-default p-0 admin-navbar ">

                
<div class="navbar-collapse nav-show mb-3 " id="navbarSupportedContent">

      <ul class="navbar-nav justify-content-center mx-auto mb-lg-0 col-lg-9 false responsive-nav undefined border-bottom pb-3">
      <li class="nav-item menu-w text-lg-center text-sm-center ">
        <img src="../../Assets/Linqskills_Icons/performance-Grey.svg" class="cursor-pointer"></img>
          <a class="nav-link  m-0 p-0 hide-text ">My Performance</a>
          </li>
          <li class="nav-item menu-w text-lg-center text-sm-center display-desktop">
        <img src="../../Assets/Linqskills_Icons/Admin/candidate-grey.svg" class="cursor-pointer"></img>
          <a class="nav-link  m-0 p-0 hide-text ">Enrollment</a>
          </li>
          <li class="nav-item menu-w text-lg-center text-sm-center ">
        <img src="../../Assets/Linqskills_Icons/Admin/batches-grey.svg" class="cursor-pointer"></img>
          <a class="nav-link  m-0 p-0 hide-text ">Batches</a>
          </li>
          <li class="nav-item menu-w text-lg-center text-sm-center ">
        <img src="../../Assets/Linqskills_Icons/notification-grey.svg" class="cursor-pointer"></img>
          <a class="nav-link m-0 p-0 hide-text ">Notification</a>
          </li>

          <li class="nav-item menu-w text-lg-center text-sm-center display-desktop">
        <img src="../../Assets/Linqskills_Icons/Admin/tdmModel-grey.svg" class="cursor-pointer"></img>
          <a class="nav-link  m-0 p-0 hide-text ">Details</a>
          </li>
          <li class="nav-item menu-w text-lg-center text-sm-center display-desktop">
        <img src="../../Assets/Linqskills_Icons/userprofile-default.svg" class="cursor-pointer"></img>
          <a class="nav-link  m-0 p-0 hide-text ">Users</a>
          </li>
          <li class="nav-item menu-w text-lg-center text-sm-center display-desktop">
        <img src="../../Assets/Linqskills_Icons/notes-Grey.svg" class="cursor-pointer"></img>
          <a class="nav-link m-0 p-0 hide-text ">Notes</a>
          </li>
      </ul>
</div>
</nav>
        </>
    )

}


