import React from "react";
import Translate from './Translate';

export default function TextField(props) {
    // // console.log("props.readOnly : ",props?.readOnly); 
    var divClass = props.errorKey != "" && props.errorKey != undefined ? "labelWrap error-msg" : "labelWrap";
    const [translatedvalue, setTranslateValue] = React.useState("");
    const textboxClass = props.disable ? "form-inputOne input-bg disable-state" : "form-inputOne input-bg";

    const setvalue = (translatedvalue) => {
        setTranslateValue(translatedvalue);
    };


    if (props.type === "textarea") {
        return (
            <div className="labelWrap">
                <textarea name={props.name} className="form-inputOne input-bg" rows="4" value="" />
                <label htmlFor={props.id}>{props.label}
                    <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" className="ms-1"></img> 
                </label>
            </div>
        )
    } else {
        return (
            <>
                <label style={{ display: 'none' }}>
                    <Translate textKey={props.placeholderkey} setvalue={setvalue} />
                </label>


                <div className={divClass}>


                    <input id={props.id} name={props.name} type={props.type} defaultValue={props.value}
                        placeholder={translatedvalue}
                        autoComplete="off"
                        onChange={props.onchange} className={textboxClass}
                        readOnly={props?.readOnly ? props?.readOnly : false}
                        maxLength={props.maxlength}
                        onKeyUp={props.keyup}
                    >
                    </input>




                    <label htmlFor={props.id}><Translate textKey={props.textKey} />{/*{props.label}*/}
                        {
                            props.isIndia == "102" || !props.isMandate ? "" : 
                            <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" className="ms-1"></img>
                        }
                    </label>

                </div>
                {
                    props.errorKey != "" && props.errorKey != undefined &&
                    <>
                        <div class="d-flex">
                            <div>
                                <img src="../../Assets/Linqskills_Icons/LoginError.svg" className=""></img>
                            </div>
                            <div class="pt-2">
                                <p className="login-error h7 mb-0 align-self-center"><Translate textKey={props.errorKey} /></p>

                            </div>
                        </div>

                    </>
                }
                {
                    props.infoKey != "" && props.infoKey == undefined &&
                    <>
                        <div className="form-check mt-2 ps-2 d-flex">
                            <p className="form-check-label ms-4 h7" htmlFor="exampleCheck1">
                                <img src="../../Assets/Linqskills_Icons/help.svg" class="me-2 inst-icon"></img>
                                <Translate textKey={props.infoKey} />
                            </p>
                        </div>
                    </>
                }
            </>
        )
    }
}