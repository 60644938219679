import React, { useState,useEffect } from 'react';
//import Message from './components/message';
import Translate from '../Shared/Translate';
import LanguagePopup from './LanguagePopup';

import {
  updateFilterVal,updateIndex

} from "../../features/CourseIndex/courseIndexSlice";
import { useDispatch,useSelector } from "react-redux";


export default function FilterOption(props) {
  
  const dispatch = useDispatch();
  const index = useSelector(state=>state.courseindex.index)
  const [toggle,setToggle] = useState(false);
  
  const [openLanguage, setOpenLanguage] = useState(false)
  // const [totalLanguages, setTotalLanguages] = useState([]);
  
  useEffect(()=>{
    setToggle(!toggle)
  },[index])
  const tpoicFilter = (e) => {
    // console.log("Working in tpoicFilter : ", e.target.value);
    props.setFilterVal(e.target.value);
    if(e.target.value == ""){
      props.setActiveAccordion(0);
      dispatch(updateIndex("CO"));
    } else {
      props.setActiveAccordion(1);
      if(e.target.value == "4"){
        dispatch(updateIndex("AA"));
      } else if (e.target.value == "2"){
        dispatch(updateIndex("SS"));
      }
    }
    dispatch(
      updateFilterVal(
        e.target.value
      )
    );
  }

  const openLanguagePopUp = () => {
    // console.log("opening language popup");
    setOpenLanguage(true);

  }
  function closeLanguage() {
    // console.log("language popup closing")
    setOpenLanguage(!openLanguage);
  }
  
  // // console.log("in filterOpitins : props.totalLanguages ; ",props.totalLanguages)
  // // console.log("filter index : ",index);
  // console.log("props.filterVal : ",props.filterVal)

  return (
    <>
       {openLanguage ? 
      <LanguagePopup
        closeEvent={closeLanguage}
        okEvent={closeLanguage}
        headerKey="Login014"
        availableLanguages={props.totalLanguages}
      /> : ""}
      <div class="d-flex justify-content-between mb-4">
      
        <div class="row g-2 col-md-4 cursor-pointer" style={(index == "CO" || index == "SS" || index == "AA") ? {visibility: "visible"} : {visibility : "hidden"}} >
          <div class="col-md">
            <div class="form-floating ">
              <select class="form-select bold-500 cursor-pointer" value={props.filterVal} onChange={tpoicFilter} id="floatingSelectGrid" aria-label="Floating label select example">
                <option value="" ><Translate textKey="coursecontent003" /></option>
                <option value="4"><Translate textKey="coursecontent006" /></option>
                <option value="1"><Translate textKey="coursecontent005" /></option>
                <option value="2"><Translate textKey="coursecontent004" /></option>
                
              </select>
              <label class="float-label ms-3" for="floatingSelectGrid"><Translate textKey="coursecontent002" /></label>
            </div>
          </div>
        </div>
        <div class="pt-2" onClick={openLanguagePopUp}>
          <div className="col-md-12 p-0" >
            <div className="language-select float-end text-uppercase" >
              <img src="../../Assets/Linqskills_Icons/Language_16.svg" className="pe-1 mb-1px"></img><span class="align-self-center cursor-pointer">{props.lan}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

// comment
// commented code 


{/* <img src="../../Assets/Linqskills_Icons/arrow_down_ios_24px.svg" class="dropdown-arrow"></img> */ }



{/*<select className="select-lang" value={language} onChange={handleChange} >*/ }
{/*    <option value="en" key="en">EN</option>*/ }

{/*    {languageOptions.map((language) => (*/ }
{/*        <option value={language.value} key={language.value}>{language.label}</option>*/ }
{/*    ))}*/ }
{/*</select>*/ }


{/* <div class="w-auto dropdown-btn cursor-pointer">
<div class="select-dropdown primary-btn cursor-pointer">

<img src="../../Assets/Linqskills_Icons/admin/batches-white.svg" class="ms-2 mt-1 batches-left"></img>

<img src="../../Assets/Linqskills_Icons/arrowdown-white.svg" class="float-end arrow-down cursor-pointer"></img>
 <select class="cursor-pointer image-padding">
   <option value="Option 1 text-center" data-title="../../Assets/Linqskills_Icons/video24_white.svg">
     View Details</option>
 
   <option value="Option 2">

     Register Participants</option>
   <option value="Option 3">Option Number 3</option>
 </select>
</div>
</div>  */}