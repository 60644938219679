// src/ProgressBar.js
import React from 'react';
import { ProgressBar as BootstrapProgressBar } from 'react-bootstrap';

const ProgressBar = ({ completionPercentage }) => {
  return (
    <div class="w-100 ps-1 pe-3">
      <BootstrapProgressBar now={completionPercentage} />
    </div>
  );
};

export default ProgressBar;
