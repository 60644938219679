import { isDisabled } from "@testing-library/user-event/dist/utils"

export default function Secondarybtn(props) {
    return (
        <>
            <button id="btnSubmit" type="button" className="text-uppercase continue-popup secondary-btn w-100"  onClick={props.buttonClick}>{props.buttonVal}
                <img src="../../Assets/Linqskills_Icons/arrow_forwardblue.svg" className=""></img>
            </button>   
        </>
    )
}

// disabled={isDisabled} className={isDisabled ? "disabled-btn text-uppercase continue-popup secondary-btn w-100" : "text-uppercase continue-popup secondary-btn w-100"}