import { Modal } from 'react-bootstrap';
import Translate from "../../components/Shared/Translate"
import VideoPlayer from "../../components/Shared/VideoPlayer";
import { useRef ,useEffect} from 'react';
import PdfComponent from '../../components/Shared/pdfComponent';
import PDFViewer from '../../components/Shared/pdfView';


const ViewTutorialPopUpRgsa = (props) => {
  console.log("props in ViewTutorialPopUp : ",props);
  const embedRef = useRef(null); 
 

  useEffect(() => {
       // Access the embed element using embedRef.current
      //  alert('hello')
     
      // Add the event listener for the embed element when the component mounts
      if (embedRef.current) {
        // alert('hello')
        // console.log(embedRef.current,"embed reffff")
        embedRef.current.addEventListener('contextmenu',  (e) => {
         
          e.preventDefault();
        });
      }

     }, []);
 
  //// console.log(JSON.stringify(embedRef)+"embed")

  

  return (
    <div className='popup-video-modal'>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`couponAppliedpop ${props.extensionType==='pdf' && 'view-pdf' }`}
      backdrop={false}
    >
      <Modal.Body>
        <div class="popup-center text-lg-left" oncontextmenu="return false;" >
          <div className='d-flex justify-content-between pb-3 align-items-center'>
            {
              props.showHeading ?              
              <div>
                <h6 class="p-0 m-0">
                  <Translate textKey={props.title} />
                </h6>
              </div>
              : <></>
            }
            <div class="col">
              <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="cursor-pointer float-end"
                onClick={props.onHide}
              ></img>
            </div>
          </div>
          {
            (props.extensionType != "") && (props.extensionType != undefined) ?
              props.extensionType == "pdf" ? <PdfComponent url={props.src} ></PdfComponent> : props.extensionType == "mp4" ? <VideoPlayer src={props.src} autoPlay={props.autoPlay? true : false} /> : ""
            : <VideoPlayer src={props.src} autoPlay={props.autoPlay? true : false} />
          }
          
        </div>
      </Modal.Body>
    </Modal>
    <style>
      {`
      .modal-content {
        border-radius: 16px !important;
      }
      `}
    </style>
    </div>
  )
}

export default ViewTutorialPopUpRgsa;

