
import React, { useEffect, useState } from "react";
import { variables } from '../Shared/Variables';
import Translate from '../Shared/Translate';
import { updateIsActiveLi } from "../../features/navbar/navbarSlice";
import {  useDispatch } from "react-redux";


import { type } from "@testing-library/user-event/dist/type";

export default function ProfileCard({ userProfileData,isShow,setIshow,isShowSettings,setIsShowSettings }) {
  // // console.log("props in userProfileData : ", userProfileData);
  const [userProfileImage, setUserProfileImg] = useState("");
  const [activeTab, setActiveTab] = useState("Edit Password")
  // // console.log("userProfileImage : ", variables.AWSPath+userProfileImage);
  const dispatch = useDispatch();

  useEffect(()=>{
     setUserProfileImg(userProfileData?.profilePic)
  },[userProfileData])
  const isShowHandler = (type) =>{
    // // console.log("type : ", type)
    setIshow(type)
  } 
  const handleSettingShow = (type) =>{
    // // console.log("handleSettingShow : ",type);
    setIsShowSettings(type);
    setActiveTab(type);
  }

  useEffect(()=>{
    const comingfromeo = sessionStorage.getItem("comingfromeo")
    if(comingfromeo){
      isShowHandler("setting")
      setActiveTab("Update Contact")
     setIsShowSettings("Update Contact")
     dispatch(updateIsActiveLi("edit"));

    }
    return ()=>{
      sessionStorage.removeItem("comingfromeo")
    }
    
  },[])
  

  return (
    <>
      <div class="col-lg-3 col-sm-12 col-xs-12 courseInside-Lmenu">
    
        <div class="card primary-radius mb-5">
          <div class="card-body cardbody-height h-auto p-0">

            <div class="profile-icon p-3">
              <div onClick={()=>isShowHandler("edit")} class="edit-profile d-flex">
                <img src="../../Assets/Linqskills_Icons/edit-white.svg" className="w-auto  float-end"></img>
                <h6 class=" float-end mb-0 mt-2 cursor-pointer"><Translate textKey="userprofile019"/></h6>
              </div>
              <div class="col-md-12 text-center profile-name pt-4">
                <div class="profile-image">
                  {/* image to be set */}
                {
                  userProfileImage?.length  > 0 ? <img src={`${variables.AWSPath}${userProfileImage}`} className=""></img> : <img src="../../Assets/Linqskills_Icons/Profile-Large.svg"></img>
                }
                </div>
                
                <h5 class="">{userProfileData?.fullName}</h5>
                <h6><Translate textKey="userprofile005"/></h6>
              </div>
            </div>

            {isShow == "home" ?
              <>
                <div class="profile-card p-3">
                  <div class="row">
                    <div onClick={()=>isShowHandler("setting")}  class="edit-profile edit-blue col-md-12">
                      <h6 class=" float-end mb-0 mt-2 cursor-pointer">Edit</h6>
                      <img src="../../Assets/Linqskills_Icons/edit-blue.svg" className="w-auto  float-end"></img>

                    </div>
                  </div>
                  {userProfileData?.mobile? <div class="profile-credentials d-flex">
                    <div >   <img src="../../Assets/Linqskills_Icons/Phone-Profile.svg" className="w-auto text-center"></img>
                      </div>
                      <div>
                      <h5 class="mb-0">{userProfileData?.mobile}</h5>
                        </div>
                 
            
                  </div>:""}
                  {userProfileData?.email ? <div class="profile-credentials d-flex">
                    <div>
                    <img src="../../Assets/Linqskills_Icons/Email-Profile.svg" className="w-auto text-center"></img>
                      </div>

                      <div class="email-width">
                        
                    <h5 class="mb-0 ">{userProfileData?.email}</h5>
                      </div>

             
                  </div> : ""}
                  
                </div>
              </>
              : isShow == "setting" ?
              <>
                <div class="profile-settings w-100 p-3 display-desktop">
                  <h6 onClick={()=>handleSettingShow("Edit Password")} class={activeTab == "Edit Password"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>{activeTab == "Edit Password"? <img src="../../Assets/Linqskills_Icons/password-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/password-default.svg" className="w-auto text-center pe-2"></img>}
                  <Translate textKey="userprofile001"/>
                  </h6>
                  <h6 onClick={()=>handleSettingShow("Update Contact")} class={activeTab == "Update Contact"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>{activeTab == "Update Contact"? <img src="../../Assets/Linqskills_Icons/mail-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/mail-default.svg" className="w-auto text-center pe-2"></img>}
                  <Translate textKey="userprofile002"/>
                  </h6>
                  <h6 onClick={()=>handleSettingShow("User Preferences")} class={activeTab == "User Preferences"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>
                  {activeTab == "User Preferences"? <img src="../../Assets/Linqskills_Icons/settings-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/settings-default.svg" className="w-auto text-center pe-2"></img>}    
                  <Translate textKey="userprofile003"/>
                  </h6>
                  <h6 onClick={()=>handleSettingShow("Organisation")} class={activeTab == "Organisation"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>
                  {activeTab == "Organisation"? <img src="../../Assets/Linqskills_Icons/b-icons-24_Organisation-filled.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/b-icons-24_Organisation-Default.svg" className="w-auto text-center pe-2"></img>}    
                  <Translate textKey="postregform002"/>
                  </h6>
                </div>
              </> : ""
            }          
        </div>
      </div>

      {/* profile-settings menu visible only for mobile view */}
      {isShow == "setting" ? 
      <>
      <div class="accordion mb-4 p-0 display-mobile" id="accordionExample">
        <div class="accordion-item box-shadow ">
          <div class="accordion-header topic-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

              <h6  class="profile-active">
                <img src="../../Assets/Linqskills_Icons/password-active.svg" className="w-auto text-center pe-2"></img> <Translate textKey="userprofile001"/></h6>
            </button>
          </div>

          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">

            <div class="accordion-body border-top">

              <div class="row pb-3">

                <div class="col-md-12 col-lg-12 d-flex">

                  <div class="profile-settings w-100 p-3">
                    <h6 onClick={()=>handleSettingShow("Edit Password")} class={activeTab == "Edit Password"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>
                      {activeTab == "Edit Password"? <img src="../../Assets/Linqskills_Icons/password-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/password-default.svg" className="w-auto text-center pe-2"></img>}
                     <Translate textKey="userprofile001"/>
                    </h6>
                    <h6 onClick={()=>handleSettingShow("Update Contact")} class={activeTab == "Update Contact"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"} >
                      {activeTab == "Update Contact"? <img src="../../Assets/Linqskills_Icons/mail-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/mail-default.svg" className="w-auto text-center pe-2"></img>}
                      <Translate textKey="userprofile002"/>
                    </h6>
                    <h6 onClick={()=>handleSettingShow("User Preferences")} class={activeTab == "User Preferences"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>
                      {activeTab == "User Preferences"? <img src="../../Assets/Linqskills_Icons/settings-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/settings-default.svg" className="w-auto text-center pe-2"></img>}
                      <Translate textKey="userprofile003"/>
                    </h6>
                    <h6 onClick={()=>handleSettingShow("Organisation")} class={activeTab == "Organisation"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>
                      {activeTab == "Organisation"? <img src="../../Assets/Linqskills_Icons/b-icons-24_Organisation-filled.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/b-icons-24_Organisation-Default.svg" className="w-auto text-center pe-2"></img>}
                      <Translate textKey="postregform002"/>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      </> : ""
      }
      
      {/* profile-settings menu visible only for mobile view */}
    </div >

    </>
  )

}



