import React, { useEffect } from "react";

import videojs from "video.js";
import "video.js/dist/video-js.css";
import VideoJS from "./VideoJS";

const VideoPlayer = (props) => {
  // console.log(props.src, "video src");

  const playerRef = React.useRef(null);



  const videoJsOptions = {
    autoplay: props.autoPlay? true : false,
    controls: true,
    responsive: true,
    fluid: true,
    
    sources: [
      {
        // src: "https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/CourseContent_hin.mp4"
        src: `${props.src}`,
        type: "video/mp4",
      },
    ],
    
    disablePictureInPicture : true,
    playbackRates: [0.5, 1, 1.5, 2],
    
   

  };

  
  // console.log(props.src, "video src");

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  useEffect(() => {
    const handleContextMenu = (e) => {
      // prevent the right-click menu from appearing
      e.preventDefault();
    };

   document.addEventListener("contextmenu", handleContextMenu);

    // clean up the event listener when
    // the component unmounts
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div>
      <VideoJS
        oncontextmenu="return false;"
        options={videoJsOptions}
        onReady={handlePlayerReady}
        onContextMenu={(event) => event.preventDefault()}
      />
    </div>
  );
};
export default VideoPlayer;
