import React, { useEffect, useState, useRef, useMemo } from "react";
import { Navigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import ImageUploadFiles from "../../components/EligibilityCheck/imagesUpload.js";
import { async } from "q";
import Translate from "../../components/Shared/Translate.js"

import videojs from "video.js";
import "video.js/dist/video-js.css";
import Cookies from "universal-cookie";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from "@mui/material";

import {
  updateNextPrevUpdated,
  updateNodeVal,
} from "../../features/CourseIndex/courseIndexSlice.js";
import axios from "axios";
import { apiCallingForCourseStarted, ApiCallingForAuto } from "../../components/generalFunctions/apiCalling.js";
import CustumLoader from "../../components/Shared/CustumLoader.js";
import ViewTutorialPopUp from "../../components/CourseIndex/viewContentPopUp.js";
import TestPopup from "../../components/Popups/TestPopup.js";
import { variables } from "../../components/Shared/Variables.js";

// import TimerCountdown from "../Shared/timerCountdown.js";
// const createDOMPurify = require('dompurify');

// import JSDOM from 'jsdom';

const TimerCountdown = ({ timeRemaining, setTimeRemaining }) => {
  const [time, setTime] = useState(timeRemaining);
  // // console.log("timeRemaining in timer : ",timeRemaining,time)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((timeRemaining) => timeRemaining - 1);
      setTimeRemaining((timeRemaining) => timeRemaining - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeRemaining]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  return (
    <div className="col p-0">
      <h6 className="text-right display-desktop">
        Time Left :{" "}
        <span className="b-text">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      </h6>
      <h6 className="text-right display-mobile">
        <span className="b-text">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      </h6>
    </div>
  );
};

const RgsaQuestionbox = React.memo(
  ({
    quizQuestions,
    setquizQuestions,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    totalAttemptedQuestion,
    setTotalAttemptedQuestion,
    userAnsOptionNo,
    setUserAnsOptionNo,
    timeLeft,
    setTimeLeft,
    userAns,
    setUserAns,
    timeRemaining,
    setTimeRemaining,
    filesToUpload,
    setFilesToUpload,
    updateAnsData,
    updateQuestionTime,
  }) => {
    const cookies = new Cookies();

    // // console.log("timeRemaining : ",timeRemaining);

    var Incompletetxt =
      "<h5>You have not completed all the questions in the test. Do you still wish to End the test and submit for final score? </h5>";
    var Aborttxt =
      "<h5>You have attempted to abort the test. Do you wish to leave the test?</h5>";
    var Endtxt =
      "<h5>The test will now be submitted for final score.Do you wish to proceed ?</h5>";
    // :
    var nodeValue = useSelector((state) => state.courseindex.nodeData);
    // console.log({ nodeValue });
    //  const quizQuestions = [];
    const [questionCount, setQuestionCount] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [filesAns, setFilesAns] = useState("");
    const dispatch = useDispatch();
    const [currentQuestion, setCurrentQuestion] = useState([]);
    const [totalTestTime, setTotalTestTime] = useState(null);
    const [abortTest, setAbortTest] = useState(false);
    const [endTest, setEndTest] = useState(false);
    const [openEGAList, setOpenEGAList] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [queVideo, setQueVideo] = useState("");
    const [openVideo, setOpenVideo] = useState(false);
    const [incompleteTest, setIncompleteTest] = useState(false);
    const [openTopicContent, setOpenTopicContent] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [submittedTest, setsubmittedTest] = useState("");

    const optionArray = ["a", "b", "c", "d", "e", "f", "g", "h", "i"];
    const shouldLog = useRef(true);
    const courseData = JSON.parse(sessionStorage.getItem("coursedata"));
    const courseinfo = JSON.parse(sessionStorage.getItem("courseinfo"));

    // const initialTime = 10; // in seconds
    // const [timeLeftInBar, setTimeLeftInBar] = useState(initialTime);
    // const [progressBarPercent, setProgressBarPercent] = useState(0);

    const timerId = useRef();

    // useEffect(() => {
    //     if (initialTime) {
    //         timerId.current = window.setInterval(() => {
    //             setTimeLeftInBar((prevProgress) => prevProgress - 1);
    //         }, 1000);

    //         return () => {
    //             clearInterval(timerId.current);
    //         };
    //     }
    // }, []);

    // useEffect(() => {
    //     if (initialTime) {
    //         if (progressBarPercent < 100) {
    //             let updateProgressPercent = Math.round(
    //                 ((initialTime - (timeLeft - 1)) / initialTime) * 100
    //             );
    //             setProgressBarPercent(updateProgressPercent);
    //         }

    //         if (timeLeftInBar === 0 && timerId.current) {
    //             clearInterval(timerId.current);

    //             return;
    //         }
    //     }
    // }, [timeLeftInBar]);

    var props = JSON.parse(sessionStorage.getItem("TestData1"));
    const videoRef = React.useRef(null);

    useEffect(() => {
      if (shouldLog.current) {
        // // console.log("working in useEffect")
        shouldLog.current = false;
        getQuestions();
      }
    }, [timeRemaining, totalTestTime, EndTest]);
    const timerCountdown = useMemo(
      () => (
        <TimerCountdown
          timeRemaining={timeRemaining || 0}
          setTimeRemaining={setTimeRemaining}
        />
      ),
      [timeRemaining]
    );

    function replaceAll(str, find, replace) {
      var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
      return str.replace(new RegExp(escapedFind, "g"), replace);
    }

    function closeVideo() {
      // // console.log("clossing the video")
      setVideoUrl("");
      setOpenVideo(false);
      // setOpenLanguage(false);
    }

    const nodeIdForAssesmentGetdata = useSelector(
        (state) => state.courseindex.nodeIdForAssesmentGetdata
      );
      var userID = sessionStorage.getItem("login-userId");

      const langArray = [
        { value: "1", label: "English" },
        { value: "1", label: "Gujarati" },

        { value: "2", label: "Hindi" },
        { value: "3", label: "Tamil" },
        { value: "4", label: "Bengali" },
        { value: "5", label: "Telugu" },
        { value: "6", label: "Odiya" },
      ];

      var LangaugeId = langArray
      .filter((data) =>
        data.label.includes(sessionStorage.getItem("prefferedLanguage"))
      )
      .map((val) => val.value);
      const nodeID = JSON.parse(sessionStorage.getItem("TestData1"))

    const fetchTestData = async () => {

        try {
          const result = await fetch(
            `${variables.API_URL}api/GetAssessmentTestData/${userID}/${courseinfo[0].batchId}/${nodeID.nodeID}/${LangaugeId}`,
            {
              method: "GET",
              headers: {
                //'Authorization': 'Bearer ' + sessionStorage.getItem("JWTToken")
              },
            }
          );
  
          const data = await result.json();
          
          setsubmittedTest(data.data); // Store the response
          const res = data.data;

          if (data.isSuccess) {
            // console.log("res after getting test data: ", res);
          }
          return res;
        } catch (error) {
          console.error("Error fetching data:", error);
        } 
      };
      console.log({props})

      function EndTest() {
        // Helper function to update session and node status
        let coursedata = JSON.parse(sessionStorage.getItem("coursedata"));
        const updateSessionAndNodeStatus = (nextPrevData, nodeVal, props) => {
          for (let i = 0; i < nextPrevData.data.userDataList.length; i++) {
            if (nextPrevData.data.userDataList[i].sessionID == props.ParentNodeId) {
              nextPrevData.data.userDataList[i].topicStatus = "Completed";
              if (i < nextPrevData.data.userDataList.length - 1) {
                if (nextPrevData.data.userDataList[i + 1].topicStatus == "Locked") {
                  nextPrevData.data.userDataList[i + 1].topicStatus = "Open";
                }
              }
      
              for (let k = 0; k < nodeVal.length; k++) {
                if (
                  nodeVal[k].sessionID == nextPrevData.data.userDataList[i].sessionID &&
                  nodeVal[k].nodeClassification == "Session"
                ) {
                  nodeVal[k].completionStatus = "Completed";
      
                  if (
                    nodeVal[k + 1]?.completionStatus == "Locked" &&
                    nodeVal[k + 1]?.nodeClassification == "Session" &&
                    nodeVal[k + 1]?.deliveryMode != 1
                  ) {
                    nodeVal[k + 1].completionStatus = "Open";
                  } else if (
                    nodeVal[k + 2]?.completionStatus == "Locked" &&
                    nodeVal[k + 2]?.nodeClassification == "Session" &&
                    nodeVal[k + 2]?.deliveryMode != 1
                  ) {
                    nodeVal[k + 2].completionStatus = "Open";
                  }
      
                  dispatch(updateNodeVal(nodeVal));
                  sessionStorage.setItem("nodeVal", JSON.stringify(nodeVal));

                }
              }
            }
          }
          dispatch(updateNextPrevUpdated(true));
          sessionStorage.setItem("nextPrevData", JSON.stringify(nextPrevData));
          apiCallingForCourseStarted(props.UserId, props.BatchId);
          
        };
      
        // Main fetch call to end the test
        fetch(variables.API_URL + "api/EndTest", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userID: props.UserId,
            batchId: props.BatchId,
            CourseId: props.CourseId,
            projectId: props.ProjectId,
            testId: props.TestId,
            lessionplanid: props.LessionPlanId,
            isEGATest: props.IsAssessment == true ? false : true,
            sessionId: props.ParentNodeId,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.isSuccess) {
              if (props.IsAssessment) {
                fetchTestData().then((testdata) => {
                  // console.log("testdata: " + JSON.stringify(testdata));
                  const isLastAttempt =
                    testdata.attemptsCount === testdata.reAttemptsNo + 1;
                  // console.log("last attempt or not: " + isLastAttempt);
                  // console.log("testStatus: " + testdata.testStatus);
      
                  if (isLastAttempt || testdata.testStatus === "C") {
                    if (courseData.activeStage == 4) {
                      let nextPrevData = JSON.parse(
                        sessionStorage.getItem("nextPrevData")
                      );
                      var nodeVal = JSON.parse(sessionStorage.getItem("nodeVal"));
                      updateSessionAndNodeStatus(nextPrevData, nodeVal, props);
                    }
                  }
                });
                if(result.coursestatus == "True" && result.generateCertificate == "Auto"){
                  ApiCallingForAuto(coursedata);
                }
              } else {
                // Handle non-assessment scenario if necessary
              }
              if (props.IsAssessment) {
                setOpenTopicContent(true);
              } else {
                setOpenEGAList(true);
              }
            }
          });
      }
      
    function validateString(str) {
        // Regular expression to match valid characters (alphanumeric and specific special characters)
        // console.log("string or not: " + typeof str);
        const validChars = /^[ -~]+$/; // Matches printable ASCII characters
        // console.log("validating string: " + validChars.test(str));
        // console.log("string: " + str);
      
        return validChars.test(str);
      }
      
    const getQuestions = () => {
      const langArray = [
        { value: "1", label: "English" },
        { value: "1", label: "Gujarati" },

        { value: "2", label: "Hindi" },
        { value: "3", label: "Tamil" },
        { value: "4", label: "Bengali" },
        { value: "5", label: "Telugu" },
        { value: "6", label: "Odiya" },
      ];

      var LangaugeId = langArray
        .filter((data) =>
          data.label.includes(sessionStorage.getItem("prefferedLanguage"))
        )
        .map((val) => val.value);
      LangaugeId =
        props.IsAssessment == true ? parseInt(LangaugeId) : props.LanguageId;
      setIsLoader(true);
      fetch(variables.API_URL + "api/GetEGAQuestions", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: props.UserId,
          testId: props.TestId,
          templateId: props.templateId,
          sequenceType: "1",
          projectId: props.ProjectId,
          languageId: LangaugeId,
          duration: props.duration,
          courseId: props.CourseId,
          lessionplanid: props.LessionPlanId,
          addOnCourseId: 0, //parseInt(props.addOnCourseId),
          isAssessment: props.IsAssessment,
          BatchId: props.BatchId,
        }),
      })
        .then((response) => response.json())
        .then(async (result) => {
          // console.log("result from getQuestions : ", result);
          if (result.isSuccess) {
            setIsLoader(false);
            setTotalTestTime(result.dataList[0].totalTimeConsume);
            setTimeRemaining(result.dataList[0].totalTimeConsume);
            setTimeLeft(result.dataList[0].totalTimeConsume);

            let tempObjArr = [...filesToUpload];
            for (var i = 0; i < result.dataList.length; i++) {
              const options = [];
              var correctAns;
              if (result.dataList[i].questiontype == 3) {
                for (var k = 0; k < result.dataList[i].options.length; k++) {
                  var questionansno =
                    result.dataList[i].options[k].questionnumber;
                  if (result.dataList[i].options[k].correctanswerflag == 1) {
                    correctAns = result.dataList[i].options[k].questionnumber;
                  }
                  var answer = await DecryptString(
                    result.dataList[i].options[k].answer
                  )
                    .replaceAll("heigth:auto", "height:50px")
                    .replaceAll("width:auto", "width:89px");
                  // console.log("answer: " + answer.length);
/*                   if (validateString(answer)) {
 */                    options.push({
                      Ans: answer, //.replaceAll("<div>", "").replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("</div>", ""),
                      Questionansno: questionansno,
                    });
                /*   }else{
                    // console.log("junk characters detected, reloading page....")
                   //window.location.reload();
                   options.push({
                    Ans: answer, //.replaceAll("<div>", "").replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("</div>", ""),
                    Questionansno: questionansno,
                  });
                  } */
                }
              }

              // // console.log("before push tempObjArr : ", tempObjArr);
              if (result.dataList[i].questiontype == 6) {
                // // console.log(`result.dataList[i].selectedAnswer ${i} = ${result.dataList[i].selectedAnswer} : `);
                const decryptAnsStr =
                  (await result.dataList[i].selectedAnswer) != undefined
                    ? DecryptString(result.dataList[i].selectedAnswer)
                    : "";
                // alert(result.dataList[i].selectedAnswer);
                // // console.log(`decryptAnsStr ${i}  : `, decryptAnsStr);
                let alreadyUploadedFiles = [];
                // alreadyUploadedFiles.push(decryptAnsStr);
                if (decryptAnsStr) {
                  const arrToBeSet = decryptAnsStr.split("~");
                  // // console.log("arrToBeSet : ", arrToBeSet)
                  for (let i = 0; i < arrToBeSet.length; i++) {
                    // // console.log(` arrToBeSet[j] : ${arrToBeSet[i]}`);
                    alreadyUploadedFiles.push(arrToBeSet[i]);
                  }
                  // // console.log("alreadyUploadedFiles after : ", alreadyUploadedFiles);
                }
                let fileAnsStr = alreadyUploadedFiles.join("~");
                // // console.log("fileAnsStr : ", fileAnsStr)
                tempObjArr.push({
                  files: [],
                  uploadedFiles: alreadyUploadedFiles,
                  isUploaded: 0,
                  queId: result.dataList[i].questionid,
                  ansStr: fileAnsStr,
                  id: i,
                  isBtnUpload: true,
                  fileId: result.dataList[i].fileformatid,
                });
              }
              // // console.log("after push tempObjArr: ", tempObjArr);
// console.log("result: ", JSON.stringify(result));
              var QuestionHeading =result.dataList[i].questiontype===6? OldDecryptString(
                result.dataList[i].questionheading
              ): DecryptString(
                result.dataList[i].questionheading
              );

              var attr = "";
              if (QuestionHeading.indexOf("fileid") == -1) {
                attr = `fileid="NA"`;
              }
              if (QuestionHeading.indexOf("prop") == -1) {
                attr = attr + " " + `prop="NA"`;
              }
              if (attr.length > 0) {
                QuestionHeading = QuestionHeading.replaceAll(
                  "<img",
                  `<img ${attr}`
                );
              } else {
                QuestionHeading = QuestionHeading.replaceAll("<img", `<img`);
              }

              // // console.log("QuestionHeading : ",QuestionHeading);
              QuestionHeading = QuestionHeading.replaceAll(
                "heigth:auto",
                "height:150px"
              ).replaceAll("width:auto", "width:267px");
              // // console.log(`QuestionHeading for ${i}: `,QuestionHeading)
              if (
                totalAttemptedQuestion.indexOf(
                  result.dataList[i].questionid
                ) === -1 &&
                result.dataList[i].selectedAnswer != ""
              ) {
                totalAttemptedQuestion.push(result.dataList[i].questionid);
              }
              // if (i == 0) {

              //     // setVideoUrl(extractVideoUrl())
              // console.log("QuestionHeading : ", QuestionHeading);
              //     // setQueVideo(QuestionHeading);
              // }
              const extractVideoUrl = () => {
                const regex = /videourl="([^"]+)"/;
                const match = QuestionHeading.match(regex);
                return match ? match[1] : null;
              };
              // // console.log(`match for ${i}: `, extractVideoUrl());

              quizQuestions.push({
                isVideoUrl: extractVideoUrl(),
                question: QuestionHeading, //DecryptString(result.dataList[i].questionheading),//replaceAll("<div>", "").replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("</div>", ""),
                options: options,
                correctAnswer: correctAns,
                QuesSectionNo: result.dataList[i].sectionno,
                QuestionId: result.dataList[i].questionid,
                MarksPerQuestion: result.dataList[i].marks,
                SelectedOptionNo: result.dataList[i].selectedAnswerOptionNo,
                QuestionType: result.dataList[i].questiontype, //6 for sub test
                SelectedAnswer: DecryptString(
                  result.dataList[i].selectedAnswer
                ),
                FileFormatId: result.dataList[i].fileformatid, //fileformatId>0 ==>file upload
              });
              setCurrentQuestion(quizQuestions[currentQuestionIndex]);
              // const elements = document.getElementsByClassName('imgVideo');

              // // Attach click event handler to each element with the class 'myClass'
              // Array.from(elements).forEach((element) => {
              //   element.addEventListener('click', this.handleImageClick);
              // });
            }
            setFilesToUpload(tempObjArr);
          }
          setQuestionCount(quizQuestions.length);
        })
        .catch((err) => {
          // console.log("error in GetEGAQuestions : ", err);
          setIsLoader(false);
        });
    };

    const handleOptionSelect = (option, optionNo) => {
      const newAnswers = [...userAnswers];
      newAnswers[currentQuestionIndex] = option;
      setUserAnswers(newAnswers);
      setUserAnsOptionNo(optionNo);
      currentQuestion.SelectedOptionNo = optionNo;
      setUserAns(option);
    };

    const handleNextQuestion = () => {
      // // console.log("ffffffff",filesToUpload);
      // const questionOnNext = filesToUpload.filter((que)=>{
      //     return que.queId == currentQuestion.QuestionId
      // })
      // // console.log("questionOnNext : ",questionOnNext);
      updateAnsData();
      setCurrentQuestionIndex((index) => index + 1);
      setCurrentQuestion(quizQuestions[currentQuestionIndex + 1]);
      setOpenVideo(false);
      if (openVideo) {
        // // console.log("disposing video in next")
        var oldPlayer = document.getElementById("example_video_1");
        videojs(oldPlayer).dispose();
      }

      // const player = playerRef.current;
      // // console.log("player : ", player);
      // return () => {
      //     if (player && !player.isDisposed()) {
      //         // console.log("disposing plyaer")
      //         player.dispose();
      //         playerRef.current = null;
      //     }
      // };
    };

    const handlePrevQuestion = () => {
      updateAnsData();
      setCurrentQuestionIndex((index) => index - 1);
      setCurrentQuestion(quizQuestions[currentQuestionIndex - 1]);
      setOpenVideo(false);
      if (openVideo) {
        // // console.log("disposing video in prev")
        var oldPlayer = document.getElementById("example_video_1");
        videojs(oldPlayer).dispose();
      }
    };

    function DecryptString(encryptedData) {
        var key = CryptoJS.enc.Utf8.parse("8080808080808080");
        var iv = CryptoJS.enc.Utf8.parse("8080808080808080");
        try {
            const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
            const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
            // console.log({decryptedText})
            return decryptedText;
        } catch (error) {
            console.error("Decryption failed:", error);
            return null;
        }
    }

     function OldDecryptString(str) {
       // console.log("str : ", str);
      try {
        var desc = CryptoJS.AES.decrypt(str.trim(), "0101001");
        desc = desc.toString(CryptoJS.enc.Utf8);
      } catch (err) {
        desc = "";
      }
      if (desc == "") {
        try {
          desc = CryptoJS.AES.decrypt(str.trim(), "8080808080808080");
          desc = desc.toString(CryptoJS.enc.Utf8);
        } catch (err) {
          desc = "";
        }
      }
      if (desc == "") {
        try {
          var key = CryptoJS.enc.Utf8.parse("8080808080808080");
          var iv = CryptoJS.enc.Utf8.parse("8080808080808080");

          var desc = CryptoJS.AES.decrypt(str.trim(), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          });
          desc = desc.toString(CryptoJS.enc.Utf8);
        } catch (err) {
          desc = "";
        }
      }

      return desc;
    }  
    // // console.log("currentQuestion : ", currentQuestion)

    const videoJsOptions = {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [
        {
          src: `${currentQuestion.isVideoUrl}`,
          type: "video/mp4",
        },
      ],
    };

    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
      // You can handle player events here, for example:
      player.on("waiting", () => {
        videojs.log("player is waiting");
      });

      player.on("dispose", () => {
        videojs.log("player will dispose");
      });
    };

    useEffect(() => {
      const handleContextMenu = (e) => {
        // prevent the right-click menu from appearing
        e.preventDefault();
      };

      document.addEventListener("contextmenu", handleContextMenu);

      // clean up the event listener when
      // the component unmounts
      return () => {
        document.removeEventListener("contextmenu", handleContextMenu);
      };
    }, []);
    useEffect(() => {
      if (openVideo) {
        // // console.log("calling Play video ")
        playVideo(currentQuestion.isVideoUrl);
        // setTimeout(() => {

        // }, 100)
      }
    }, [openVideo]);

    const playVideo = async (srcLink) => {
      // // console.log("inside play video")
      // // console.log("srcLink : ", srcLink)
      const videoJsPlayer = videojs("example_video_1", {
        autoplay: true,
        controls: true,
        responsive: true,
        sources: [
          {
            src: srcLink,
            type: "video/mp4",
          },
        ],
      });
    };
    const valueGetByDangerouslySetInnerHTML = (e) => {
      const el = e.target.closest("Img");

      if (currentQuestion.isVideoUrl != null && openVideo == false) {
        // // console.log("setting video url")
        const splitArr = currentQuestion.question.split("<p/>");
        // // console.log("splitArr : ", splitArr);
        const htmlString = splitArr[0];
        // console.log("htmlString : ", htmlString);
        const updatedHtmlString = htmlString.replace(
          `<img fileid="${el.getAttribute("fileid")}" prop="${el.getAttribute(
            "prop"
          )}" src="${el.getAttribute("src")}" style="${el.getAttribute(
            "style"
          )}" videourl="${el.getAttribute("videourl")}" />`,
          `<video id="example_video_1" width="208"  src=${currentQuestion.isVideoUrl} class="ega-video video-js vjs-default-skin vjs-big-play-centered"
            controls preload="auto" 
            
            data-setup='{"example_option":true}' >
          </video>`
        );
        const tempUpdated = updatedHtmlString;
        // playVideo(currentQuestion.isVideoUrl);
        // // console.log("updatedHtmlString : ", updatedHtmlString)
        setCurrentQuestion({ ...currentQuestion, question: updatedHtmlString });
        setOpenVideo(true);
      }

      // if(openVideo) {
      //     // console.log("dont open video pop up")
      // } else {
      //     if(currentQuestion.isVideoUrl != null){
      //         // console.log("setting video url")
      //         setOpenVideo(true);
      //         setVideoUrl(currentQuestion.isVideoUrl);
      //     }
      // }
      // const tempStr = '<p><img className=\'imgVideo\' data-id=\'9492\' fileid="7358" prop="1111" src="https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18690/videos/Testing+image 6.jpg" style="width:267px;height:150px;" videourl="https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18690/videos/1st+Testing.mp4" /></p>';
      // // console.log("queVideo : ", queVideo)
      // const extractVideoUrl = () => { const regex = /videourl="([^"]+)"/;
      // const match = queVideo.match(regex); return match ? match[1] : null; };
      // // console.log("match : ",extractVideoUrl());
      // setVideoUrl(extractVideoUrl())
      // // console.log($('img').attr('src'));
    };
    // const htmlString = '<p>&lsquo;Zircon hypersonic cruise missile&rsquo; belongs to which country?</p>  <p>&nbsp;</p>';

    const subNodeData = JSON.parse(sessionStorage.getItem("subNodeData"))
    if (openEGAList) {
      return <Navigate to="/eligibility" />;
    } else if (openTopicContent) {
      return (
        <Navigate
          to={`/TopicDetailsRgsa/${subNodeData.nodeName}/${props.ParentNodeId}/${props.BatchId}/${props.DeliveryMode}/${props.ExamMode}`}
        />
      );
    } else
      return (
        /*  abortTest ?
                 <>
                     <div class="row m-2">
                         <div class="popup-one text-lg-left select-medium">
                             <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="cursor-pointer float-end cross-icon2 " onClick={(e) => setAbortTest(false)}></img>
                             <div class="row">
                                 <div class="d-flex mb-3 p-0">
                                     <h6 class="m-0">
                                         <img src="../../Assets/Linqskills_Icons/popup-info.svg" class=""></img>
                                         Abort Test
                                     </h6>
                                 </div>
                                 <div class="d-flex mb-3">
                                     <h5 class="m-0">
                                         You have attempted to abort the test. Do you wish to leave the test?   </h5>
                                 </div>
                             </div>
                             <div class="d-flex float-end mt-3">
                                 <h6 class="pe-3 cta-ok" onClick={(e) => setOpenEGAList(true)}>Yes</h6>
                                 <h6 class="" onClick={(e) => setAbortTest(false)}>No</h6>
                             </div>
                         </div>
                     </div>
                     <section class="modal overlay">
                     </section>
                 </>
                 :
                 endTest ?
                     <>
                         <div class="row m-2">
                             <div class="popup-one text-lg-left select-medium">
                                 <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="cursor-pointer float-end cross-icon2 "></img>
                                 <div class="row">
                                     <div class="d-flex mb-3 p-0">
                                         <h6 class="m-0">
                                             <img src="../../Assets/Linqskills_Icons/popup-info.svg" class=""></img>
                                             End Test
                                         </h6>
                                     </div>
                                     <div class="d-flex mb-3">
                                         {
                                             (questionCount - 1) !== totalAttemptedQuestion.length ?
                                                 <h5 class="m-0">
                                                     You have not completed all the questions in the test. Do you still wish to end the test and submit for final score ?
                                                 </h5>
                                                 :
                                                 <h5 class="m-0">
                                                     The test will now be submitted for final score.Do you wish to proceed ?
                                                 </h5>
                                         }
                                     </div>
                                 </div>
                                 <div class="d-flex float-end mt-3">
                                     <h6 class="pe-3 cta-ok" onClick={EndTest}>Yes</h6>
                                     <h6 class="" onClick={(e) => setEndTest(false)}>No</h6>
                                 </div>
                             </div>
                         </div>
                         <section class="modal overlay">
                         </section>
                     </>
                     : */
        <>
         {/*  <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
                         {isLoader &&      <CustumLoader message="Loading..." />}

          <div className="assessment-header border-bottom">
            <div className="question-details border-bottom">
              <div className="col p-0">
                <h6 class="display-desktop">
                  Question :{" "}
                  <span className="b-text ">
                    {currentQuestionIndex + 1} of {questionCount}
                  </span>
                </h6>
                <h6 class=" display-mobile">
                  Q :{" "}
                  <span className="b-text">
                    {currentQuestionIndex + 1} of {questionCount}
                  </span>
                </h6>
              </div>
              {/* {timerCountdown} */}
              {timeRemaining !== null ? timerCountdown : <p>Loading...</p>}
            </div>
            {/* <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "#FFFFFF",
                                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
                                    width: "100%",
                                    padding: "20px"
                                }}
                            >
                                <Box sx={{ width: "50%" }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={progressBarPercent}
                                        sx={{
                                            borderRadius: "20px",
                                            background: "rgba(132, 162, 233, 0.4)",
                                            padding: "15px",
                                            [`& .${linearProgressClasses.bar1Determinate}`]: {
                                                backgroundColor: progressBarPercent > 90 ? `red` : "blue"
                                            }
                                        }}
                                    />
                                </Box>

                                <Box sx={{ ml: 1, display: "flex" }}>
                                    <Typography sx={{ fontWeight: 400, fontSize: "25px" }}>
                                        Time left:
                                    </Typography>
                                    <Typography sx={{ fontWeight: 700, fontSize: "25px", ml: 1 }}>
                                        {moment
                                            .utc(1000 * timeLeft)
                                            .format(
                                                `${timeLeft > 3600 ? "HH[h]:mm[m]:ss[s]" : "mm[m]:ss[s]"} `
                                            )}
                                    </Typography>
                                </Box>
                            </Box> */}
            <div className="col-md-12 d-flex actionbtns-rgsa p-3">
              <div className="col-8 p-0 d-flex">
                {currentQuestionIndex != 0 && (
                  <button
                    onClick={handlePrevQuestion}
                    className=" me-4"
                  >
                   <img src="../../Assets/Linqskills_icons/back-btn-rgsa.svg" class=" me-1"></img> <Translate textKey="topic001" />
                  </button>
                )}
                {currentQuestionIndex + 1 != questionCount && (
                  <button onClick={handleNextQuestion} className="">
                   <img src="../../Assets/Linqskills_icons/arrow-right-rgsa.svg" class="float-end ms-1"></img> <Translate textKey="topic002" />
                    
                  </button>
                )}
              </div>
              <div className="col p-0">
                {currentQuestionIndex + 1 !== questionCount && (
                  <button
                    onClick={(e) => {
                      setAbortTest(true);
                    }}
                    className=" float-end"
                  >
                    ABORT
                  </button>
                )}
                {currentQuestionIndex + 1 === questionCount && (
                  <button
                    onClick={(e) => {
                      updateAnsData();
                      setEndTest(true);
                    }}
                    className=" float-end me-2"
                  >
                    END TEST
                  </button>
                )}
              </div>
            </div>
          </div>

          {abortTest && !props.IsAssessment && (
            <TestPopup
              show={true}
              txt={Aborttxt}
              onOk={(e) => {
                updateQuestionTime();
                setOpenEGAList(true);
              }}
              onClose={(e) => setAbortTest(false)}
              title="Abort"
            />
          )}
          {abortTest && props.IsAssessment && (
            <TestPopup
              show={true}
              txt={Aborttxt}
              onOk={(e) => {
                updateQuestionTime();
                setOpenTopicContent(true);
              }}
              onClose={(e) => setAbortTest(false)}
              title="Abort"
            />
          )}
          {endTest && (
            <TestPopup
              show={true}
              txt={Endtxt}
              onOk={() => EndTest()}
              onClose={(e) => setEndTest(false)}
              title="End"
            />
          )}
          {endTest && questionCount !== totalAttemptedQuestion.length && (
            <TestPopup
              show={true}
              txt={Incompletetxt}
              onOk={() => EndTest()}
              onClose={(e) => setEndTest(false)}
              title="Incomplete"
            />
          )}

          {questionCount > 0 && (
            <div className="question-box p-0 mb-3">
              <div
                onClick={valueGetByDangerouslySetInnerHTML}
                className="questions d-flex"
              >
                <span className="question-no pe-2">
                  Q{/* .{currentQuestionIndex + 1} */}
                </span>
{/*                 { console.log({currentQuestion})}
 */}                <p
                  class="mb-0 image-fullwidth"
                  dangerouslySetInnerHTML={{ __html: currentQuestion.question }}
                ></p>
              </div>

              {currentQuestion.QuestionType == 6 ? (
                <>
                  <ImageUploadFiles
                    setFilesAns={setFilesAns}
                    fileId={currentQuestion.FileFormatId}
                    setFilesToUpload={setFilesToUpload}
                    testId={props.TestId}
                    userId={props.UserId}
                    queId={currentQuestion.QuestionId}
                    filesToUpload={filesToUpload}
                    IsAssessment={props.IsAssessment}
                  />
                </>
              ) : (
                <>
                  {currentQuestion.options.map((option, index) => (
                    <div key={index} className="options">
                      <div className="form-check d-flex pe-3">
                        <div>
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={() =>
                              handleOptionSelect(
                                option.Ans,
                                option.Questionansno
                              )
                            }
                            checked={
                              userAnswers[currentQuestionIndex] ===
                                option.Ans ||
                              currentQuestion.SelectedOptionNo ===
                                option.Questionansno
                            }
                            value={option.Ans}
                            id={index}
                          ></input>
                        </div>
                        {/* <h5> */}
                        <span className="options-seq pe-2">
                          {optionArray[index]}.
                        </span>
                        <label
                          htmlFor={index}
                          dangerouslySetInnerHTML={{ __html: option.Ans }}
                        ></label>
                        {/* </h5> */}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </>
      );
  }
);

export default RgsaQuestionbox;
