import React, { useEffect } from 'react';

const AdobePdfViewer = (props) => {
  useEffect(() => {
    // Load Adobe PDF Embed API script
    const script = document.createElement('script');
  
      if (window.AdobeDC) {
        // Initialize the AdobeDC View
        const adobeDCView = new window.AdobeDC.View({
          clientId: "d754a5b1c3804162ba0e86e2e02e22e4",
          divId: "pdf-viewer-container"
        });

        // Preview the PDF file
        adobeDCView.previewFile({
          content: {
            location: {
              url: props.url,
              headers: [{
                values: [
                  { type: "text", value: "d754a5b1c3804162ba0e86e2e02e22e4", key: "API_KEY", enabled: true },
                  { type: "text", value: ["example_sdk"], key: "META_SCOPE", enabled: true },
                  { type: "text", value: "", key: "ACCESS_TOKEN", enabled: false },
                  { type: "text", value: "", key: "PRIVATE_KEY", enabled: false },
                  { type: "text", value: "", key: "JWT_TOKEN", enabled: false },
                  { type: "text", key: "TECHNICAL_ACCOUNT_ID", enabled: true },
                  { type: "text", value: "ims-na1.adobelogin.com", key: "IMS", enabled: true },
                  { type: "text", value: "CF341E566620C0270A495CE1@AdobeOrg", key: "IMS_ORG", enabled: true }
                ],
                name: "reactlocolhost"
              }]
            }
          },
          metaData: {
            fileName: props.fileName || 'sample.pdf'
          }
        }, {
          embedMode: "IN_LINE",
           showDownloadPDF: false,
           showPrintPDF: false,
          showLeftHandPanel: false,
          showAnnotationTools: false,
          showPageControls: false,
          showDocumentLinkAnnotation: false
        });
      } else {
        console.error("AdobeDC is not defined");
      }
    
    script.onerror = () => console.error("Failed to load the Adobe PDF Embed API script.");
    document.head.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, [props.url, props.fileName]);

  return (
    <div id="pdf-viewer-container" style={{ width: '100%', height: '100vh', overflow: 'auto' }}></div>
  );
};

export default AdobePdfViewer;
