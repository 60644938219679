import React from "react";



export default function SkeletonCard() {
  return (
      <>
<div class="container p-0 skeleton">
  <div class="col-lg-3 col-sm-12 col-xs-12 mb-3 card-one">
<div class="skelton-image">

</div>
    <div class="card">
    <div class="card-body cardbody-height">

    <div class="skeleton-section mb-3">

    </div>
   
    <div class="skeleton-section2" >

    </div>
    </div>
    </div>
  </div>
</div>
      </>
    )

}

