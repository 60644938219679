import React, { useState, useEffect } from "react";
import { variables } from './Variables';
import Translate from './Translate';

export default function HamburgerIcons(props) {
  const [showMainDiv, setShowMainDiv] = useState(false);
  const [activeTab, setActiveTab] = useState("Edit Password")



  const DisplayHamburger = () => {

    // setShowMainDiv(false);

  
    props.hamburgerParentCallBack(false);
    // props.DisplayHamburger()
  }

  const CloseHamburger = () => {
   // alert("CloseHamburger");
    // setShowMainDiv(true);

    props.hamburgerParentCallBack(true);
    // props.CloseHamburger()
 // showMainDiv = true;

    //TestAlert();

  }
  const handleSettingShow = (type) =>{
    // // console.log("handleSettingShow : ",type);
    props.setIsShowSettings(type);
    setActiveTab(type);
  }

  // alert(props.showHamburgerIcon);
  return(
    <>
    {
      props.dontShow == "true" ? 
      "" : 
      <>
      {
      ((props.showHamburgerIcon == undefined) || props.showHamburgerIcon) ?
      <img src="../../Assets/Linqskills_Icons/LeftMenu-hamburger.svg" class="display-mobile float-end leftmenu-hamburger" onClick={DisplayHamburger}></img>
      :<>
      <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="display-mobile float-end hamburger-close" onClick={CloseHamburger}></img>
      </> 
    }
      </>
    }
    
</>
  )


}


{/* <div class="accordion mb-4 p-0 display-mobile" id="accordionExample">
        <div class="accordion-item box-shadow ">
          <div class="accordion-header topic-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

              <h6  class="profile-active">
                <img src="../../Assets/Linqskills_Icons/password-active.svg" className="w-auto text-center pe-2"></img> <Translate textKey="userprofile001"/></h6>
            </button>
          </div>

          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">

            <div class="accordion-body border-top">

              <div class="row pb-3">

                <div class="col-md-12 col-lg-12 d-flex">

                <div class="profile-settings w-100 p-3">
                    <h6 onClick={()=>handleSettingShow("Edit Password")} class={activeTab == "Edit Password"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>
                      {activeTab == "Edit Password"? <img src="../../Assets/Linqskills_Icons/password-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/password-default.svg" className="w-auto text-center pe-2"></img>}
                     <Translate textKey="userprofile001"/>
                    </h6>
                    <h6 onClick={()=>handleSettingShow("Update Contact")} class={activeTab == "Update Contact"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"} >
                      {activeTab == "Update Contact"? <img src="../../Assets/Linqskills_Icons/mail-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/mail-default.svg" className="w-auto text-center pe-2"></img>}
                      <Translate textKey="userprofile002"/>
                    </h6>
                    <h6 onClick={()=>handleSettingShow("User Preferences")} class={activeTab == "User Preferences"? "active courselist-hover cursor-pointer" : "courselist-hover cursor-pointer"}>
                      {activeTab == "User Preferences"? <img src="../../Assets/Linqskills_Icons/settings-active.svg" className="w-auto text-center pe-2"></img> : <img src="../../Assets/Linqskills_Icons/settings-default.svg" className="w-auto text-center pe-2"></img>}
                      <Translate textKey="userprofile003"/>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div> */}