import React from 'react';
//import Message from './components/message';
import NavOne from '../NavOne';
import AdminCourseCard from './AdminCourseCard';
import Footer from '../Shared/Footer';
import DeliveryModel from './DeliveryModel';
import SearchPanel from './SearchPanel';
import DropdownAdminbtn from './DropdownAdminbtn';
import AdminNavbarTwo from './AdminNavbarTwo';
import CourseIndexleftbox from '../CourseIndex/CourseIndexleftbox';
import LeftCard from './LeftCard';
import AdminAccordion from './AdminAccordion';
import MobileMenu from './MobileMenu';


export default function BatchesList() {

  return (
    <>
      
          <div className="p-0">
       
                      <NavOne></NavOne>
                      <MobileMenu/>
                  
              <div class="container-fluid body-wrapper bg-default">
                <DeliveryModel/>
           
                
              <div class="container pt-3 pb-3 p-0">
                <div class="row p-3">
                <LeftCard/>
<div class=" ps-3 pe-3 col-lg-9 col-sm-12 col-xs-12 content-cards ">
<AdminAccordion/>
  </div>         
                
                </div>
</div>
                </div>
             
          </div>
         <Footer/>
          </>
      )
  
}


