import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { callingDispose, updateIndex, updateOnlineLec, updateNodeVal, updateVideo } from "../features/CourseIndex/courseIndexSlice";
import { updateIsActiveLi } from "../features/navbar/navbarSlice"

import Logout from "./Shared/Logout";
const NavOne = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showLogout, setshowLogout] = useState(false);
    
    const [toggle, setToggle] = useState(false);
    const isNavHide = useSelector(state => state.navbar.isNavHide);
    const isActiveLi = useSelector(state => state.navbar.isActiveLi);
    const activeStage1List = useSelector(state => state.courseindex.activeStage1List);
    const activeStage4List = useSelector(state => state.courseindex.activeStage4List);

    // // console.log("final val of isNavHide : ",isNavHide);
    const doCallingDispose = async () => {
        // console.log("calling callingDispose")
        await dispatch(callingDispose(true));
        dispatch(updateIsActiveLi("courses"));
        // dispatch(updateVideo(false))
        dispatch(updateIndex("CO"));
        dispatch(updateNodeVal([]));
        dispatch(updateOnlineLec([]));
        navigate('/mycourses');
    }

    const goToOnActivePage = (type) => {
        if (type == "help") {
            dispatch(updateIsActiveLi("help"));
            navigate('/HelpVideos');
        } else if (type == "edit") {
            dispatch(updateIsActiveLi("edit"));
            navigate('../UserProfile');
        } else if (type == "Assessment") {
            dispatch(updateIsActiveLi("Assessment"));
            navigate('/mycourses');
        }

    }

    // useEffect(()=>{
    //     if(courseIdRegisterFor){
    //         console.log("there is registeration done");
    //     } else {
    //         // console.log("there is no registeration process last done")
    //         if(activeStage1List.length > 0){
    //             dispatch(updateIsActiveLi("Assessment"));
    //         } else  {
    //             dispatch(updateIsActiveLi("courses"));
    //         }
    //     }
    // },[])

    return (

        <div className={`container-fluid  nav-wrapper bg-default p-0 `}>
            <div className="container-fluid p-0 border-bottom nav-position">

                <nav className={isNavHide ? "d-none" : "navbar navbar-expand-lg navbar-light bg-default p-0"}>
                    <div className="container cont-padding">
                        <a className="navbar-brand col-lg-2 ps-2" >
                            <img src="../../assets/images/nav-logo.png" alt="LinqSkills" />
                        </a>
                        <div className="collapse navbar-collapse nav-show " id="navbarSupportedContent"  >
                            <ul className={`navbar-nav justify-content-center ${props.isDNone ? "invisible" : ""} mx-auto mb-lg-0 col-lg-9 ${props.mobileView === true && "hide-topic-navbar"} responsive-nav ${props.footer && "nav-margin"}`}  >
                                {/* <Link class="" to={'/mycourses'}> */}

                                {
                                    activeStage1List?.length > 0 ?
                                        <>
                                            <li onClick={() => goToOnActivePage("Assessment")} className={isActiveLi == "Assessment" ? "me-2 nav-item menu-w text-lg-center text-sm-center li-active cursor-pointer" : "me-2 nav-item menu-w text-lg-center text-sm-center"}>
                                                <img src={isActiveLi == "Assessment" ? "../../Assets/Linqskills_Icons/Assessment-purple.svg" : "../../Assets/Linqskills_Icons/Assessment-Default.svg"} className="cursor-pointer" />
                                                <a className={isActiveLi == "Assessment" ? " cursor-pointer nav-link active m-0 p-0 hide-text active" : "cursor-pointer nav-link m-0 p-0 hide-text"} aria-current="{}page" >Proficiency Test</a>
                                            </li>
                                        </> : ""
                                }

                                {
                                    activeStage4List?.length > 0 ?
                                        <>
                                            <li onClick={doCallingDispose} className={isActiveLi == "courses" ? "me-2 nav-item menu-w text-lg-center text-sm-center li-active cursor-pointer" : "me-2 nav-item menu-w text-lg-center text-sm-center"}>
                                                <img src={isActiveLi == "courses" ? "../../Assets/Linqskills_Icons/course-active.svg" : "../../Assets/Linqskills_Icons/course-default.svg"} className="cursor-pointer" />
                                                <a className={isActiveLi == "courses" ? " cursor-pointer nav-link active m-0 p-0 hide-text active" : "cursor-pointer nav-link m-0 p-0 hide-text"} aria-current="{}page" >Courses</a>
                                            </li>
                                        </> : ""
                                }

                                <li onClick={() => goToOnActivePage("help")} className={isActiveLi == "help" ? "me-2 nav-item menu-w text-lg-center text-sm-center li-active cursor-pointer" : "me-2 nav-item menu-w text-lg-center text-sm-center"}>
                                    <img src={isActiveLi == "help" ? "../../Assets/Linqskills_Icons/Video-Active.svg" : "../../Assets/Linqskills_Icons/Video-Default.svg"} className="cursor-pointer" />
                                    <a className={isActiveLi == "help" ? "cursor-pointer nav-link active m-0 p-0 hide-text active" : "cursor-pointer nav-link m-0 p-0 hide-text"} aria-current="{}page" >Help Videos</a>
                                </li>
                                <li onClick={() => goToOnActivePage("edit")} className={isActiveLi == "edit" ? "me-2 nav-item menu-w text-lg-center text-sm-center li-active cursor-pointer" : "me-2 nav-item menu-w text-lg-center text-sm-center"}>
                                    <img src={isActiveLi == "edit" ? "../../Assets/Linqskills_Icons/eprofile-active.svg" : "../../Assets/Linqskills_Icons/eprofile-Default.svg"} className="cursor-pointer" />
                                    <a className={isActiveLi == "edit" ? "cursor-pointer nav-link active m-0 p-0 hide-text active" : "cursor-pointer nav-link m-0 p-0 hide-text"} aria-current="{}page" >Edit Profile</a>
                                </li>
                                {/* </Link> */}
                                <li className="nav-item menu-w text-lg-center hidden">
                                    <img src="../../Assets/Linqskills_Icons/bookmark-grey.svg" className="cursor-pointer" />

                                    <a className="nav-link m-0 p-0 hide-text" href="#">Bookmark</a>
                                </li>
                                <li className="nav-item menu-w text-lg-center hidden">
                                    <img src="../../Assets/Linqskills_Icons/explore-grey.svg" className="cursor-pointer"></img>
                                    <a className="nav-link m-0 p-0 hide-text" href="#">Explore</a>
                                </li>
                                <li className="nav-item menu-w text-lg-center hidden">
                                    <img src="../../Assets/Linqskills_Icons/notification-grey.svg" className="cursor-pointer" />
                                    <a className="nav-link p-0 mt-1 hide-text" href="#" tabindex="-1" aria-disabled="true">Notification</a>
                                </li>
                                <li className="nav-item menu-w text-lg-center menu-w hidden ">
                                    <img src="../../Assets/Linqskills_Icons/performance-grey.svg" className="cursor-pointer" />
                                    <a className="nav-link m-0 p-0 hide-text" href="#" tabindex="-1" aria-disabled="true">Performance</a>
                                </li>
                                <li className="nav-item menu-w text-lg-left text-lg-center hidden">
                                    <img src="../../Assets/Linqskills_Icons/notes-grey.svg" className="cursor-pointer" />
                                    <a className="nav-link p-0 mt-1 hide-text" href="#" tabindex="-1" aria-disabled="true">Notes</a>
                                </li>
                            </ul>
                            <div className="col-lg-2 search-bar">
                                {/*       <img src="../../Assets/images/image1464.png" className="float-end display-desktop" />*/}
                                {/*  <NavLink to="/"> */}
                                <div class="float-end ps-3" onClick={() => setshowLogout(!showLogout)}  >
                                    <img src="../../Assets/Linqskills_Icons/profile.svg" className="cursor-pointer"></img>
                                    <img src="../../Assets/Linqskills_Icons/profile-drop.svg" className="cursor-pointer"></img>

                                </div>
                                {showLogout && <Logout />}
                                {/*     <img src="../../Assets/Linqskills_Icons/search.svg" className="float-end display-desktop" />*/}
                                {/*     </NavLink> */}

                            </div>

                            {/* <Logout/> */}

                        </div>
                    </div>

                </nav>
            </div>
        </div>

    )
}

export default NavOne;