import { variables } from '../Shared/Variables';
import axios from "axios";

const apiCallingForCourseStarted = (userId, batchId) => {
  if (sessionStorage.getItem("isCourseStarted") == "False") {
    let body = {
      "userId": userId,
      "batchId": batchId,
    }
    const resAfterUpdatingCourseStarted = axios.post(variables.API_URL + 'api/UpdateCoursStartStatus', body);
    // console.log("resAfterUpdatingCourseStarted : ", resAfterUpdatingCourseStarted);
    if (resAfterUpdatingCourseStarted.isSuccess) {
      sessionStorage.setItem("isCourseStarted", "True");
    }
  }
}

const ApiCallingForAuto = (data) => {
  // console.log("Api calling for auto certificate generation : ", data);
  var userId = sessionStorage.getItem("login-userId");
  // alert("calling api for ApiCallingForAuto");
  const body = {
    "userId": userId,
    "courseId": data.courseId,
    "projectId": data.projectId,
    "batchId": data.batchId
  }
  axios.post(variables.API_URL + 'api/GenearateCertificate_v1', body).then((res) => {
    // console.log("response from api ApiCallingForAuto : ", res);
  }).catch((err) => {
    // console.log("error from api ApiCallingForAuto : ", err);
  });
}
export { apiCallingForCourseStarted, ApiCallingForAuto }