import Primarybtn from "../Shared/Primarybtn";
import Secondarybtn from "../Shared/Secondarybtn";
import TextField from '../Shared/TextField'

export default function ProgressSteps(props) {

    // console.log("props in ProgressSteps : ",props)
    return (
        <>
            <div id="progressbarContainer">
                <div id="emptyProgressbar"></div>
                <div id="completedProgress">
                </div>
                <ul className="p-0">
                    {
                        props.Step2Active ?
                        <li id="stepLable2" class="activeStep"><img src="../../Assets/media/icons/Active/Step-Completed.svg" class="w-36p"></img></li> :  <li id="stepLable1" class="activeStep"><img src="../../Assets/media/icons/Active/step01-active.png"></img></li>
                    }
                    
                    {
                        props.Step2Active &&
                        <li id="stepLable2" class="activeStep"><img src="../../Assets/media/icons/Active/step02-active.png" class="w-36p"></img></li>
                    }
                    {/* <img src="../../Assets/media/icons/Active/Step-Completed.svg" class="w-36p"></img> */}
                    {
                        !props.Step2Active &&
                        <li id="stepLable2" class=""><img src="../../Assets/media/icons/Inactive/ic_36_step02_inactive.svg"></img></li>
                    }
                    {
                        props.Step3Active &&
                        <li id="stepLable3" class="activeStep"><img src="../../Assets/media/icons/Active/step03-active.png"></img></li>
                    }
                    {/* {
                        !props.Step3Active &&
                        <li id="stepLable3" class=""><img src="../../Assets/media/icons/Inactive/ic_36_step03_inactive.svg"></img></li>
                    } */}
                    {/* {
                        props.Step4Active &&
                        <li id="stepLable4" class="activeStep"><img src="../../Assets/media/icons/Active/step04-active.png"></img></li>
                    }
                    {
                        !props.Step4Active &&
                        <li id="stepLable4" class="M1"><img src="../../Assets/media/icons/Inactive/ic_36_step04_inactive.svg"></img></li>
                    } */}
                </ul>
            </div>
        </>
    )

}