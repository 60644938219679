

import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';



//import PopupOne from '../Shared/PopupOne';

export default function FutureBatchReminder(props) {
  return (
    <>
      <div class="event-details">
        <h7 className="reminder d"><img src="../../Assets/Linqskills_Icons/Remind Clock_20.svg" class="" />Course training starts on </h7>
        <div class="calendar-text mt-2">
          <img src="../../Assets/Linqskills_icons/calendar.svg" class="" />
          <h6>{props.batchStartsOn}</h6>
        </div>
      </div>
    </>
  )

}


