import React from "react";



//import PopupOne from '../Shared/PopupOne';

export default function SearchPanel() {
    return (
        <>
            <div class="search-panel ">
            <div class="input-group">
            <img src="../../Assets/Linqskills_Icons/admin/search-icon.svg" class="me-1"></img>
  <input type="text" class="form-control" placeholder="Search by Batches Details" aria-label="Username"></input>
    </div>


              
            </div>
        </>
    )

}


