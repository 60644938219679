import PdfComponent from "../../components/Shared/pdfComponent";
import { variables } from "../../components/Shared/Variables";
import Translate from '../../components/Shared/Translate';
import CompletedTestRgsa from './completeRgsa';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router";


const Resources = () => {
  const rgsaResourceData = useSelector((state) => state.courseindex.rgsaResourceData);
  var coursedata = JSON.parse(sessionStorage.getItem("coursedata"));
const batchID = coursedata.batchId
 

  const navigate = useNavigate()
  const examMode="pdf"
  const goToTopicPage = (subnode) => {
    navigate( `/TopicDetailsRgsa/${subnode.nodeName}/${subnode.nodeId}/${batchID}/${subnode.deliveryMode}/${examMode}`);
  }  
  const { moduleId } = useParams();
  const [Resources,setResources] = useState([]);

  useEffect(()=>{
   if(rgsaResourceData.length > 0){
    
    // console.log("rgsaResourceData : ",rgsaResourceData);
    let tempdataList = rgsaResourceData.filter(
      (node) => node.nodeClassification === "Sub" && node.contentNodeID == moduleId
    );
    // console.log("tempdataList in resources : ", tempdataList);
    setResources(tempdataList)
   }
  },[])

  return (
    <>
      <div class="pb-3">
        {
          Resources.length > 0 ? 
          <>
          {
          Resources.map((item,idx)=>{
            // console.log(`item of rgsaResourceData : ${idx} :  `,item);
            return(
              <>
              <div class="d-flex justify-content-around pdf-comp mb-3">
          <div>
            <img
              src="../../Assets/Linqskills_Icons/pdf-rgsa.svg"
              class="me-2"
            ></img>
          </div>
          <div class="col">
            <h5>
              {item.nodeName}</h5>
          </div>
          <div onClick={()=>goToTopicPage(item)} >
            <img
              src="../../Assets/Linqskills_Icons/rgsa-rightbtn.svg"
              class="ms-1"
            ></img>
          </div>
        </div>
              </>
            )
          })
          }
          </> : ""
        }
      </div>
    </>
  )

}

export default Resources