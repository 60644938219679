// Import the main component

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { pdfjs } from 'react-pdf';

// Import the styles


const PdfComponent = (props) => {
   

  // console.log("props in PdfComponent : ",props);

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu behavior
  };

  return (
    <div className=''>
      <div
        className="rpv-core__viewer"
        style={{
          border: '1px solid #ccc',
          display: 'flex',
          height: 100,
        }}
        onContextMenu={handleContextMenu}
      >
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
        <Viewer fileUrl={props.url} />
        </Worker>
      </div>
    </div>
  );
}

export default PdfComponent;




