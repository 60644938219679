

import React, { useEffect, useState } from "react";
import NavOne from '../../components/NavOne.js';
import Breadcrumb from '../CourseIndex/Breadcrumb';
import CourseCard from "../Shared/CourseCard.js";
import PrivateRoute from "../Shared/PrivateRoute.js";
import ProfileCard from "./ProfileCard.js";
import ProfileContent from "./ProfileContent.js";
import ProfileDetails from "./ProfileDetails.js";
import { variables } from '../Shared/Variables.js';
import { updateChild, updateExtended, updateParent } from "../../features/breadcrumb/breadcrumbSlice";
import { useDispatch } from "react-redux";
import Footer from "../Shared/Footer.js";
import SettingsDetails from "./SettingsDetails.js";
import UploadPopup from "./UploadPopup.js";
import { useSSR } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import HamburgerIcons from "../Shared/HamburgerIcons.js"
import Translate from '../Shared/Translate';

import MyPerformance from "../Shared/MyPerformance.js";


export default function UserProfile() {
  const location = useLocation();
  const propsData = location.state;
  // console.log("propsData : ", propsData);
  const [isShow, setIshow] = useState(propsData ? propsData :"home")
  const [isShowSettings, setIsShowSettings] = useState("Edit Password");
  const [userProfileData, setUserProfileData] = useState();
  const [showMainDiv, setShowMainDiv] = useState(true);

  const gettingUserProfile = () => {
    let UserId = sessionStorage.getItem("login-userId")
    // // console.log("login-userId : ", UserId);
    fetch(variables.API_URL + `api/GetUserData/${UserId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // console.table("data of user profile : ", JSON.stringify(data));
        if (data.isSuccess) {
          // console.log("setting user profile data in userProfile.js : ", data.data);
          setUserProfileData(data.data);
          sessionStorage.setItem("profilePic", data.data.profilePic);
        }
      });
  }
  const dispatch = useDispatch();
  useEffect(() => {
    gettingUserProfile()
    dispatch(updateExtended("false"))
    dispatch(updateParent('Edit Profile'))
    dispatch(updateChild('Profile Details'))
  }, [])

  const handleNavigate = () => {
    // console.log("calling handleNavigate")
    setIshow("home");
  }

  const HandleHamburgerClick = (data) => {
    setShowMainDiv(data);
  }


  return (
    <>
      <NavOne footer={true}></NavOne>

      <div class="container-fluid body-wrapper bg-default pb-3">
        <div class="container p-0">
        {isShow != "home" ?
          <Breadcrumb redirectLink="mycourses" isTest={true} handleNavigate={() => handleNavigate()} /> :""
          }
        </div>
        <div class="container pb-4">
       
          {/* <HamburgerIcons showHamburgerIcon={showMainDiv} isShow={isShow} setIshow={setIshow} isShowSettings={isShowSettings} setIsShowSettings={setIsShowSettings} hamburgerParentCallBack={HandleHamburgerClick} /> */}
          <div class={isShow != "home" ? "course-indexcontent" : "course-indexcontent mt-5"}>
            {isShow == "home" ?
              <>
                <ProfileCard isShow={isShow} setIshow={setIshow} isShowSettings={isShowSettings} setIsShowSettings={setIsShowSettings} userProfileData={userProfileData} />
                <ProfileContent />
              </> :
              isShow == "edit" ?
                <ProfileDetails gettingUserProfile={gettingUserProfile} setIshow={setIshow} userProfileData={userProfileData} />
                :
                isShow == "setting" ?
                  <>
                    <ProfileCard isShow={isShow} setIshow={setIshow} setIsShowSettings={setIsShowSettings} userProfileData={userProfileData} />
                    <SettingsDetails gettingUserProfile={gettingUserProfile} userProfileData={userProfileData} isShowSettings={isShowSettings} />
                  </>
                  : ""
            }
          </div>
          {/* <MyPerformance/> */}
        </div>

      </div>
      <Footer />
    </>


  )

}


