import React from 'react';
//import Message from './components/message';


export default function AdminAccordion() {

  return (
    <>
      
<div class=" accordion" id="accordionExample">
    <div class="accordion mb-4 p-0" id="accordionExample">
        <div class="accordion-item box-shadow">
            <div class="accordion-header topic-header">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne0" aria-expanded="true" aria-controls="collapseOne"><div><p class="number-badge mb-0">1</p></div> <h5 class="m-0 text-truncate"> Virtual Session Stages </h5></button>
            </div>
            <div class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="accordionExample" id="collapseOne0">
              <div class="accordion-body border-top">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 d-flex pb-4">
                        <div class="col-lg-1 left-contentIcon ">
                        <img src="../../Assets/Linqskills_Icons/forward-actionblue.svg" class="cursor-pointer"></img>
                        </div>
                          <div class="col  ps-2 mt-6p">
                          <h5 class="bold-500 mb-1">Virtual Session : Not Yet Scheduled</h5>
                      <div class="col-lg-12 session-details">
                        <div class="me-2 d-flex">
                          <div>
                            <img src="../../Assets/Linqskills_Icons/interactivegallery-multicolor.svg" class="pe-1"></img>
                              </div>
                              <div>
                                <span class="h7 align-self-center">1 mins</span>
                                </div>
                                </div>
                                </div>
                          </div>
                          <div class="col flex-shrink-1 create-action">
                       
                            <img src="../../Assets/Linqskills_Icons/forward-actionblue.svg" class="float-end cursor-pointer">
                            </img>
                            <h6 class="float-end text-uppercase mb-0 mt-2 me-2">
                            <img src="../../Assets/Linqskills_Icons/calendartime-grey.svg" class="cursor-pointer me-1">
                            </img>
                              Crerate Schedule</h6>
                          </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</div>
          </>
      )
  
}


