import Secondarybtn from "../Shared/Secondarybtn";
import CompletedReminder from "../MyCourses/CompletedReminder";
import PendingReminder from "../MyCourses/PendingReminder";
import FutureBatchReminder from "../MyCourses/futureBachReminder";
import moment from "moment";
import Translate from '../Shared/Translate';
var title = "", btnVal = "", btnDisable = "";

function ValidateCourseDate(dt) {
    var currentDt = moment().format("DD-MMM-YYYY");
    var selectedDt = moment(dt, "DD/MM/YYYY").format("DD-MMM-YYYY");
    return moment(selectedDt).diff(moment(currentDt), "days");
}

function CompletedCourseTitle(certificationDate, isCertificateToShow, deliveryMode, examMode, markForGrading, isPassed, restrictCandidateAccess, activeStage, externalExam) {
    // console.log(`certificationDate=${certificationDate}  :: isCertificateToShow=${isCertificateToShow}  :: deliveryMode=${deliveryMode}  :: examMode=${examMode} :: markForGrading=${markForGrading} :: isPassed=${isPassed}  :: restrictCandidateAccess=${restrictCandidateAccess}`);
    //if (isPassed && (certificationDate != null) && isCertificateToShow && (deliveryMode != 4) && (examMode != "Theory") && markForGrading) {
    // console.log("ffffff: ", moment())
    // console.log("dddddddd : ", moment(certificationDate).isAfter(moment()))
    // console.log(`restrictCandidateAccess : ${restrictCandidateAccess}  ::: activeStage : ${activeStage}`)
    if (activeStage === 4 && restrictCandidateAccess === false) {
        title = "Training Completed On";
        btnVal = "View Course";
        btnDisable = (isPassed && (certificationDate != null) && isCertificateToShow && (deliveryMode != 4) && (examMode != "Theory") && markForGrading && !(moment(certificationDate).isAfter(moment()))) ? "disabled" : "";
    } else if (activeStage == 1) {
        btnVal = "View Score";
        if (externalExam) {
            title = "Test Completed ";

        }
    } else {
        title = "Course Completed On";
        btnVal = "Download Certificate";
        btnDisable = "";
    }
}

export default function CourseCard(props) {
    console.log("corseData : ", props)

    // console.log("btnDisable res : ", btnDisable)
    // console.log("props.isDisabled : ", props.isDisabled);
    // console.log("props.isBatch : ", props.isBatch)
    if (props.type === "completed") {
        CompletedCourseTitle(props.certificationDate, props.isCertificateToShow, props.deliveryMode, props.examMode, props.markForGrading, props.isPassed, props.restrictCandidateAccess, props.activeStage, props.externalExam);
    }

    const element = props.type === "completed" ? (
        <div className="card card-modify">
            <img src={props.courseIcon} class="card-img" />
            <div class="card-body cardbody-height">
                <h5 class="card-title line-clamp-two mb-0">{props.courseName}</h5>
                <div class="course-content">
                    <div class="event-details">
                        {props.externalExam === true ? <PendingReminder txtValue={title} externalExam={props.externalExam} /> : <CompletedReminder txtValue={title} />}

                        {(props.externalExam != true) && <div class="calendar-text mt-2">
                            <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                            <h6>{props.courseCompletedOn !== null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY | hh:mm A')}</h6>
                        </div>}
                        {(props.externalExam == true) && <div class="calendar-text mt-2">
                            <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                            {/* <h6>{props.courseCompletedOn == null ? "" : moment(props.courseCompletedOn).format('DD MMM YYYY | hh:mm A')}</h6> */}
                            <h6>{moment(props.courseCompletedOn).format('DD MMM YYYY | hh:mm A')}</h6>
                            
                        </div>}
                        {
                            (props.externalExam === true) && <> <div >
                            </div>
                                <div class="calendar-text mt-2">
                                    <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                    <h6>0 Pending</h6>
                                </div>


                            </>
                        }
                    </div>
                </div>
                <div class="course-action pt-3">
                    <Secondarybtn buttonID="downloadCertificate" isDisabled={btnDisable} buttonVal={btnVal} certificate={props.certificate} buttonClick={props.buttonClick}></Secondarybtn>
                    {/* <button id="btnSubmit" type="button" className="text-uppercase continue-popup secondary-btn w-100" onClick={props.buttonClick}>{btnVal}
                        <img src="../../Assets/Linqskills_Icons/arrow_forwardblue.svg" className=""></img>
                    </button> */}
                </div>
            </div>
        </div >
    ) : (
        <div class="card card-modify">
            <img src={props.courseIcon} class="card-img" />
            <div class="card-body cardbody-height">
                {/*<span class="course-type d-inline-block text-truncate w-50 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={sectorName}>{sectorName}</span>*/}

                <h5 class="card-title line-clamp-two mb-0">{props.courseName}</h5>

                <div class="course-content">
                    {
                        ((props.batchID === 0) && (props.egaPendingCnt === 0)) ?
                            <>
                                <div class="course-details">
                                    <div class="duration m-0 mt-2">
                                        <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                    </div>
                                    <p>Awaiting training dates details</p>
                                    <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
                                        <span class="">{props.description}</span>
                                    </p>
                                </div>
                            </> : ((props.batchID !== 0) && (props.vcStartTime == null) && (ValidateCourseDate(props.batchStartDate) <= 0)) ?
                                <>
                                    <div class="course-details">
                                        <div class="duration m-0 mt-2">
                                            <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                        </div>

                                        <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
                                            <span class="">{props.description}</span>
                                        </p>
                                    </div>
                                </>
                                :
                                ((ValidateCourseDate(props.batchStartDate) <= 0) &&
                                    ((props.activeStage === 4) && (props.vcStartTime == null))) &&
                                (<>
                                    <div class="course-details">
                                        <div class="duration m-0 mt-2">
                                            <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                        </div>

                                        <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
                                            <span class="">{props.description}</span>
                                        </p>
                                    </div>
                                </>)
                    }
                    {
                        (props.egaPendingCnt > 0) ?

                            <div class="event-details p-0">
                                {/* <div class="reminder">
                                        <img src="../../Assets/Linqskills_Icons/reminder-clock.svg" class="" />

                                        <span className="">Eligibility test pendxing </span>

                                    </div> */}
                                {
                                    props.isCandidateFormSubmitted == false && props.isFormDetailsMandate == true ?
                                        <>
                                            <div class="course-details">
                                                <div class="duration m-0 mt-2">
                                                    <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class="" /><p>{props.duration}</p>
                                                </div>

                                                <p class="line-clamp-three m-0 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title={props.description}>
                                                    <span class="">{props.description}</span>
                                                </p>
                                            </div>
                                        </> :
                                        <>
                                            <div class="event-details">
                                                <div >
                                                    <PendingReminder txtValue={"Eligibility test pending"} />
                                                </div>
                                                <div class="calendar-text mt-2">
                                                    <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                                    <h6>{props.egaPendingCnt} Pending</h6>
                                                </div>
                                            </div>

                                        </>
                                }
                            </div>
                            :
                            (props.activeStage === 4) && (ValidateCourseDate(props.batchStartDate) > 0) ?
                                <>
                                    <FutureBatchReminder batchStartsOn={moment(props.batchStartDate, 'DD/MM/YYYY').format('DD MMM YYYY')} />
                                </>

                                :
                                (props.activeStage === 4) && (props.vcStartTime != null) &&
                                <>
                                    <div class="event-details">
                                        <div class="reminder">
                                            <img src="../../Assets/Linqskills_Icons/reminder-clock.svg" class=""></img>
                                            <span className="">Online class scheduled on</span>


                                        </div>
                                        <div class="calendar-text mt-2">
                                            <img src="../../Assets/Linqskills_icons/calendar-Multicolorgreen.svg" class="" />
                                            <h6>{props.vcStartTime}</h6>
                                        </div>
                                    </div>
                                </>

                    }
                </div>
                <div class="course-action pt-3">
                    <button type="button" onClick={props.buttonClick} class={props.isBatch ? "primary-btn w-100" : "w-100 disabled-btn"} disabled={props.isBatch ? false : true}>

                        <Translate textKey={props.buttonValue} />
                        <img src="../../Assets/Linqskills_icons/arrow_forwardwhite.svg" class="" />
                    </button>
                </div>

            </div>
        </div>

    );

    return (
        <div>
            {element}
        </div>
    )
}