import { combineReducers } from "@reduxjs/toolkit";
import breadcrumbSlice from "./breadcrumb/breadcrumbSlice";
import courseIndexSlice from "./CourseIndex/courseIndexSlice";
import languageSlice from "./Language/languageSlice";
import navbarSlice from "./navbar/navbarSlice";
import pageErrorSlice from "./pageError/pageErrorSlice";
import projectSlice from "./project/projectSlice";

const rootReducer = combineReducers({
  breadcrumb: breadcrumbSlice,
  courseindex: courseIndexSlice,
  language: languageSlice,
  navbar : navbarSlice,
  pageError : pageErrorSlice,
  project : projectSlice
});

export default rootReducer;