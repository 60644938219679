import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isNavHide: false,
  isActiveLi : "Assessment",
};

const navbarSlice = createSlice({
    name:"navbar",
    initialState,
    reducers:{
        updateNavbar : (state,action)=>{
            state.isNavHide = action.payload
        },
        updateIsActiveLi : (state,action)=>{
            state.isActiveLi = action.payload
        },
    }
})

export const {updateNavbar,updateIsActiveLi} = navbarSlice.actions;

export default navbarSlice.reducer;