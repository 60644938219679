import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registerErroeMess: '',
  registerErrorContact : "",  
  urlRegisterPage : "",
  componentName: ''
};

const pageErrorSlice = createSlice({
    name:"pageError",
    initialState,
    reducers:{
        updateRegisterError : (state,action)=>{
          // console.log("updateRegisterError called : ",action.payload);
            state.registerErroeMess = action.payload.errorMess
            state.registerErrorContact = action.payload.contact
        },
        updateUrlRegister : (state,action)=>{
          // console.log("updateUrlRegister called : ",action.payload);
          state.urlRegisterPage = action.payload
        },
       setComponentName:(state,action)=>{
           
           state.componentName=  action.payload
          }
    }
})

export const {updateRegisterError,updateUrlRegister,setComponentName} = pageErrorSlice.actions;

export default pageErrorSlice.reducer;