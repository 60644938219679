import React,{useEffect} from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import { variables } from '../Shared/Variables';
import { updateNavbar } from '../../features/navbar/navbarSlice';
import { useDispatch, useSelector } from "react-redux";
import { apiCallingForCourseStarted } from "../generalFunctions/apiCalling.js";


export default function Zoom(props) {
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("login-userId")
  // console.log("props in zoom : ", props);
  ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');
  ZoomMtg.preLoadWasm();
  // ZoomMtg.prepareJssdk();
  ZoomMtg.prepareWebSDK();

  // loads language files, also passes any error messages to the ui
  ZoomMtg.i18n.load('en-US');
  ZoomMtg.i18n.reload('en-US');
  var sdkKey = variables.sdkKey;
  const userName = sessionStorage.getItem('login-userName');
  const firstName = sessionStorage.getItem('login-firstName');
  const meetingId = props.props.webinarMeetingId;
  const endUrl = window.location.origin + '/#/VComplete';
  // const endUrl = window.location.origin +'/#/VirtualSession/18772';
  var passWord = props.props.student_join_url;
  const leaveUrl = endUrl
  var token = '';

  /* function isZoomAppInstalled() {
    // Check if the Zoom app's URL scheme is registered
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return userAgent.indexOf("Zoom") !== -1;
  }
 */
  function getSignature() {
    dispatch(updateNavbar(true));
    //e.preventDefault();
    let meetingNumber = parseFloat(meetingId);
    let passCode = passWord.split('=')[1];
   // const meetingUrl = `zoomus://us06web.zoom.us/join?action=join&confno=${meetingId}&pwd=${passCode}`;

    // Check if the Zoom app is available
   // const isZoomAppAvailable = isZoomAppInstalled();
  
    // if (isZoomAppAvailable) {
    //   // Open Zoom meeting in Zoom app
    //   window.open(meetingUrl);
    // } else {
    fetch(variables.API_URL + 'api/GetSignature/' + meetingNumber,
      {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("JWTToken")
        },
      }).then(response => {
        return response.json();
      }).then(data => {
        // console.log("data.data ::: ", data)
        token = data.data;
       // const zoomMeetingUrl = `https://us06web.zoom.us/j/${meetingId}?pwd=${passCode}&uname=${encodeURIComponent(userName)}`;
     // window.open(zoomMeetingUrl, '_blank'); // Open meeting URL in a new tab

    //  setTimeout(() => { 
        startMeeting(token);
  //    }, 5000);  // Adjust the timeout as needed
        sessionStorage.setItem("endSession", null);
        sessionStorage.setItem("endSession", 'true');

      })
      .catch(error => {
        console.error(error)
      })
    //}

    function startMeeting(signature) {
      document.getElementById('zmmtg-root').style.display = 'block'
      ZoomMtg.init({
        leaveUrl: leaveUrl,
        success: (success) => {
          // console.log(success)
                // Enter fullscreen mode
                const element = document.getElementById('zmmtg-root');

                // Check if the browser supports fullscreen API
                if (element.requestFullscreen) {
                  element.requestFullscreen();
                } else if (element.mozRequestFullScreen) { // Firefox
                  element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
                  element.webkitRequestFullscreen();
                } else if (element.msRequestFullscreen) { // IE/Edge
                  element.msRequestFullscreen();
                } else {
                  // Fullscreen API is not supported
                  console.error('Fullscreen API is not supported');
                }
                

          ZoomMtg.join({
            signature: signature,
            sdkKey: sdkKey,
            meetingNumber: meetingId,
            passWord: passCode,
            // userName: props.props.candidateid,
            // displayName : "ppppppp",
            userName: props.props.username == null || props.props.candidateid == null ? firstName == "" ? "Participant" : firstName :  `${props?.props?.username?.substring(0, 15)}_${props.props.candidateid}`,
            success: (success) => {
              // console.log(success)
           
         
            },
            error: (error) => {
              // console.log(error)
            }
          })
        },
        error: (error) => {
          // console.log(error)
        }
      })
    }
  }

    // useEffect to reset zmmtg-root display property on component unmount
    useEffect(() => {
      return () => {
        document.getElementById('zmmtg-root').style.display = 'none';
      };
    }, []);
  
  return (
    <>
      <div className="App">

        <main>
          {/* For Component View */}
          <div>
            {/* Zoom Meeting SDK Component View Rendered Here */}
          </div>
          {/* <div id='userName'>
          {props.props.username}
          </div> */}
          <button onClick={getSignature} disabled={props?.isButtonEnabled ? false : true} class={props?.isButtonEnabled ? "primary-btn m-auto text-uppercase" : "disabled-btn m-auto text-uppercase"} >Join</button>
          {/* <a target="_blank" href={props.props.student_join_url}>
        <button disabled={props?.isButtonEnabled ? false : true} class={props?.isButtonEnabled ? "primary-btn m-auto text-uppercase" : "disabled-btn m-auto text-uppercase"} >Join</button>
        </a> */}
          {/* onClick={getSignature} */}
        </main>
      </div>
    </>
  );

}


