import { Modal } from 'react-bootstrap';
import Translate from "../Shared/Translate"
import VideoPlayer from "../Shared/VideoPlayer";
import { useRef, useEffect } from 'react';
import PdfComponent from '../Shared/pdfComponent';
import PDFViewer from '../Shared/pdfView';
import AdobePdfViewer from '../Shared/adobePdf';

const ViewTutorialPopUp = (props) => {
  // console.log("props in ViewTutorialPopUp : ", props);
  const embedRef = useRef(null);
  let fileName = ""
  let tempfilename = props?.src?.split("/");
  // // console.log("tempfilename : ", tempfilename[tempfilename.length -1]);
  fileName = tempfilename[tempfilename.length - 1];


  useEffect(() => {
    // Access the embed element using embedRef.current
    //  alert('hello')

    // Add the event listener for the embed element when the component mounts
    if (embedRef.current) {
      // alert('hello')
      // console.log(embedRef.current, "embed reffff")
      embedRef.current.addEventListener('contextmenu', (e) => {

        e.preventDefault();
      });
    }
  }, []);

  //// console.log(JSON.stringify(embedRef)+"embed")
  // console.log("props.src in view control pop up : ", props.src);
  // console.log("file name in view control pop up : ", fileName);


  return (
    <div className='popup-video-modal'>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`couponAppliedpop ${props.extensionType === 'pdf' && 'view-pdf'}`}
        backdrop={false}
      >
        <Modal.Body>
          <div class="popup-center text-lg-left" oncontextmenu="return false;" >
            <div className='d-flex justify-content-between pb-3 align-items-center'>
              {
                props.showHeading ?
                  <div>
                    <h6 class="p-0 m-0">
                      <Translate textKey={props.title} />
                    </h6>
                  </div>
                  : <></>
              }
              <div class="col">
                <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="cursor-pointer float-end"
                  onClick={props.onHide}
                ></img>
              </div>
            </div>
            {/* {
              (props.extensionType != "") && (props.extensionType != undefined) ?
                props.extensionType == "pdf" ? <AdobePdfViewer pdfUrl={props.src} pdfPlayer="adobe" mode="shoebClientID" fileName={fileName} ></AdobePdfViewer> : props.extensionType == "mp4" ? <VideoPlayer src={props.src} autoPlay={props.autoPlay ? true : false} /> : ""
                : <VideoPlayer src={props.src} autoPlay={props.autoPlay ? true : false} />
            } */}
            
            {
              (props.extensionType != "") && (props.extensionType != undefined) ?
                props.extensionType == "pdf" ? <AdobePdfViewer url={props.src} pdfPlayer="normal" ></AdobePdfViewer> : props.extensionType == "mp4" ? <VideoPlayer src={props.src} autoPlay={props.autoPlay ? true : false} /> : ""
                : <VideoPlayer src={props.src} autoPlay={props.autoPlay ? true : false} />
            }
          </div>
        </Modal.Body>
      </Modal>
      <style>
        {`
      .modal-content {
        border-radius: 16px !important;
      }
      `}
      </style>
    </div>
  )
}

export default ViewTutorialPopUp;






