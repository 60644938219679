import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProject : {},
  url : ""
};
const projectSlice = createSlice({
    name:"project",
    initialState,
    reducers:{
        updateIsProject : (state,action)=>{
            state.isProject = action.payload
        },
        updateUrl : (state,action)=>{
            state.url = action.payload
        },
    }
})

export const {updateIsProject,updateUrl} = projectSlice.actions;

export default projectSlice.reducer;