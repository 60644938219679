import React, { useEffect ,useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  callingNextPrev,
  updateFilterRetained,
  updateNextPrevUpdated,
  updateIndex,
  updateVideo,
  updateNodeVal,
  callingDispose,
  updateOnlineLec,
  updateVideoIndex
} from "../../features/CourseIndex/courseIndexSlice";
const Breadcrumb = (props) => {
  const dispatch = useDispatch();
  // // console.log("props in Breadcrumb : ",props)
  const parentNode = useSelector((state) => state.breadcrumb.parentNode);
  const childNode = useSelector((state) => state.breadcrumb.childNode);
  const [toggle, setToggle] = useState(false);
  const extendedBreadCrumb = useSelector(
    (state) => state.breadcrumb.extendedBreadCrumb
  );
  const grandchildNode = useSelector(
    (state) => state.breadcrumb.grandchildNode
  );
  const greatgrandchildNode = useSelector(
    (state) => state.breadcrumb.greatgrandchildNode
  );

  // // console.log("greatgrandchildNode in breadcrumb : ",greatgrandchildNode)
  const navigate = useNavigate();
  const handleNavigate = async() => {
    if(props.setIsBackBtnClicked){
      dispatch(callingDispose(true));
      dispatch(updateVideoIndex("-1"));
      await props.setIsBackBtnClicked(true);
      // alert("working for back btn click on self study page")
      // dispatch(updateVideo(false));
    }
    dispatch(updateFilterRetained(true));
    dispatch(updateNextPrevUpdated(true));
    if(props.redirectLink == "mycourses"){
      dispatch(updateIndex("CO"));
      dispatch(updateNodeVal([]));
      dispatch(updateOnlineLec([]));
    }
    navigate(`/${props.redirectLink}`);
  };

  useEffect(()=>{
    //  setToggle(!toggle);
  },[greatgrandchildNode,grandchildNode])
  return (
    <div class="row breadcrumb">
      <div class={`d-flex ${props.hamburgerClassSow ? "breadcrumbMargin": ""}`}>
        {props.hamburgerClassSow == undefined || props.hamburgerClassSow ? (
          <>
            {props.isTest ? (
              <img
                src="../../Assets/Linqskills_Icons/breadcrumb-back.svg"
                onClick={props.handleNavigate}
                class="cursor-pointer hamburger-margin"
              ></img>
            ) : (
              <>
              <img
                src="../../Assets/Linqskills_Icons/breadcrumb-back.svg"
                onClick={handleNavigate}
                class="cursor-pointer hamburger-margin display-desktop"
              ></img>
             
             {/* Mobile BaCK btn */}
              <img
              src="../../Assets/Linqskills_Icons/breadcrumb-back.svg"
              onClick={handleNavigate}
              class="cursor-pointer hamburger-margin display-mobile"
            ></img>
             <h5 class="bold-500 mt-1 mb-0 cursor-pointer display-mobile">
             Back
            </h5>
             
        </>
            )}
            <h5 class="bold-500 mt-1 mb-0 cursor-pointer display-desktop" style={{ color: "#8b5ad1" }}>
              {parentNode}
            </h5>
            <h5
              class={`${
                extendedBreadCrumb === "true" ? "bold-500" : "active-breadcrumb"
              } mt-1 display-desktop mb-0 text-truncate `}
            >
              <img
                src="../../Assets/Linqskills_Icons/breadcrumb2.svg"
                class="ps-2 pe-2"
              ></img>
              {childNode}
            </h5>
            {extendedBreadCrumb === "true" && (
              <>
                <img
                  src="../../Assets/Linqskills_Icons/breadcrumb2.svg "
                  class="display-desktop ps-2 pe-2"
                ></img>
                <h5 class="bold-500 mt-1 mb-0 display-desktop text-truncate">
                  {grandchildNode}
                </h5>
                <h5 class="active-breadcrumb mt-1 mb-0 display-desktop text-truncate">
                  <img
                    src="../../Assets/Linqskills_Icons/breadcrumb2.svg"
                    class="ps-2 pe-2"
                  ></img>
                  {greatgrandchildNode}
                </h5>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
