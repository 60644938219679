import { useNavigate } from "react-router";
import Cookies from "universal-cookie"
import { Modal } from 'react-bootstrap';
import React, { useState } from "react";
import { variables } from '../Shared/Variables';
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustumLoader from "../Shared/CustumLoader";

export default function UploadPopup(props) {
  const [picture, setPicture] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [btnUploadFiles, setBtnUploadFiles] = useState(true);
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [errorMess, setErrorMess] = useState("");

  // const closePopup = () => {
  //   props.setIsUploadPopup(false);
  // }
  const chooseFileHandler = (e) => {
    e.stopPropagation();

    // console.log("chooseFileHandler : ", Array.prototype.slice.call(e.target.files)[0].size);
    if(Array.prototype.slice.call(e.target.files)[0].size / 1000 < 2000){
      setPicture(Array.prototype.slice.call(e.target.files))
      setBtnUploadFiles(false);
      setErrorMess("");
    } else {
      // console.log("file is greater then 2mb")
      setErrorMess("The profile picture should be less than 2 M")
    }
    e.target.value = ''
  }
  const removeImage = () => {
    setPicture([]);
    setBtnUploadFiles(true)
  }
  const uploadFiles = async () => {
    try {
      setBtnUploadFiles(true);
      setIsLoader(true);
       
      const userId = sessionStorage.getItem("login-userId")
      let formData = new FormData();
      formData.append('file', picture[0]);
      formData.append('ProfilePic','""');
      formData.append('userId', userId);
      // console.log("formData : ", formData);
      const res = await axios.post(variables.API_URL + "api/UpdateProfilePicture", formData);
      // console.log("res : ", res);
      if (res.data.isSuccess) {
        // console.log("every thing is fine : ");
        setPicture([])
        setIsLoader(false)
        props.onClose("successfull")
        setBtnUploadFiles(true)
      } else {
        // console.log("something went wrong");
        setIsLoader(false)
        setBtnUploadFiles(false)
      }
      // // filesToUpload is a [{},{}] in which storing files and there values in obj
      // for (let i = 0; i < filesToUpload.length; i++) {
      //   // console.log(`i = ${i} filesToUpload : ${filesToUpload[i].queId}`);
      //   if (filesToUpload[i].queId == queId) {
      //     // console.log(`setting new files for que = ${filesToUpload[i].queId}`);
      //     files = filesToUpload[i].files;
      //   }
      // }
      // // console.log("newFiles after : ", files);
      // files.map((it, ind) => {
      //   formData.append('files', files[ind]);
      // })
      // formData.append('userId', userId);
      // formData.append('TestId', testId);
      // formData.append('QuestionId', queId);
      // formData.append('AttemptNo', 1);

      // const res = await axios.post(variables.API_URL + "api/UplaodEGAFiles", formData);
      // // console.log("res : ", res);
      // if (res.data.isSuccess) {
      //   // console.log("files uploaded successfully");
      //   if (res.data.nameList.length == files.length) {
      //     const result = filesToUpload.map((obj) => {
      //       if (obj.queId === queId) {

      //         let newUploadedFiles = obj.uploadedFiles;
      //         for (let i = 0; i < res.data.nameList.length; i++) {
      //           // console.log(` res.data.nameList[i] : ${res.data.nameList[i]}`);
      //           newUploadedFiles.push(res.data.nameList[i])

      //         }
      //         // console.log("newUploadedFiles after : ", newUploadedFiles);
      //         let fileAnsStr = newUploadedFiles.join('~');
      //         // console.log("fileAnsStr : ", fileAnsStr)

      //         // setFilesAns(fileAnsStr);
      //         return { ...obj, isUploaded: 2, files: [], uploadedFiles: newUploadedFiles, ansStr: fileAnsStr };
      //       }
      //       return obj
      //     })
      //     // console.log("result after success : ", result);
      //     setFilesToUpload(result);
      //     setIsLoader(false);
      //     // setBtnUploadFiles(false);
      //   }
      // } else {
      //   setIsLoader(false);
      //   setBtnUploadFiles(false);
      //   // console.log("something went wrong")
      // }
    } catch (error) {
      // console.log("error occured in uploading files : ", error);
      // setText(2)
      // setIsPopUpShow(true)
      setBtnUploadFiles(false)
      setIsLoader(false);
    }
  }
  return (

    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='couponAppliedpop'
        backdrop={false}
      >
        <Modal.Body>
        
         {/*  <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoader}

          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
                         {isLoader &&      <CustumLoader message="Loading..." />}

          <div class="popup-center text-lg-left select-medium">
            <img onClick={props.onClose} src="../../Assets/Linqskills_Icons/popup-close.svg" class="cursor-pointer float-end cross-icon2 "></img>
            <div class="row">
              <div class="d-flex  p-0 ms-0 mt-2 mb-2">
                <h6 class="m-0">
                  <img src="../../Assets/Linqskills_Icons/uploadsmall-grey.svg" class=" me-2"></img>
                  Upload Photo
                </h6>
              </div>
            </div>
            <div class="upload-files ps-0">
              <div class="row p-0">
                <h6>Choose a file and then click on “Upload File” </h6></div>
            </div>
            <div class="d-flex popups-label">
              <div class="file-input-wrapper m-auto">
                <input type="file" accept="image/png, image/gif, image/jpeg, image/jpg" onChange={(e) => chooseFileHandler(e)} id="file-input" class="hidden" />
                <label for="file-input" class="cursor-pointer primary-btn btn-change me-3 ">Image Gallery</label> </div>
              <div class="file-input-wrapper m-auto">
                <input type="radio" disabled={true} class="hidden" />
                <label for="" class="cursor-pointer disabled-btn btn-change me-3">Take Photo</label> </div>
            </div>
            <div class="col-lg-11 col-sm-12 pt-3 pb-3 text-center m-auto">
              <img src="../../Assets/Linqskills_Icons/upload-default.svg" class="w-auto"></img>
            </div>
            <div class="col-lg-11 col-sm-12 text-center formats-allowed m-auto"><h5 class="mb-2">Formats allowed: PNG, JPG, JPEG, GIF</h5><h5>Upload file of any size</h5></div>
            {picture.length > 0 ?
              <>
                {picture.map((item, index) => {
                  // // console.log("item.size in kb : ", item)
                  const splitedItem = item.name.split(".");
                  // console.log("splitedItem : ", splitedItem);
                  let extensionName = splitedItem[splitedItem.length - 1];
                  let truncFileName = splitedItem[0]
                  // // console.log("truncFileName : ", truncFileNsame, extensionName);
                  return (
                    <>
                      <div key={index} class="d-flex col-lg-12 pb-3 space-btwn">
                        <div class="d-flex col">
                          <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg" class="pe-2"></img>
                          <img src="../../Assets/Linqskills_Icons/upload-img.svg" class="pe-2"></img>
                          <div class="mt-2 d-flex">
                            <h6 class="line-clamp-one">{truncFileName}</h6>
                            <h6>.{extensionName}</h6>
                          </div>

                          {/* <h6 class="mt-2">{`${truncFileName}.${extensionName}`}</h6> */}

                        </div>

                        <div class="col d-flex float-end properties flex-end">
                          <h6 class="mt-2 ">{item.size / 1000 < 1000 ? `${(item.size / 1000).toFixed(1)} KB` : `${(item.size / 1000000).toFixed(1)} MB`} </h6>
                          <img src="../../Assets/Linqskills_Icons/close-small.svg" onClick={() => removeImage()} class=""></img>
                        </div>
                      </div>
                    </>
                  )
                })
                }
              </> :
              ""
            }
            <span>{errorMess}</span>
            <div class="col-lg-10 m-auto col-sm-12 pt-4 pb-4">
              <button onClick={uploadFiles} disabled={btnUploadFiles} className={btnUploadFiles ? "disabled-btn w-100" : "primary-btn w-100"} >
                <img src="../../Assets/Linqskills_Icons/uploadfile-btn.svg" class=""></img>Upload File</button></div>
          </div>
        </Modal.Body>
      </Modal>
      <section class="modal overlay">
      </section>
    </>
  )

}