

import React, { useEffect, useState } from "react";
import SessionHeading from "../EligibilityCheck/SessionHeading";
import axios from "axios";
import { variables } from '../Shared/Variables';
import TextField from '../Shared/TextField'
import Translate from '../Shared/Translate';
import TestPopup from "../Popups/TestPopup";
import Cookies from "universal-cookie";
import WelcomeMenu from "../Shared/WelcomeMenu";

import ProfileSettings from "./ProfileSettings";

export default function SettingsDetails({ isShowSettings, gettingUserProfile, userProfileData }) {
  // // console.log("Translate : ",Translate)
  const cookies = new Cookies();
  const userId = sessionStorage.getItem("login-userId")
  const [selectStyle, setSelectStyle] = useState("form-select form-dropdown input-bg select-change-placeholder");
  // console.log("userProfileData?.languageId in setting medium : ", userProfileData?.languageId);
  const [languageVal, setLanguageVal] = useState(userProfileData?.languageId);
  const [mediumInstructionLanguages, setMediumInstructionLanguages] = useState([]);
  const [langSaveBtn, setLangSaveBtn] = useState(true);
  const [isPopUpShow, setIsPopUpShow] = useState(false);
  const [messagePrompt, setMessagePrompt] = useState("");
  const [titlePrompt, setTitlePrompt] = useState("");
  const [text, setText] = useState("");
  var prompt = "<h5>SuccessFully Updated</h5>";
  var prompt2 = "<h5>Something went wrong please try again</h5>";
  var prompt3 = "ProfileSucccessLanguage"
  const [translatedvalue, setTranslateValue] = useState("");
  const setvalue = (translatedvalue) => {
    setTranslateValue(translatedvalue);
  };
  const passwordInitialValues = { oldPassword: "", fullname: "", password: "", confirmpassword: "" }
  const phoneInitialValues = { phonenumber: "", otp: "", otpRecived: "" }
  const emailInitialValues = { emailid: "", emailotp: "", emailOtpRecived: "" }
  const [passwordFormValues, setPasswordFormValues] = useState(passwordInitialValues);
  const [phoneFormValues, setPhoneFormValues] = useState(phoneInitialValues);
  const [emailFormValues, setEmailFormValues] = useState(emailInitialValues);
  const [passwordFormErrors, setPasswordFormErrors] = useState({});
  const [phoneFormErrors, setPhoneFormErrors] = useState({});
  const [emailFormErrors, setEmailFormErrors] = useState({});
  const [disabledClass, setdisabledClass] = useState("disabled-btn");
  const [disabled, setdisabled] = useState(true);
  // const [oldPass, setOldPass] = useState("");
  // const [newPass, setNewPass] = useState("");
  // const [confirmPass, setConfirmPass] = useState("");

  useEffect(() => {
    getAllLanguages();
  }, [])
  const getAllLanguages = async () => {
    let resAfterGetAllLanguage = await axios.get(variables.API_URL + "api/GetLanguages");
    // console.log("resAfterGetAllLanguage : ", resAfterGetAllLanguage);
    setMediumInstructionLanguages(resAfterGetAllLanguage.data.dataList);
  }


  const handleChange = (e, callingFrom) => {
    const { name, value } = e.target;
    // // console.log("name in password handle : ",name,value,callingFrom);
    if (callingFrom == "passUpdate") {
      setPasswordFormErrors({})
      setPasswordFormValues({ ...passwordFormValues, [name]: value })
    } else if (callingFrom == "phoneUpdate") {
      setPhoneFormErrors({});
      setPhoneFormValues({ ...phoneFormValues, [name]: value })
    } else if (callingFrom == "emailUpdate") {
      setEmailFormErrors({});
      setEmailFormValues({ ...emailFormValues, [name]: value })
    }
    if (e.target.value != "") {
      setdisabledClass("");
      setdisabled(false)
    }
    else {
      setdisabledClass("disabled-btn");
      setdisabled(true)

    }
  };

  const passwordUpdate = async () => {
    const errorState = await validatePassUpdate(passwordFormValues)
    // // console.log("errorState : ", errorState);
    setPasswordFormErrors(errorState)
    if (Object.keys(errorState).length > 0) {
      // // console.log("solve the errors")
    } else {
      // setdisabled(true);
      // setdisabledClass("disabled-btn");
      // // console.log("go call api : ")
      const body = {
        UserId: userId,
        OldPassword: passwordFormValues.oldPassword,
        NewPassword: passwordFormValues.password
      }
      // // console.log("body for update pass : ", body);
      let resAfterUpdatePassword = await axios.post(variables.API_URL + "api/UpdatePassword", body);
      // // console.log("resAfterUpdatePassword : ", resAfterUpdatePassword);
      if (resAfterUpdatePassword.data.isSuccess) {
        setPasswordFormValues({ ...passwordFormValues, oldPassword: "", password: "", confirmpassword: "" })
        // // console.log("Password updated succesfully");
        let promptMess = "ProfileSucccessLanguage";
        // // console.log("promptMess : ",promptMess);
        setMessagePrompt(`<h5>${resAfterUpdatePassword.data.returnMessage}</h5>`);
        setTitlePrompt("UPDATED");
        gettingUserProfile()
        setIsPopUpShow(true);
      } else {
        // // console.log("something went wrong while password update");
        setTitlePrompt("WARNING");
        setMessagePrompt(`<h5>${resAfterUpdatePassword.data.returnMessage}</h5>`)
        setIsPopUpShow(true);
      }
    }
  }
  const validatePassUpdate = (values) => {
    // // console.log("value in validatePassUpdate : ",values);
    const errors = {};
    var passReg = /^(?!.*\s)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!])[A-Za-z\d@!]{8,12}$/;
    // var nameReg = /^[A-Za-z ]+$/;
    var totalError = 0;
    // if (!values.fullname) {
    //   errors.fullname = "registration026";
    //   totalError++;
    // }
    // else if (!nameReg.test(values.fullname)) {
    //   errors.fullname = "registration031";
    //   values.fullname = "";
    // }

    // if (!values.oldPassword) {
    //   errors.oldPasswordError = "Profile001";
    //   totalError++;
    // }
    // if (values.oldPassword.length < 8) {
    //   errors.oldPasswordError = "registration027";
    //   totalError++;
    // }
    // if (!passReg.test(values.oldPassword)) {

    //   errors.oldPasswordError = "registration030";
    //   totalError++;
    // }

    if (!passReg.test(values.password)) {
      errors.passwordError = "registration030";
      totalError++;
    }
    if (values.password != values.confirmpassword) {
      errors.confirmPasswordError = "registration032";
      totalError++;
    }
    if (values.password.length < 8) {
      errors.passwordError = "registration027";
      totalError++;
    }
    if (!values.password) {
      errors.passwordError = "Login005";
      totalError++;
    }
    if (!values.confirmpassword) {
      errors.confirmPasswordError = "registration029";
      totalError++;
    }

    // errorCount = totalError;
    return errors;
  }

  // // console.log("passwordFormValues : ",passwordFormValues)
  // // console.log("emailFormValues : ",emailFormValues)
  // // console.log("phoneFormValues : ", phoneFormValues)

  const languageHandler = (e) => {
    // console.log("languageHandler : ", e.target.value);
    var index = e.nativeEvent.target.selectedIndex;
    if (e.nativeEvent.target[index].text == "Select Language") {

      setSelectStyle("form-select form-dropdown input-bg select-change-placeholder")
    } else {

      setSelectStyle("form-select form-dropdown input-bg select-change-value")
    }
    setLanguageVal(e.target.value)
    setLangSaveBtn(false);
  }
  const setLanguage = (languageId) => {
    switch (languageId) {
      case 1:
        return 'English';
      case 2:
        return 'Hindi';
      case 3:
        return 'Tamil';
      case 4:
        return 'Bengali';
      case 5:
        return 'Telugu';
      case 6:
        return 'Odiya';
      default:
        return 'English';
    }
  }
  const languageSave = async () => {
    // console.log("languageSave : ", languageVal);
    setLangSaveBtn(true);
    // // console.log(`uploadFiles called :: ${userId} :: ${languageVal}`)
    const body = {
      UserId: userId,
      LanguageId: languageVal
    }
    let langName = setLanguage(parseInt(languageVal));
    // console.log("langName : ", langName);
    let resAfterLanguageUpdate = await axios.post(variables.API_URL + "api/UpdatePreferredLanguage", body)
    // // console.log("resAfterLanguageUpdate : ", resAfterLanguageUpdate.data.isSuccess);
    if (resAfterLanguageUpdate.data.isSuccess) {
      // // console.log("language updated succesfully");
      setTitlePrompt("UPDATED");
      setMessagePrompt(`<h5>${resAfterLanguageUpdate.data.returnMessage}</h5>`)
      setIsPopUpShow(true);
      setLanguageVal("");
      cookies.set("language", langName);
      sessionStorage.setItem("prefferedLanguage", langName)
      gettingUserProfile()
    } else {
      // // console.log("language something went wrong");
    }
  }


  const PhonenoKeyUp = (e) => {
    var filter = /^\d*(?:\.\d{1,2})?$/;
    if (!filter.test(e.target.value)) { e.target.value = "" }
  };

  const sendPhoneOtp = async (e) => {
    e.preventDefault();
    // // console.log("sendPhoneOtp : ", phoneFormValues);
    const phoneErrorState = await validatePhoneUpdate(phoneFormValues)
    // // console.log("phoneErrorState : ", phoneErrorState);
    setPhoneFormErrors(phoneErrorState);
    if (Object.keys(phoneErrorState).length > 0) {
      // // console.log("solve errors of phoneErrorState")
    } else {
      // // console.log("going to call send phone otp api call");
      const body = {
        UserName: phoneFormValues.phonenumber,
        UserId: userId,
      }
      const resAfterCheckingIsNoExist = await axios.post(variables.API_URL + "api/CheckUserNameExists", body);
      // // console.log("resAfterCheckingIsNoExist : ", resAfterCheckingIsNoExist);
      if (resAfterCheckingIsNoExist.data.isSuccess) {
        // // console.log("calling send phone otp api");
        const bodyForOtp = {
          ContactNo: "",
          TPLogo: "",
          email: "",
          MobileNo: phoneFormValues.phonenumber
        }
        const resAfterRecievingOtpOnMobile = await axios.post(variables.API_URL + "api/SendOTP", bodyForOtp);
        // // console.log("resAfterRecievingOtpOnMobile : ", resAfterRecievingOtpOnMobile);
        if (resAfterRecievingOtpOnMobile.data.isSuccess) {
          setPhoneFormValues({ ...phoneFormValues, otpRecived: resAfterRecievingOtpOnMobile.data.data.otp })
        } else {
          // // console.log("error in recieving otp on mobile");
        }
      } else {
        // console.log("number is not present in db")
        const errors = {};
        errors.phonenumber = "numberAlreadyInUse"
        // console.log("emailErrorState in invalid username condition : ", errors);
        setPhoneFormErrors(errors);
      }
    }
  }

  const validatePhoneUpdate = (values) => {
    const errors = {};
    var phoneReg = /^(\+91|91|0)?[6-9]\d{9}$/;
    var totalError = 0;
    if (!values.phonenumber) {
      errors.phonenumber = "registration005";//"Phone number is required";
      totalError++;
    }
    // if (!phoneReg.test(values.phonenumber)) {
    //   errors.phonenumber = "profilePhoneValid";
    //   totalError++;
    // }
    // if (values.phonenumber.length < 10) {
    //   errors.phonenumber = "profilePhonelength";
    //   totalError++;
    // }
    return errors;
  }

  const sendEmailOtp = async (e) => {
    e.preventDefault();
    // // console.log("sendEmailOtp : ", emailFormValues);
    const emailErrorState = await validateEmailUpdate(emailFormValues)
    // // console.log("emailErrorState : ", emailErrorState);
    setEmailFormErrors(emailErrorState);
    if (Object.keys(emailErrorState).length > 0) {
      // // console.log("solve errors of emailErrorState")
    } else {
      // console.log("going to call send email otp api call");
      const body = {
        UserName: emailFormValues.emailid,
        UserId: userId,
      }
      const resAfterCheckingIsEmailExist = await axios.post(variables.API_URL + "api/CheckUserNameExists", body);
      // // console.log("resAfterCheckingIsEmailExist : ", resAfterCheckingIsEmailExist);
      if (resAfterCheckingIsEmailExist.data.isSuccess) {
        // console.log("calling send email otp api");
        const bodyForOtp = {
          ContactNo: "",
          TPLogo: "",
          email: emailFormValues.emailid,
          MobileNo: ""
        }
        const resAfterRecievingOtpOnEmail = await axios.post(variables.API_URL + "api/SendOTP", bodyForOtp);
        // // console.log("resAfterRecievingOtpOnEmail : ", resAfterRecievingOtpOnEmail);
        if (resAfterRecievingOtpOnEmail.data.isSuccess) {
          setEmailFormValues({ ...emailFormValues, emailOtpRecived: resAfterRecievingOtpOnEmail.data.data.otp })
        } else {
          // console.log("error in recieving otp on email");
        }
      } else {
        // console.log("email is not present in db")
        const errors = {};
        errors.emailid = "emailAlreadyInUse"
        // console.log("emailErrorState in invalid username condition : ", errors);
        setEmailFormErrors(errors);
      }
    }
  }

  const validateEmailUpdate = (values) => {
    // // console.log("validateEmailUpdate called", values);
    const errors = {};
    const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!values.emailid) {
      errors.emailid = "registration007";
    }
    if ((!emailReg.test(values.emailid))) {
      errors.emailid = "registration012";
    }
    return errors;
  }

  const validateContactOtp = (callingFrom) => {
    // // console.log("validateContactOtp : ", callingFrom);
    const errors = {};
    if (callingFrom == "mb") {
      // // console.log("phoneFormValues in validateContactOtp", phoneFormValues)
      // if (phoneFormValues.otp !== phoneFormValues.otpRecived) {
      //   errors.otp = "wrongOtpEntered";
      // }
    } else if (callingFrom == "em") {
      if (emailFormValues.emailotp !== emailFormValues.emailOtpRecived) {
        errors.emailotp = "wrongOtpEntered";
      }
    }
    return errors
  }

  const updateContact = async (e, callingFrom) => {
    e.preventDefault();
    // console.log("updateContact : ", callingFrom);
    const otpErrorState = await validateContactOtp(callingFrom)
    // console.log("otpErrorState : ", otpErrorState);
    if (callingFrom == "mb") {
      setPhoneFormErrors(otpErrorState);
    } else if (callingFrom == "em") {
      setEmailFormErrors(otpErrorState);
    }
    if (Object.keys(otpErrorState).length > 0) {
      // console.log("solve errors of otpErrorState")
    } else {
      let bodyForContact;
      let url;
      if (callingFrom == "mb") {
        
        const body = {
          UserName: phoneFormValues.phonenumber,
          UserId: userId,
        }
        const resAfterCheckingIsNoExist = await axios.post(variables.API_URL + "api/CheckUserNameExists", body);
        
        if (resAfterCheckingIsNoExist.data.isSuccess) {
          bodyForContact = {
            UserId: userId,
            Mobile: phoneFormValues.phonenumber,
            IsMobileVerified: 1
          }
          url = variables.API_URL + "api/UpdateMobile"
        } else {
          // console.log("number is not present in db")
          const errors = {};
          errors.phonenumber = "numberAlreadyInUse"
          // console.log("emailErrorState in invalid username condition : ", errors);
          setPhoneFormErrors(errors);
          return
        }        
      } else if (callingFrom == "em") {
        bodyForContact = {
          UserId: userId,
          EmailId: emailFormValues.emailid,
          IsEmailVerified: "1"
        }
        url = variables.API_URL + "api/UpdateEmail"
      }
      // // console.log("bodyForContact : ", bodyForContact)
      // // console.log("url : ", url);
      const resAfterUpdateContact = await axios.post(url, bodyForContact);
      // console.log("resAfterUpdateContact : ", resAfterUpdateContact);
      if (resAfterUpdateContact.data.isSuccess) {
        // console.log("profile updated success : ", resAfterUpdateContact.data.returnMessage);
        setTitlePrompt("UPDATED");
        setMessagePrompt(`<h5>${resAfterUpdateContact.data.returnMessage}</h5>`)
        gettingUserProfile()
        setIsPopUpShow(true);
        if (callingFrom == "mb") {
          setPhoneFormValues({ ...phoneFormValues, otpRecived: "", otp: "", phonenumber: "" })
        } else if (callingFrom == "em") {
          setEmailFormValues({ ...emailFormValues, emailOtpRecived: "", emailid: "", emailotp: "" })
        }
      } else {
        // console.log("somwthing went wrong while updating mobile no : ", resAfterUpdateContact.data.returnMessage)
      }
    }
  }

  // const oldPassHandler = (e) => {
  //   // console.log("oldPassHandler : ", e.target.value)
  //   setOldPass(e.target.value);
  // }

  // const confirmPassHandler = (e) => {
  //   // console.log("confirmPassHandler : ", e.target.value);
  //   setConfirmPass(e.target.value);
  // }
  // // console.log("disabled : ", disabled)

  // // console.log("phoneFormValues.otpRecived : ", phoneFormValues.otpRecived);
  // console.log("userProfileData : ", userProfileData?.languageId)
  return (
    <>
      {isPopUpShow &&
        <TestPopup show={true} txt={text == "2" ? prompt2 : messagePrompt} title={titlePrompt} onOk={(e) => setIsPopUpShow(false)} onClose={(e) => setIsPopUpShow(false)} type="success" />}

      <div className="col-lg-9 col-sm-12 col-xs-12 content-cards ">
        <div className="profile-heading primary-radius">
          <SessionHeading name={isShowSettings} type="profile" iconType={isShowSettings} />

          <div class={isShowSettings == "Organisation" ? "col-md-12 p-3 pt-0 profile-details pb-4" : "col-md-12 p-4 profile-details pb-4"}>
            {isShowSettings == "Update Contact" ?
              <>
                <div class="personal-details">
                  <h4><Translate textKey="registration004" /></h4>
                  {/* phonenumber */}
                  <form>
                    <div class="parent-form">
                      <div class="col-md-6 col-sm-12 mt-0">
                        <TextField type="text"
                          onchange={(e) => handleChange(e, "phoneUpdate")} name="phonenumber"
                          maxlength="10"
                          value={phoneFormValues.phonenumber}
                          classNm="change-one"
                          textKey="registration004"
                          errorKey={phoneFormErrors.phonenumber}
                          infoKey=""
                          keyup={PhonenoKeyUp}
                          placeholderkey="registration005"
                        />

                        {/* <input name="username" type="text" placeholder="Enter Phone Number" autocomplete="off" class="form-inputOne input-bg" value=""></input>
                      <label>Phone Number<img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                      </label> */}
                      </div>

                      <div>
                        <button onClick={(e) => updateContact(e, "mb")} disabled={phoneFormValues.phonenumber.length > 9 ? false : true} class={phoneFormValues.phonenumber.length > 9 ? "primary-btn mt-3" : "disabled-btn mt-3"} >Submit</button>
                      </div>

                      {/* <div class="mt-3 d-flex">
                        <button onClick={sendPhoneOtp} disabled={phoneFormValues.phonenumber.length < 10 ? true : false} class={phoneFormValues.phonenumber.length < 10 ? "disabled-btn" : "primary-btn"}>Send OTP</button>
                        <p class="instruction-content h7 pt-3 ms-2 mb-0">
                          <Translate textKey="userprofile004" />
                        </p>
                      </div> */}
                    </div>
                  </form>
                  <form>
                    <div class="parent-form">
                      {/* <div class="col-md-6 col-sm-12 mt-0">
                        <TextField type="text"
                          onchange={(e) => handleChange(e, "phoneUpdate")} name="otp"
                          value={phoneFormValues.otp}
                          classNm={phoneFormValues.otpRecived ? "change-one" : "disable-state"}
                          textKey="registration017"
                          errorKey={phoneFormErrors.otp}
                          infoKey=""
                          maxlength="4"
                          disable={phoneFormValues.otpRecived ? false : true}
                          placeholderkey="registration018"
                        />
                        <div class="d-flex">
                          <div>
                            <img src="../../Assets/Linqskills_Icons/info-blue.svg" class="ms-1"></img>
                          </div>
                          <div>
                            <p class="instruction-content h7 pt-2 mb-0">
                              Enter the verificatsion code sent via SMS
                            </p>                          </div>
                        </div>
                      </div> */}

                      
                      {/* {phoneFormValues.otpRecived ?
                        <button >{phoneFormValues.otpRecived}</button> : ""} */}
                    </div>

                  </form>


                </div>
                <div class="col-md-12 personal-details pt-3">
                  {/* Email */}
                  <h4><Translate textKey="registration006" /></h4>

                  <form>
                    <div class="parent-form">
                      <div class="col-md-6 col-sm-12">
                        <TextField type="text"
                          onchange={(e) => handleChange(e, "emailUpdate")} name="emailid"
                          value={emailFormValues.emailid}
                          classNm="change-one"
                          textKey="registration006"
                          errorKey={emailFormErrors.emailid}
                          infoKey=""
                          placeholderkey="registration007"
                        />
                      </div>
                      <button onClick={sendEmailOtp} disabled={emailFormValues.emailid.length == 0 ? true : false} className={emailFormValues.emailid.length == 0 ? "disabled-btn mt-3" : "primary-btn mt-3"} >Send OTP</button>
                    </div>
                  </form>

                  <form>
                    <div class="parent-form">
                      <div class="col-md-6 col-sm-12">
                        <TextField type="text"
                          onchange={(e) => handleChange(e, "emailUpdate")} name="emailotp"
                          value={emailFormValues.emailotp}
                          classNm={emailFormValues.emailOtpRecived ? "change-one" : "disable-state"}
                          textKey="registration054"
                          callingFrom="profile"
                          errorKey={emailFormErrors.emailotp}
                          disable={emailFormValues.emailOtpRecived ? false : true}
                          infoKey=""
                          maxlength="4"
                          placeholderkey="registration018"
                        />

                        <div class="d-flex">
                          <div>
                            <img src="../../Assets/Linqskills_Icons/info-blue.svg" class="ms-1"></img>
                          </div>
                          <div>
                            <p class="instruction-content h7 pt-2 mb-0">Enter the verification code sent via SMS
                            </p>                          </div>
                        </div>
                      </div>
                      <button onClick={(e) => updateContact(e, "em")} disabled={emailFormValues.emailotp.length > 3 ? false : true} class={emailFormValues.emailotp.length > 3 ? "primary-btn mt-3" : "disabled-btn mt-3"} >Verify & Update</button>

                    </div>
                  </form>




                </div>
              </> : isShowSettings == "User Preferences" ?
                <>
                  <h4><Translate textKey="userprofile012" /></h4>
                  {/* <h4>Medium of Instructions</h4> */}
                  <p class="instruction-content h7 pt-2 ms-2 mb-0">
                    <Translate textKey="userprofile013" />
                    {/* Set this option as my default language for viewing course content on LinQ LMS */}
                  </p>
                  <form class="border-bottom pb-3">
                    <div class="parent-form">
                      <div class="labelWrap col-md-6 col-sm-12">
                        <label class="dropdown-label trn">
                          <Translate textKey="Login014" />
                          {/* Select Language */}
                          <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                        </label>
                        <img src="../../Assets/Linqskills_Icons/arrow_down_ios_24px.svg" class="dropdown-arrow"></img>
                        <select name="countryid" value={languageVal} class={selectStyle} onChange={languageHandler} aria-label="Default select example">
                          <option value="" disabled selected >Select Language</option>
                          {mediumInstructionLanguages.map((item) => {
                            // // console.log("item in lan : ", item);
                            return (
                              <>
                                <option value={item.languageId} >{item.languageName}</option>
                              </>
                            )
                          })}

                        </select>
                      </div>
                    </div>
                  </form>
                  {/* Dropdown btn */}
                  {/*      
<div class="dropdown-btn col-md-4">


<div class="select-dropdown">
<img src="../../Assets/Linqskills_Icons/arrowdown-white.svg" class="float-end arrow-down"></img>
	<select>


		<option value="Option 1" data-title="../../Assets/Linqskills_Icons/video24_white.svg">
      First Option</option>
		<option value="Option 2">2nd Option</option>
		<option value="Option 3">Option Number 3</option>
	</select>
</div>
</div> */}
                  {/* Dropdown btn */}
            
                  <div class="col-md-12 text-center p-3 pb-0">
           
                    <button onClick={languageSave} disabled={langSaveBtn} class={langSaveBtn ? "disabled-btn" : "primary-btn"}>
                      <Translate textKey="userprofile008" />
                    </button>
                  </div>
                </> : isShowSettings == "Edit Password" ?
                  <>
               
                    <div class="col-md-12 personal-details">

                      <h4 class="mb-0"><Translate textKey="Login004" /></h4>
                      <div class="border-bottom pb-3 mb-3">
                        <form>
                          <div class="parent-form">
                            <div class="labelWrap col-md-6 col-sm-12">
                              <TextField type="text"
                                onchange={(e) => handleChange(e, "passUpdate")} name="oldPassword"
                                value={passwordFormValues.oldPassword}
                                textKey="ProfileLabel001"
                                errorKey={passwordFormErrors.oldPasswordError}
                                infoKey=""
                                placeholderkey="Profile001"
                                classNm="change-one"
                              />
                              {/* <input name="username"  type="text" placeholder="Enter Old Password" autocomplete="off" class="form-inputOne input-bg" value=""></input>
                            <label>Old Password<img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                            </label> */}
                            </div>
                          </div>
                        </form>
                        <form>
                          <div class="parent-form col-md-12">


                            <div class="labelWrap col-md-6 col-sm-12">
                              <TextField type="text"
                                onchange={(e) => handleChange(e, "passUpdate")} name="password"
                                value={passwordFormValues.password}
                                classNm="change-one"
                                textKey="ProfileLabel002"
                                errorKey={passwordFormErrors.passwordError}
                                infoKey=""
                                placeholderkey="Profile002"
                              />
                              {
                                passwordFormErrors?.passwordError ?
                                  "" : <div class="d-flex">
                                    <div>
                                      <img src="../../Assets/Linqskills_Icons/info-blue.svg" class="ms-1"></img>
                                    </div>
                                    <div>
                                      <p class="h7 pt-2"><Translate textKey="registration030" />
                                      </p>                          </div>
                                  </div>
                              }
                            </div>
                            <div class="labelWrap col-md-6 col-sm-12">

                              <TextField type="text"
                                onchange={(e) => handleChange(e, "passUpdate")} name="confirmpassword"
                                value={passwordFormValues.confirmpassword}
                                classNm="change-one"
                                textKey="ProfileLabel003"
                                errorKey={passwordFormErrors.confirmPasswordError}
                                infoKey=""
                                placeholderkey="Profile003"
                              />
                            </div>
                          </div>


                          {/* <input name="username" onChange={newPassHandler} type="text" placeholder="Enter New Password" autocomplete="off" class="form-inputOne input-bg" value={newPass}></input>
                            <label>New Password<img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                            </label>
                            <div class="d-flex">
                              <div>
                                <img src="../../Assets/Linqskills_Icons/info-blue.svg" class="ms-1"></img>
                              </div>
                              <div>
                                <p class="h7 pt-2">The password should contain atleast one uppercase, lowercase & number.
                                </p>                          </div>
                            </div> */}

                          <div class="labelWrap col-md-6 col-sm-12">
                            {/* <input name="username" onChange={confirmPassHandler} type="text" placeholder="Confirm Password" autocomplete="off" class="form-inputOne input-bg" value={confirmPass}></input>
                            <label>Re enter Password<img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                            </label> */}
                          </div>
                        </form>
                      </div>
                      <div class="col-md-12 text-center p-3 pb-0 pt-0">
                        {/* class={`disabled-btn ${disabled === true && disabledClass}`} */}
                        <button onClick={passwordUpdate} disabled={disabled} className={disabled ? "disabled-btn" : "primary-btn"}  ><Translate textKey="userprofile008" /></button>
                      </div>
                    </div>
                  </> : isShowSettings == "Organisation" ?
                    <>
                      <div class="col-md-12 personal-details">
                        <WelcomeMenu isShowSettings={isShowSettings} isProfile={true}></WelcomeMenu>
                      </div>
                    </> : ""
            }
          </div>

        </div>

      </div>





    </>


  )

}







