import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { variables } from "../Shared/Variables";
import EGADetails from "../EligibilityCheck/EGADetails";
import TrainerMessage from "../CourseIndex/TrainerMessage";
import { updateChild, updateParent } from "../../features/breadcrumb/breadcrumbSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import SkeletonCard from "../Shared/SkeletonCard";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustumLoader from "./CustumLoader";

export default function Accordion(props) {
  const [openTest, setOpenTest] = useState(false);

  const dispatch = useDispatch();



  const getTestData = (data) => {
    sessionStorage.setItem("TestData", null);
    sessionStorage.setItem("TestData", JSON.stringify(data));

    sessionStorage.setItem("courseName", null);
    sessionStorage.setItem("courseId", null);
    sessionStorage.setItem("courseNodeId", null);
    sessionStorage.setItem("lessionPlanId", null);
    sessionStorage.setItem("courseName", props.courseName);
    sessionStorage.setItem("courseId", props.courseId);
    sessionStorage.setItem("courseNodeId", props.courseNodeId);
    sessionStorage.setItem("lessionPlanId", props.lessionplanid);
    dispatch(updateChild(data.testName))
    dispatch(updateParent('Eligibility Check'))
    setOpenTest(true);
  };
  if (openTest) {
    return <Navigate to="/EGADetails" />;
  } else {

    // console.log("props.data :: ", props.data)
    return (


      <div >
      {/*   <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props.isLoader}

        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <div className=" accordion " id="accordionExample">
          <div
            className="accordion mb-4 p-0"
            id="accordionExample"

          >
            <div class="accordion-item box-shadow ">
              <div class="accordion-header topic-header s" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {/*<p class="number-badge mb-0">{props.courseId}</p> <h5 class="m-0">{props.courseName}</h5>*/}
                  {/* <p class="number-badge mb-0">1</p>{" "}
                  
                  */}
                  <div class="pink-box me-2"><img src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png" class="me-1"></img></div>
                  <h5 class="m-0 text-truncate">Eligibilty Check</h5>
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body border-top">
                  {props.data.map((item) => {
                    // // console.log("item : aa : ",item)s
                    return (
                      <div class="row active pb-3">

                        <div class="col-md-12 col-lg-12 d-flex">
                          <div class="col-lg-1 left-contentIcon">


                            {item.testStatus === "C" && (
                              <img
                                src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg"
                                class="pe-2"
                              ></img>
                            )}
                            {item.testStatus === "A" && (
                              <img
                                src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg"
                                class="pe-2"
                              ></img>
                            )}
                            {item.testStatus === "EU" || item.testStatus === "E" && (
                              <img
                                src="../../Assets/Linqskills_Icons/sessionstatus-submitted.svg"
                                class="pe-2"
                              ></img>
                            )}
                            {(item.testStatus === "D" || item.testStatus === "I") && (
                              <img
                                src="../../Assets/Linqskills_Icons/sessionstatus-open.svg"
                                class="pe-2"
                              ></img>
                            )}



                          </div>

                          <div
                            /* onClick={() => getTestData(item)} */
                            class="col-lg-11 col-sm-10 ps-2 mt-6p"
                          >
                            <h5 class="bold-500 mb-0 ">{item.testName}</h5>
                            <div class="col-lg-12 session-details">
                              {(item.testStatus === "C" ||
                                item.testStatus === "EU" || item.testStatus === "E") && (
                                  <div class="me-2 d-flex">
                                    <div>
                                      <img
                                        src="../../Assets/Linqskills_Icons/assignment-multicolorgreen.svg"
                                        class=""
                                      ></img>
                                    </div>
                                    <div>
                                      <span class="h7 align-self-center ms-1">
                                        {item.testStatus === "C" && "Completed"}
                                        {(item.testStatus === "EU" || item.testStatus === "E") && "Submitted"}
                                      </span>{" "}
                                    </div>
                                  </div>
                                )}
                              {(item.testStatus === "A" ||
                                item.testStatus === "D" ||
                                item.testStatus === "I") && (
                                  <div class="me-2 d-flex">
                                    <div>
                                      <img
                                        src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg"
                                        class=""
                                      ></img>
                                    </div>
                                    <div>
                                      <span class="h7 align-self-center ms-1">
                                        {item.duration / 60} mins
                                      </span>
                                    </div>
                                  </div>
                                )}
                              {(item.testStatus === "A" ||
                                item.testStatus === "D" ||
                                item.testStatus === "I") && (
                                  <div class="me-2 d-flex">
                                    <div>
                                      <img
                                        src="../../Assets/Linqskills_Icons/marks-multicolorgreen.svg"
                                        class=""
                                      ></img>
                                    </div>
                                    <div>
                                      <span class="h7 align-self-center ms-1">
                                        {item.totalMarks/* ?item.totalMarks : '25' */} marks
                                      </span>
                                    </div>
                                  </div>
                                )}
                              {(item.testStatus === "E" ||
                                item.testStatus === "C" /* ||
                                item.testStatus === "D" */) && (
                                  <div class="me-2 d-flex">
                                    <div>
                                      <img
                                        src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg"
                                        class=""
                                      ></img>
                                    </div>
                                    <div>
                                      <span class="h7 align-self-center ms-1">
                                        {(item.testStatus === "C" || item.testStatus === "E")
                                          &&

                                          `${moment(item.completedOn).format("DD MMM YYYY")}  |  ${moment(item.completedOn).format("hh:mm A")}`

                                        }
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>

                          <div class="col flex-shrink-1">
                            {item.testStatus != "A" && (
                              <Link to="">    <img
                                onClick={() => getTestData(item)}
                                src="../../Assets/Linqskills_Icons/forward-actionblue.svg"
                                class="float-end cursor-pointer"
                              ></img></Link>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/*<div class="accordion-item box-shadow mb-4">*/}
          {/*    <div class="accordion-header topic-header" id="headingThree">*/}
          {/*        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">*/}
          {/*            <h5 class="m-0">Accordion Item #3</h5>*/}
          {/*        </button>*/}
          {/*    </div>*/}
          {/*    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">*/}
          {/*        <div class="accordion-body border-top">*/}
          {/*            <div class="row pb-3">*/}
          {/*                <div class="col-md-12 col-lg-12 d-flex">*/}
          {/*                    <div class="col-lg-1 left-contentIcon">*/}
          {/*                        <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg" class="pe-2"></img>*/}

          {/*                    </div>*/}
          {/*                    <div class="col-lg-11 col-sm-10">*/}

          {/*                        <h5 class="bold-500 mb-0 mt-6p">Listening & Reading</h5>*/}
          {/*                        <div class="col-lg-12 session-details">*/}
          {/*                            <div class="me-2 d-flex">*/}
          {/*                                <div>*/}
          {/*                                    <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class=""></img>*/}

          {/*                                </div>*/}
          {/*                                <div>*/}
          {/*                                    <span class="h7 align-self-center ms-1">*/}
          {/*                                        30 mins</span>*/}
          {/*                                </div>*/}

          {/*                            </div>*/}

          {/*                            <div class="me-2 d-flex">*/}
          {/*                                <div>*/}
          {/*                                    <img src="../../Assets/Linqskills_Icons/marks-multicolorgreen.svg" class=""></img>*/}

          {/*                                </div>*/}
          {/*                                <div>*/}
          {/*                                    <span class="h7 align-self-center ms-1">*/}
          {/*                                        45 marks</span>*/}
          {/*                                </div>*/}

          {/*                            </div>*/}

          {/*                            <div class="me-2 d-flex">*/}
          {/*                                <div>*/}
          {/*                                    <img src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg" class=""></img>*/}

          {/*                                </div>*/}
          {/*                                <div>*/}
          {/*                                    <span class="h7 align-self-center ms-1">*/}
          {/*                                        02 Apr 2023  |  2:30 PM   to   03 Apr 2023   |  2:30 PM</span>*/}
          {/*                                </div>*/}

          {/*                            </div>*/}

          {/*                        </div>*/}
          {/*                    </div>*/}
          {/*                    <div class="col flex-shrink-1">*/}
          {/*                        <img src="../../Assets/Linqskills_Icons/forward-actionblue.svg" class="float-end"></img>*/}

          {/*                    </div>*/}
          {/*                </div>*/}
          {/*            </div>                            </div>*/}
          {/*    </div>*/}
          {/*</div>*/}
        </div>
      </div>

    );
  }
}
