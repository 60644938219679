export const data = {
  states: {
    "Ahmadabad": ['Bavla', 'Daskroi','Dhandhuka','Dholera','Dholka'],
    "Amreli": ['Amreli', 'Babra', 'Bagasara','Dhari', 'Jafrabad', 'Khambha'],
    "Anand": ['Anand', 'Anklav', 'Borsad','Khambhat','Petlad','Sojitra'],
  },
  blocks: {
    "Bavla": ['Adroda', 'Amipura', 'Bagodara'],
    "Daskroi": ['Aslali', 'Badodara', 'Bakrol Bujrang'],
    "Dhandhuka": ['Adval', 'Akru', 'Aniyali Bhimji'],
    "Dholera": ['Ambli', 'Anandpur', 'Bavliyari'],
    "Dholka": ['Ambaliyara', 'Ambareli', 'Ambethi'],
    "Amreli": ['Amarpur', 'Ankadiya Mota', 'Babapur'],
    "Babra": ['Amarvalpar', 'Amrapara', 'Balel Pipariya'],
    "Dhari": ['Amaratpur-Dabhali', 'Ambardi-Bharad', 'Bhader'],
    "Anand": ['Adas', 'Ajarpura', 'Ajupura'],
    "Anklav": ['Ambali', 'Ambav', 'Amrol'],
    "Borsad":['Alarsa', 'Amiyad', 'Badalpur'],
  },
};