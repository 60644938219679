import {Link} from 'react-router-dom';
import React from "react";

import OnGoing from "./OnGoing";
import DropdownAdminbtn from './DropdownAdminbtn';

export default function AdminCourseCard() {

  return (
    <>
 <div class="col-lg-3 col-sm-12 col-xs-12 mb-3 ps-2 pe-2">
<div class="row">
    <div class="mb-3 ps-2 pe-2">
   
  
        <div class="card-modify">


{/* This batch details component will be visible when image is not there */}
          {/* <div class="admin-batchdetails p-3">
            <h6>12 July 2023 - 20 Nov 2023</h6>
            <h4 class="line-clamp-two mb-1">Batch 9002932</h4>
            <h6>English for Employability Level 2</h6>
          </div> */}

          
          <img src="../../Assets/Linqskills_Icons/admin/admin-coursecard.svg" class="card-img bg-tint"></img>
            <div class="card-body cardbody-height">
              <OnGoing/>
            <h5 class="card-title line-clamp-two mb-0">Chemistry Proficiency Test</h5>
              <div class="admin-details pt-3 pb-3">
                <div class="batches mb-2 d-flex justify-content-between">
                    <div>
                    <img src="../../Assets/Linqskills_Icons/admin/batches-grey.svg" class="me-1"></img>
                    <span>122</span>
                    </div>
                    <div class="">
                    <h6 class="mt-1 mb-0">Batches</h6> 
                    </div>
                </div>
                <div class="batches mb-2 d-flex justify-content-between">
                    <div>
                    <img src="../../Assets/Linqskills_Icons/admin/candidate-grey.svg" class="me-1"></img>
                    <span>12,22,333</span>
                    </div>
                    <div class="">
                    <h6 class="mt-1 mb-0">Registered</h6> 
                    </div>
                </div>
              </div>

<DropdownAdminbtn/>
            </div>
        </div>

    
    </div>

</div>
</div>
</>


)

}
