import React from 'react';
//import Message from './components/message';
import NavOne from '../NavOne';
import AdminCourseCard from './AdminCourseCard';
import Footer from '../Shared/Footer';
import DeliveryModel from './DeliveryModel';
import SearchPanel from './SearchPanel';
import DropdownAdminbtn from './DropdownAdminbtn';
import AdminNavbarTwo from './AdminNavbarTwo';
import MobileMenu from './MobileMenu';



export default function AdminList() {

  return (
    <>
     
       <div className="p-0">
       
                      <NavOne></NavOne>
                  <MobileMenu/>
              <div class="container-fluid body-wrapper bg-default">
                <DeliveryModel/>
                
                
              <div class="container pt-3 pb-3 p-0">
   
                <AdminNavbarTwo/>
                <div class="d-flex mb-4 justify-content-md-between">
                <div class="col-md-9">
                <SearchPanel/>
                </div>
                <DropdownAdminbtn/>
                </div>
            <div class="d-flex">
            <AdminCourseCard/>
            </div>
       
                </div>
                </div>
                <courselist/>
          </div>
         <Footer/> 
          </>
      )
  
}


