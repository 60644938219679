import React, { useState } from "react";
import { variables } from '../Shared/Variables';
import moment from "moment";
// import LanguagePopup from "../Shared/LanguagePopup";

// import Translate from "../Shared/Translate";

import Cookies from "universal-cookie";

// import VideoPlayer from "../Shared/VideoPlayer";
// import ViewTutorialPopUp from "./viewContentPopUp";
import { Link, useLocation } from "react-router-dom";
import { updategrandChild,updateChild } from "../../features/breadcrumb/breadcrumbSlice";
import { useDispatch } from "react-redux";
import {TimerFormat} from "../generalFunctions/timeFormat";




const VcNotificationCard = (props) => {
  var timeZone = (variables.TimeZone)
  // // console.log("props.vcNotifications : ", props.vcNotifications);

  const dispatch = useDispatch();

  //   const cookies = new Cookies();

  //   const defaultLanguage = cookies.get("default-language");

  //   // console.log(props.pageName, "props");

  //   const [openVideo, setOpenVideo] = useState(false);

  //   //  const [openLanguage, setOpenLanguage] = useState(defaultLanguage?false:true);

  //   const [openLanguage, setOpenLanguage] = useState(false);

  //   var Lan = cookies.get("language");

  //   Lan = Lan != undefined ? Lan : "EN";




  //   let defsrc = `https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/${

  //     props.pageName

  //   }_${

  //     cookies.get("default-language") === "en" ||

  //     cookies.get("default-language") === "EN"

  //       ? "eng"

  //       : "hin"

  //   }.mp4`;

  //   let src = `https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/${

  //     props.pageName

  //   }_${

  //     cookies.get("language") === "en" || cookies.get("language") === "EN"

  //       ? "eng"

  //       : "hin"

  //   }.mp4`;

  // /* let defsrc = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
  // let src = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" */
  //   function closeVideo() {
  //     setOpenVideo(false);
  //     setOpenLanguage(false);
  //   }

  //   function okLanguage() {

  //     setOpenVideo(true);
  //     if( props.onLanguageChange){
  //       props.onLanguageChange()
  //     }


  //     setOpenLanguage(false);

  //   }


  const testing = () =>{
    const d = new Date();
    // console.log("d : ",d);
  }

  const updateBreadcrumbInNotificationCard = () => {
    dispatch(updateChild("Online Lectures"));
    dispatch(updategrandChild(props?.vcNotifications[0].topic));
  }
  
  // console.log("props?.vcNotifications[0]", props?.vcNotifications[0])
  return (
    <>
      <div class="trainer-primary-radius mb-4">
        <div class="trainer-message p-3">
          <div class="d-flex col-lg-9 p-0">

            <div class="">

              <img

                src="../../Assets/Linqskills_Icons/MessageIcon.svg"

                class="pe-2"

              />

            </div>

            <div class="ps-2">

              <h6 class="p-0">{props.cardTitle}</h6>
              <p onClick={testing} class="mt-1 mb-1 bold-500">{props?.vcNotifications[0]?.topic}</p>
            </div>
          </div>
          {/* onClick={()=>dispatch(updategrandChild(props?.vcNotifications[0].topic))} */}
          <div class="col-lg-3 align-self-center pt-2 text-right" onClick={updateBreadcrumbInNotificationCard}  >
            <Link to={props?.vcNotifications[0]?.nodeId ? `/VirtualSession/${props?.vcNotifications[0]?.nodeId}` : `/VirtualSession/1`} >
              <h6 class="view cursor-pointer">
                GO TO TOPIC
                <img src={props.iconVal} class="ps-2 cursor-pointer" />
              </h6>
            </Link>

          </div>
          {/* {cookies.get("default-language") ? (

            openVideo ? (
              <>
                <ViewTutorialPopUp show={openVideo} onHide={closeVideo} title={props.title} src={defsrc} showHeading={true} />
                <section class="modal overlay"></section>
              </>
            ) : (
              <></>
            )
          ) : openLanguage ? (
            <LanguagePopup
              show={openLanguage}
              closeEvent={closeVideo}
              okEvent={okLanguage}
              type="center"
              headerKey="registration002"
            />
          ) : (
            openVideo && (
              <>
                <ViewTutorialPopUp show={openVideo} title={props.title} onHide={closeVideo} src={src} showHeading={true} />
                <section class="modal overlay"></section>
              </>
            )
          )} */}
        </div>
        <div class="session-details ps-3 pe-3 pb-3 d-flex">
        <div class="d-flex me-2">
            <div>
              <img src="../../Assets/Linqskills_Icons/time-mutlicolorgreen.svg" class=""></img>
            </div>
            <div>
              <span class="h7 align-self-center ms-2">{TimerFormat(props?.vcNotifications[0]?.duration)}</span>
            </div>
          </div>
          <div class="d-flex me-2">
            <div class="ps-1">
              <img src="../../Assets/Linqskills_Icons/calendar-Multicolorgreen.svg" class="" alt=""></img>
            </div>
            <div>
              <span class="h7 align-self-center ms-2">
                {moment(props?.vcNotifications[0]?.sessionStart).format("DD MMM YYYY")} | {props?.vcNotifications[0]?.startTime}</span>
            </div>
          </div>
        </div>
        {/* <div class="session-details d-flex pt-2 ps-1 p-3">
          <div class="d-flex me-3">
            <div>
              <img src="../../Assets/Linqskills_Icons/time-grey.svg" class=""></img>
                </div><div><span class="h7 align-self-center ms-2">20 Min</span>
                </div>
                </div>
                </div> */}
        {/*    <div class="chat-forum mt-4 mb-4 p-0">
          <div class="left-chat mb-3 d-flex">
            <div>
              <img
                src="../../Assets/Linqskills_Icons/chat-profile.svg"
                class="pe-2"
              />
            </div>
            <div class="chat-text">
              <span>
                Hello! Finally found the time to write to you, I need your help
                in creating presentation on customer segmentation.
              </span>
            </div>
            <div class="chat-settings">
              <img src="../../Assets/Linqskills_Icons/dots.svg" class="" />
            </div>
          </div>
          <div class="right-chat mb-3 d-flex float-end">
            <div class="chat-settings">
              <img src="../../Assets/Linqskills_Icons/dots.svg" class="" />
            </div>
            <div class="chat-text radius-updated">
              <span>Hello! Finally found the time to write to you</span>
            </div>
            <div>
              <img
                src="../../Assets/Linqskills_Icons/chat-profile.svg"
                class="ps-2"
              />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};




export default VcNotificationCard;
