
import { useNavigate,useLocation } from "react-router";

import React, { useState,useEffect } from "react";
import { Redirect } from "react-router-dom";
import { variables } from '../Shared/Variables';

import Translate from '../Shared/Translate';
import Cookies from 'universal-cookie';
import {
    updateFilterVal,
    updateNodeVal,
    updateFilterRetained,
    updateIsBlockCourseIndexAPi,
    updateAccordianNo,
    updateIsContentNodeVal,
    callingDispose,
    updateVideo,
    updateIsDevice
  } from "../../features/CourseIndex/courseIndexSlice";
  import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

/*import PopupOne from '../Shared/PopupOne';*/

export default function PageError({ error, resetErrorBoundary }) {
    // console.log("error in page error : ",error);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        localStorage.clear();
        sessionStorage.clear();
        // alert("Complete Cache Cleared");
        return true
    };
    const goHome = async () => {
        dispatch(updateNodeVal([]));
        dispatch(callingDispose(false));
        dispatch(updateVideo(false));
        dispatch(updateIsDevice("finding"));
        const isCacheClear = await clearCacheData();
        if(isCacheClear){
            navigate('/');
        }
    }
    const location = useLocation(); // useLocation hook to get the current route
    const userid = sessionStorage.getItem("login-userId");
  
    const componentName = useSelector(state => state.pageError.componentName);

    // console.log("location: " + location.pathname)
    // console.log("componentName: " + componentName)
    // console.log("error: " + error)
    // console.log("userid: " + userid)

    
    useEffect(() => {
        const sendErrorLog = async () => {
            try {
                if (componentName) {
                    const response = await axios.post(variables.API_URL+'api/SaveErrorLog', {
                        RoleId: 0,
                        UserID: userid,
                        ErrorPath: location.pathname,
                        ErrorDescription: error.message,
                        Component: componentName
                    }, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    });
    
                    // console.log('Error logged successfully:', response.data);
                }
            } catch (error) {
                console.error('Error occurred while logging error:', error);
            }
        };
    
        sendErrorLog();
    }, [componentName, error, location.pathname, userid]);
    

    // const [someState, setSomeState] = useState('');

    // useEffect(() => {
    //     // Check if there is a value in sessionStorage
    //     const storedValue = sessionStorage.length;
    
    //     if (storedValue) {
    //       // Value exists, do something with it
    //       // console.log('Value in sessionStorage:', storedValue);
    //     } else {
    //       // Value doesn't exist
    //       // console.log('No value in sessionStorage');
    //     }
    // },[someState]);
    return (
        <>
            <div class="container-fluid page-error">
                <div class="col-md-8 m-auto row p-5">
                    <h4 class="text-center mb-3">Something went wrong.</h4>
                    <img src="../../Assets/Linqskills_Icons/page-error404.png" className="w-auto m-auto"></img>
                    <h5 class="text-center mt-3">Get in touch with your admin to get this resolved.</h5>
                    {/* <pre>{error.message}</pre> */}
                    {/* <button onClick={resetErrorBoundary} class="primary-btn">Try Again</button> */}
                    <button onClick={goHome} class="primary-btn">Go to Home</button>
                </div>

            </div>
        </>
    )

}


