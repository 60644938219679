import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Translate from "../../components/Shared/Translate";


export default function FooterRgsa() {

    return (

        <>
        <div class="container-fluid footer-rgsa pt-1">
          <div class="container">
                <div claas="col-6">
                  <span>Developed by <span class="bold-title">Schoolnet</span></span>
                </div>
                <div claas="col-6">
                  <span> <Translate textKey="Policy001" /> </span>
                </div>

          </div>
          </div>
      
                                {/* <div class="conatiner-fluid main-footer mobilefooter">
                                    <div class="container d-flex p-0">
                                    <div className="col-lg-6 col-sm-12 poweredby">
                                    <span>Powered by <span class="span-bold">LinQ Skills</span></span>
                                    <span class="ps-3 footertext">Developed by  <span class="span-bold">Schoolnet</span></span>
                                        </div>
                                        <div className="col-lg-6 text-right footerdetails display-desktop p-0">
                                        <span class="">All rights reserved. 2022</span>
                                        </div>
                                     </div>
 
                                    </div> */}
            
        </>
    )
}

                                  
{/* <div className="container d-flex login-footer mobilefooter">
                                   
<div className="col-6 col-sm-12 ps-3 poweredby">
    <span>f <span className="span-bold">
      sss</span></span>
    <span className="ps-3 footertext">
    sdsd <span className="span-bold">
        dsdsss</span></span>
</div>
<div className="col-6 text-right pe-3 footerdetails display-desktop">
    <span className="ps-3">
    sdsd</span>
</div>

</div> */}