
import Translate from '../Shared/Translate';
export default function Primarybtn(props) {
    var buttonClass = `text-uppercase continue-popup primary-btn w-100 `+props.disabledClass;
    var buttonClassWithoutHover = `text-uppercase continue-popup primary-btn-without-hover w-100 `+props.disabledClass;
    return (

            <button type={props.type} disabled={props.disabled} className={props.disabledClass?buttonClassWithoutHover:buttonClass} onClick={props.onClick}><Translate textKey={props.textKey} />
                <img src="../../Assets/Linqskills_Icons/arrow_forwardwhite.svg" className=""></img>
            </button>
    )
}
