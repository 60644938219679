import React, { useEffect, useState } from "react";

import LanguagePopup from "../Shared/LanguagePopup";

import Translate from "../Shared/Translate";

import Cookies from "universal-cookie";

import VideoPlayer from "../Shared/VideoPlayer";
import ViewTutorialPopUp from "./viewContentPopUp";





const TrainerMessage = (props) => {

  const cookies = new Cookies(); 
  
  // // console.log(props.pageName, "props");
  const [openVideo, setOpenVideo] = useState(false);

  //  const [openLanguage, setOpenLanguage] = useState(defaultLanguage?false:true);

  const [openLanguage, setOpenLanguage] = useState(false);

  var Lan = cookies.get("language");

  Lan = Lan != undefined ? Lan : "English";




  let defsrc = `https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/${

    props.pageName

  }_${

    (cookies.get("language") === "Hindi" 
    || cookies.get("language") === "HI" ) 

      ? "hin"

      : "eng"

  }.mp4`;

  let src = `https://linqskills.s3.ap-south-1.amazonaws.com/UAT/LH/Data/ImageGallery/18738/videos/${

    props.pageName

  }_${

    (cookies.get("language") === "Hindi" || cookies.get("language") === "HI")

      ? "hin"

      : "eng"

  }.mp4`;
 
/* let defsrc = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
let src = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" */
  function closeVideo() {
    setOpenVideo(false);
    setOpenLanguage(false);
  }

  function okLanguage() {

    setOpenVideo(true);
    setOpenLanguage(false);

  }

 /*  useEffect(()=>{
    setOpenLanguage(true);

  },[]) */


// // console.log("openVideo : ",openVideo)
  return (
    <>
      <div class="trainer-primary-radius mb-4">
        <div class="trainer-message p-3">
          <div class="d-flex col-lg-9 p-0">
            <div class="">
              <img src={props.messageType == "video" ? "../../Assets/Linqskills_Icons/Video-notification.svg" :"../../Assets/Linqskills_Icons/MessageIcon.svg"} class="pe-2" />
            </div>

            <div class="ps-2">

              {/* <h6 class="p-0">Message from trainer</h6> */}

              <p class="mt-1 mb-1 bold-500">

                {" "}

                {props.text}.{" "}

              </p>

            </div>

          </div>

          <div class="col-lg-3 align-self-center pt-2 text-right"
            onClick={
              cookies.get("language")
                ? () => {
                    setOpenVideo(true);
                  }
                : () => setOpenLanguage(true)
            }
          >
            <h6 class="view cursor-pointer">
              <img src="../../Assets/Linqskills_Icons/video.svg" class="ps-2 cursor-pointer" />
              VIEW TUTORIAL
            </h6>
          </div>
        
        {   openLanguage && <LanguagePopup
            show={openLanguage}
            closeEvent={closeVideo}
            okEvent={okLanguage}
            type="center"
            /* forRegVideo="true" */ headerKey="registration002"
          />
         } 
           

          {cookies.get("language") ? (

            openVideo ? (
              <>
                {/* <div class="row m-2">
                  <div class="popup-one text-lg-left" oncontextmenu="return false;" >
                    <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="mb-2 float-end close-icon"
                      onClick={closeVideo}
                    ></img>
                    <div class="mb-3">
                      <h6 class="p-0 m-0">
                        <Translate textKey="registration001" />
                      </h6>
                    </div> 
                    <VideoPlayer src={defsrc} />
                  </div>
                </div> */}
                <ViewTutorialPopUp show={openVideo} onHide={closeVideo} autoPlay={true} title={props.title} src={defsrc} showHeading={true} />
                <section class="modal overlay"></section>
              </>
            ) : (
              <></>
            )
          ) : openLanguage ? (
            <LanguagePopup
              show={openLanguage}
              closeEvent={closeVideo}
              okEvent={okLanguage}
              type="center"
              /* forRegVideo="true" */ headerKey="registration002"
            />
          ) : (
            openVideo && (
              <>
                {/* <div class="row m-2 centerInsideDiv">
                  <div class="popup-one text-lg-left" oncontextmenu="return false;" >
                    <img
                      src="../../Assets/Linqskills_Icons/popup-close.svg"
                      class="mb-2 float-end close-icon"
                      onClick={closeVideo}
                    ></img>
                    <div class="mb-3">
                      <h6 class="p-0 m-0">
                        <Translate textKey="registration001" />
                      </h6>
                    </div>
                    <VideoPlayer src={src} />
                  </div>
                </div> */}
                <ViewTutorialPopUp show={openVideo} title={props.title} autoPlay={true} onHide={closeVideo} src={src} showHeading={true} />
                <section class="modal overlay"></section>
              </>
            )
          )}
        </div>
     {/*    <div class="chat-forum mt-4 mb-4 p-0">
          <div class="left-chat mb-3 d-flex">
            <div>
              <img
                src="../../Assets/Linqskills_Icons/chat-profile.svg"
                class="pe-2"
              />
            </div>
            <div class="chat-text">
              <span>
                Hello! Finally found the time to write to you, I need your help
                in creating presentation on customer segmentation.
              </span>
            </div>
            <div class="chat-settings">
              <img src="../../Assets/Linqskills_Icons/dots.svg" class="" />
            </div>
          </div>
          <div class="right-chat mb-3 d-flex float-end">
            <div class="chat-settings">
              <img src="../../Assets/Linqskills_Icons/dots.svg" class="" />
            </div>
            <div class="chat-text radius-updated">
              <span>Hello! Finally found the time to write to you</span>
            </div>
            <div>
              <img
                src="../../Assets/Linqskills_Icons/chat-profile.svg"
                class="ps-2"
              />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};




export default TrainerMessage;
