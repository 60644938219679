import React from 'react';
//import Message from './components/message';


export default function LeftCard() {

  return (
    <>
      

      <div class="mb-3 col-lg-3 col-sm-12 col-xs-12">
<div class="bg-white box-shadow">
            <div class="">
  
              <img src="../../Assets/Linqskills_Icons/Admin/TDMCardIcon.svg" class=""></img>
            
    
           
            </div>
            <div class="coursecard-list ps-3 pb-3 ">
            <div class="d-flex pb-2 ps-2 cursor-pointer courselist-hover pt-2 me-3">
                  <img src="../../Assets/Linqskills_Icons/performance-Grey.svg" class="pe-2"></img>
                    <span class="cursor-pointer">Performance</span>
                  </div>
                <div class="d-flex pb-2 ps-2 cursor-pointer courselist-hover pt-2 me-3">
                  <img src="../../Assets/Linqskills_Icons/content-grey.svg" class="pe-2"
                  ></img>
                    <span class="cursor-pointer">Content</span>
                  </div>
                  <div class="d-flex pb-2 ps-2 cursor-pointer courselist-hover pt-2 me-3">
                  <img src="../../Assets/Linqskills_Icons/resource-grey.svg" class="pe-2"></img>
                    <span class="cursor-pointer">Resource Library</span>
                  </div>
                  <div class="d-flex pb-2 ps-2 cursor-pointer courselist-hover pt-2 me-3">
                  <img src="../../Assets/Linqskills_Icons/certificate-grey.svg" class="pe-2"></img>
                    <span class="cursor-pointer">Certificates</span>
                  </div>
           
            </div>
            </div>
      </div>
          </>
      )
  
}


