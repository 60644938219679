import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { variables } from "./Variables";

const PrivateRoute = ({ children }) => {

// // console.log("inside PrivateRoute")

  const cookies = new Cookies();

  const getSessionDetails = async () => {
    try {
      let url =
      variables.API_URL+"api/ISValidSession";
      const data = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: cookies.get("sessionId"),
          userId: sessionStorage.getItem("login-userId"),
        }),
      });

      const res = await data.json();
      // // console.log(res.strResult,"res")
      //// console.log(res.strResult,"auth")
     return  res.strResult;
    } catch (error) {
      // console.log(error);
    }
  };
  
  getSessionDetails().then((res)=>{
    if(res!="true")
    {
      return <Navigate to="/" />;
    }
  })
  return children
};

export default PrivateRoute;
