import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { variables } from "../Shared/Variables";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Breadcrumb from "../CourseIndex/Breadcrumb";
import { Bar, Radar } from "react-chartjs-2";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useDispatch } from "react-redux";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  plugins,
  scales,
  Ticks,
} from "chart.js";
import {
  updateChild,
  updateParent,
} from "../../features/breadcrumb/breadcrumbSlice";
import CompletedTest from "./CompletedTest";
import CustumLoader from "./CustumLoader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

export default function SummaryScore() {
  const [isLoader, setIsLoader] = useState(false);
  const [showMainDiv, setShowMainDiv] = useState(true);
  const [visibleQuestion, setVisibleQuestion] = useState(null);
  const [summaryScore, setSummaryScore] = useState([]);
  const [aptitudeSkills, setAptitude_skills] = useState([]);
  const [isScoreVisible, setIsScoreVisible] = useState(false);
  const [behavioralSkills, setBehavioralSkills] = useState([]);
  const [communicationSkills, setCommunicationSkills] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [writtenSkills, setWrittenSkills] = useState([]);

  const [error, setError] = useState(false);

  const toggleQuestionVisibility = (questionIndex) => {
    setVisibleQuestion(
      visibleQuestion === questionIndex ? null : questionIndex
    );
  };
  ChartJS.defaults.font.size = 12;

  const writing_skills_data = {
    labels: [
      "technical depth score",
      "grammatical score",
      "relevance score",
      "cohesiveness score",
    ],
    datasets: [
      {
        label: "Writing Skills",
        data: [5.2, 6.2, 5.9, 5.2],
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
    ],
  };

  const writing_skills_options = {
    type: "radar",
    data: writing_skills_data,
    options: {
      responsive: true,
      plugins: {
        title: {
          display: false,
          text: "Chart.js Radar Chart",
        },
      },
    },
  };

  const behavioral_skills_data = {
    labels: [
      "openness to feedback",
      "critical thinking",
      "achievement orientation",
      "adaptability",
      "attention to detail",
      "organizational awareness",
      "self - motivation",
      "time management and prioritization",
      "learnability",
      "collaboration skills",
    ],
    datasets: [
      {
        label: "Behavioural Skills",
        data: [5.4, 5.2, 5.2, 8.3, 8.2, 5.2, 5.7, 5.6, 5.5, 7.2],
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
    ],
  };

  const behavioral_skills_options = {
    type: "radar",
    data: behavioral_skills_data,
    responsive: true,
    scales: {
      r: {
        // Adjust the radial scale (r is for radar chart)
        min: 0,
        max: 10,
        ticks: {
          stepSize: 2, // Defines the spacing between each scale point
          count: 5, // Defines the number of scale points
          beginAtZero: true,
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  };
  const aptitude_skills = aptitudeSkills.map((item) => item.skill);
  const aptitude_scores = aptitudeSkills.map((item) => item.score);

  const aptitude_skills_data = {
    labels: aptitude_skills,
    datasets: [
      {
        label: "Aptitude Skills",
        data: aptitude_scores,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
    ],
  };

  const aptitude_skills_options = {
    type: "radar",
    data: aptitude_skills_data,
    options: {
      responsive: true,
    },
  };

  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };

  const labels = [
    "Aptitude Skills",
    "Behavioral Score",
    "Communication",
    "Technical Score",
  ];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [
          summaryScore.aptitude_skills_score,
          summaryScore.behavioral_score,
          summaryScore.communication_score,
          summaryScore.technical_skills_score,
        ],

        borderColor: "rgb(153, 102, 255)",
        backgroundColor: "rgb(54, 162, 235)",
        order: 1,
      },
      {
        label: "Dataset 2",
        data: [
          summaryScore.aptitude_skills_score,
          summaryScore.behavioral_score,
          summaryScore.communication_score,
          summaryScore.technical_skills_score,
        ],

        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(201, 203, 207)",
        type: "line",
        order: 0,
      },
    ],
  };

  const config = {
    type: "bar",
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: "Chart.js Combined Line/Bar Chart",
        },
      },
    },
    scales: {
      y: {
        grace: "5%",
      },
    },
  };
  const communication_skills_data = {
    labels: ["A", "B", "C", "D", "E", "F", "G"],
    datasets: [
      {
        data: [-4.2, 5.2, 5.2, 5.8, -7.2, 8.2, 8.2],
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgb(54, 162, 235)",
        pointBackgroundColor: "rgb(54, 162, 235)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(54, 162, 235)",
      },
    ],
  };
  const communication_skills_options = {
    responsive: true,
    scales: {
      r: {
        // Adjust the radial scale (r is for radar chart)
        /*  min: 0,
      max: 10, */
        ticks: {
          stepSize: 5, // Defines the spacing between each scale point
          count: 6, // Defines the number of scale points
          beginAtZero: true,
        },
        pointLabels: {
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const radar_data = {
    labels: [
      "technical depth score",
      "grammatical score",
      "relevance score",
      "cohesiveness score",
      "openness to feedback",
      "critical thinking",
      "achievement orientation",
      "adaptability",
      "attention to detail",
      "organizational awareness",
      "self - motivation",
      "time management and prioritization",
      "learnability",
      "collaboration skills",
      "positive mindset",
      "speaking with conviction",
      "conciseness",
      "presentation skills",
      "self-confidence",
      "voice, articulation and diction",
      "resilience",
    ],
    datasets: [
      {
        label: "Skills",
        data: [
          5.2, 6.2, 5.9, 5.2, 5.4, 5.2, 5.2, 8.3, 8.2, 5.2, 5.7, 5.6, 5.5, 7.2,
          4.2, 5.2, 5.2, 5.8, 7.2, 8.2, 8.2,
        ],
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const radar_options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Radar Chart of All Skills",
      },
    },
    scales: {
      r: {
        beginAtZero: true,
      },
    },
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/mycourses");
  };

  const coursedata = JSON.parse(sessionStorage.getItem("coursedata"));

  const getEgaResult = async () => {
    try {
      setIsLoader(true);
      // console.log("calling getEgaResult : ");
      const AUTH_TOKEN = await sessionStorage.getItem("accessToken");
      const assesmentResultId = await sessionStorage.getItem(
        "assesmentResultId"
      );

      const response = await axios.get(
        `https://rz16px4j4i.execute-api.us-east-1.amazonaws.com/Prod/read/assessment_result_summary?assessment_result_id=${assesmentResultId}`,
        {
          headers: {
            Authorization: AUTH_TOKEN,
          },
        }
      );
      const name = "rachel";
      if (
        response.data &&
        response.data.assessment_result_data /* && name ==="phoebe" */
      ) {
        /* console.log(
         "Response after fetching score :",
         response.data.assessment_result_data
       ); */
        setSummaryScore(response.data.assessment_result_data);
        setIsScoreVisible(true);
        if (response.data.assessment_result_data.aptitude_skills) {
          const aptitudeSkills =
            response.data.assessment_result_data.aptitude_skills;

          // Transform the object into an array of key-value pairs
          const aptitudeSkillsArray = Object.keys(aptitudeSkills).map((key) => {
            return { skill: key, score: aptitudeSkills[key] };
          });

          // console.log("aptitudeSkillsArray : ", aptitudeSkillsArray);
          setAptitude_skills(aptitudeSkillsArray);
        } else {
          console.error("written_skills is undefined or null");
        }

        if (response.data.assessment_result_data.behavioral_skills) {
          const behavioralSkills =
            response.data.assessment_result_data.behavioral_skills;

          // Transform the object into an array of key-value pairs
          const behavioralSkillsArray = Object.keys(behavioralSkills).map(
            (key) => {
              return { skill: key, score: behavioralSkills[key] };
            }
          );

          // console.log("behavioralSkillsArray : ", behavioralSkillsArray);
          setBehavioralSkills(behavioralSkillsArray);
        } else {
          console.error("behavioralSkillsArray is undefined or null");
        }

        if (response.data.assessment_result_data.communication_skills) {
          const communicationSkills =
            response.data.assessment_result_data.communication_skills;

          // Transform the object into an array of key-value pairs
          const communicationSkillsArray = Object.keys(communicationSkills).map(
            (key) => {
              return { skill: key, score: communicationSkills[key] };
            }
          );

          // console.log("communicationSkillsArray : ", communicationSkillsArray);
          setCommunicationSkills(communicationSkillsArray);
        } else {
          console.error("communicationSkillsArray is undefined or null");
        }

        if (response.data.assessment_result_data.technical_skills) {
          const technicalSkills =
            response.data.assessment_result_data.technical_skills;

          // Transform the object into an array of key-value pairs
          const technicalSkillsArray = Object.keys(technicalSkills).map(
            (key) => {
              return { skill: key, score: technicalSkills[key] };
            }
          );

          // console.log("technicalSkillsArray : ", technicalSkillsArray);
          setTechnicalSkills(technicalSkillsArray);
        } else {
          console.error("technicalSkillsArray is undefined or null");
        }

        if (response.data.assessment_result_data.written_skills) {
          const writtenSkills =
            response.data.assessment_result_data.written_skills;

          // Transform the object into an array of key-value pairs
          const writtenSkillsArray = Object.keys(writtenSkills).map((key) => {
            return { skill: key, score: writtenSkills[key] };
          });

          // console.log("writtenSkillsArray : ", writtenSkillsArray);
          setWrittenSkills(writtenSkillsArray);
        } else {
          console.error("technicalSkillsArray is undefined or null");
        }
        // console.log("externalResultDataExists value : ",coursedata.externalResultDataExists)
        if (coursedata.externalResultDataExists == false) {
          const saveResultToLms = await axios.post(
            variables.API_URL + "api/SaveExternalScore",
            response.data
          );
          console.log("saveResultToLms : ", saveResultToLms);
        }
      } else {
        //alert("No assessment result data found.");
        setError(true);
        confirmAlert({
          message: "Something went wrong, try again",
          buttons: [
            {
              label: "Try Again",
              onClick: () => {
                getEgaResult();
              },
              class: "primary-btn",
            },
          ],
          closeOnClickOutside: false,
        });
      }
    } catch (error) {
      console.error("Error fetching assessment result:", error);
      //alert("An error occurred while fetching the assessment result.");
      setError(true);
      /*  confirmAlert({
        message: "We are generating your score, please check again after 5-10 min",
        buttons: [
          {
            label: "Go Back",
            onClick: () => { goBack() },
            class: "primary-btn"
          }
        ],
        closeOnClickOutside: false

    }) */
    } finally {
      setIsLoader(false);
    }
  };
  const isBackBtnClicked = () => {
    navigate("/mycourses");
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("coursedata"));

    getEgaResult();

    dispatch(updateChild(data.courseName));
    dispatch(updateParent("My courses"));
  }, []);

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("coursedata"));
    // console.log({ data });
    if (sessionStorage.getItem("assesmentStatus") === "Completed") {
      const resultbody = {
        userID: sessionStorage.getItem("login-userId"),
        courseID: data.courseId,
        projectID: data.projectId,
        batchID: data.batchId,
        status: "Completed",
      };
      axios
        .post(
          variables.API_URL + "api/SaveExternalAssessmentStatus",
          resultbody
        )
        .then((res) => {
          // console.log("response from api SaveExternalAssessmentlink : ", res);
          this.setState({ isLoader: false });
          //window.open(newAppUrl, '_blank');
        })
        .catch((err) => {
          // console.log("error from api SaveExternalAssessmentlink : ", err);
        });
    }
  }, []);

  const isScoreGenerated = coursedata?.releaseScoreToCandidate;
  // console.log("isScoreGenerated : ", isScoreGenerated);
  const markedCompleted = coursedata?.markedCompleted;
  // console.log("markedCompleted : ", markedCompleted);

  // console.log("summaryScore : ",summaryScore)
  if (
    !isScoreGenerated ||
    isScoreGenerated == undefined ||
    markedCompleted == "0"
  ) {
    return (
      <div class="container-fluid body-wrapper bg-default">
        <div class="container p-0">
          <Breadcrumb
            redirectLink="mycourses"
            hamburgerClassSow={showMainDiv}
          />
        </div>
        <div class="container">
          <div class={`course-indexcontent `}>
            <div
              className={`col-lg-9 col-sm-12 col-xs-12 mb-3 content-cards ${"sas"}`}
            >
              {" "}
              <div className="primary-radius bg-white primary-radius mb-3">
                {" "}
                <div className="session-heading bg-body border-bottom">
                  <div className="d-flex">
                    <div class="pink-box">
                      <img
                        src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png"
                        className=""
                      ></img>
                    </div>
                    <div class="heading-one">
                      <h5 className="mb-0 text-truncate">Assessment Score</h5>
                    </div>
                  </div>
                </div>
                <CompletedTest
                  dontShowNavBtns={true}
                  ImgSrc="../../Assets/Linqskills_Icons/test-submited.svg"
                  Message="Your test score will be generated and shared with your college."
                  SecondMessage="To find out your score, please contact your college administrator"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {isScoreGenerated && (
          <>
            {/*  <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop> */}
            {isLoader && <CustumLoader message="Loading..." />}

            <div class="container">
              <Breadcrumb
                redirectLink="mycourses"
                hamburgerClassSow={showMainDiv}
              />
            </div>

            {isScoreVisible ? (
              <>
                <div className="p-4 col-lg-9 summary-score col-sm-12 col-xs-12 content-cards ">
                  <div className="profile-heading primary-radius">
                    <div className=" session-heading bg-body border-bottom">
                      <div className="d-flex">
                        <img
                          src="../../Assets/Linqskills_Icons/edit-passwordpink.svg"
                          className=""
                        ></img>
                        <h5 className="mb-0 mt-1 ms-2 ps-1 ">
                          {" "}
                          Assessment Score
                        </h5>
                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12 p-4 profile-details m-auto">
                      <div class="col-md-11 m-auto">
                        <span class="score-txt">
                          {" "}
                          You have completed the test, the overall score
                          achieved is{" "}
                          <span class="score-bold">
                            {summaryScore?.overall_score}
                          </span>
                        </span>
                      </div>
                        {/* <div class="mt-3 table-score mb-3 col-11 m-auto">
                        <Bar data={data} options={config}/>     
                        </div>   */}

                      {/* accordian one   */}
                      {aptitudeSkills.length > 0 ? (
                        <>
                          <div class="question-solved pt-4 ">
                            <div class="mb-2">
                              <div class="d-block score-btns col-md-11 m-auto accordion-header topic-header ">
                                <div class="col-12 d-flex justify-content-between">
                                  <div class="col-md-6">
                                    <h5 class="line-clamp-one w-140px">
                                      Aptitude Skills
                                    </h5>
                                  </div>
                                  <div class="col-md-6 justify-content-end d-flex">
                                    <h5 class="me-4 mt-1">
                                      {summaryScore?.aptitude_skills_score}
                                    </h5>
                                    <img
                                      src={
                                        visibleQuestion === 1
                                          ? "../../Assets/Linqskills_Icons/circle-btn-up.svg"
                                          : "../../Assets/Linqskills_Icons/circle-btn-down.svg"
                                      }
                                      class="cursor-pointer float-end"
                                      onClick={() =>
                                        toggleQuestionVisibility(1)
                                      }
                                    ></img>
                                    {/* <img src="../../Assets/Linqskills_Icons/circle-btn-down.svg" class="cursor-pointer"></img> */}
                                  </div>
                                </div>
                                {visibleQuestion === 1 && (
                                  <div class="mt-3 table-score mb-3 col-11 m-auto">
                                   <Radar options={aptitude_skills_options} data={aptitude_skills_data} /> 

                                    {/* score table  */}
                                       <div class="graph-table">
                          <div class="col-md-7 col-sm-12">

           
  
              {/* <div class="graph-score " >
                <Radar data={communication_skills_data} options={communication_skills_options} />
              </div>
  */}
       
                          </div>
                          {/* <div class="col-md-5 col-sm-12 table-g  ">
                            <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>A.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Positive Mindset</h5>
                                </div>
                          
                              </div>
                              <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>B.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Presentation Skills</h5>
                                </div>
                          
                              </div>
                              <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>C.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Voice, Articulation and Diction</h5>
                                </div>
                          
                              </div>
                              <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>D.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Positive Mindset</h5>
                                </div>
                          
                              </div>
                              <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>E.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Presentation Skills</h5>
                                </div>
                          
                              </div>
                              <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>F.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Voice, Articulation and Diction</h5>
                                </div>
                          
                              </div>
                              <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>F.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Voice, Articulation and Diction</h5>
                                </div>
                          
                              </div>
                              <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>F.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Voice, Articulation and Diction</h5>
                                </div>
                          
                              </div>
                              <div class="d-flex mb-3">
                              <div class="d-flex">
                              <h5>F.</h5> <h5 class="bold-700">4.2:</h5>

                                </div>
                              <div class="ms-2">
                              <h5>Voice, Articulation and Diction</h5>
                                </div>
                          
                              </div>
  
</div> */}
                          
                        </div>  

                                    <table class="table mt-3">
                                      <tbody>
                                        {aptitudeSkills.map((item) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{item.skill}</td>
                                                <td>
                                                  {
                                                    summaryScore[
                                                      "aptitude_skills"
                                                    ][item.skill]
                                                  }
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* accordian twp  */}
                      {behavioralSkills.length > 0 ? (
                        <>
                          <div class="question-solved pt-4">
                            <div class="mb-2 ">
                              <div class="score-btns d-block col-md-11 m-auto accordion-header topic-header ">
                                <div class="col-12 d-flex justify-content-between">
                                  <div class="col-md-6 ">
                                    <h5 class="line-clamp-one w-140px">
                                      Behavioral Skills
                                    </h5>
                                  </div>
                                  <div class="col-md-6 justify-content-end d-flex">
                                    <h5 class="me-4 mt-1">
                                      {summaryScore.behavioral_score}
                                    </h5>
                                    <img
                                      src={
                                        visibleQuestion === 2
                                          ? "../../Assets/Linqskills_Icons/circle-btn-up.svg"
                                          : "../../Assets/Linqskills_Icons/circle-btn-down.svg"
                                      }
                                      class="cursor-pointer float-end"
                                      onClick={() =>
                                        toggleQuestionVisibility(2)
                                      }
                                    ></img>
                                    {/* <img src="../../Assets/Linqskills_Icons/circle-btn-down.svg" class="cursor-pointer"></img> */}
                                  </div>
                                </div>
                                {visibleQuestion === 2 && (
                                  <div class="table-score mb-3 col-11 m-auto">
                                    <table class="table mt-3">
                                      <tbody>
                                        {behavioralSkills.map((item) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{item.skill}</td>
                                                <td>
                                                  {
                                                    summaryScore[
                                                      "behavioral_skills"
                                                    ][item.skill]
                                                  }
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* accordian three  */}
                      {communicationSkills.length > 0 ? (
                        <>
                          <div class="question-solved pt-4">
                            <div class="mb-2 ">
                              <div class="score-btns d-block col-md-11 m-auto accordion-header topic-header ">
                                <div class="col-12 d-flex justify-content-between">
                                  <div class="col-md-6 ">
                                    <h5 class="line-clamp-one w-140px">
                                      Communication Skills
                                    </h5>
                                  </div>
                                  <div class="col-md-6 justify-content-end d-flex">
                                    <h5 class="me-4 mt-1">
                                      {summaryScore.communication_score}
                                    </h5>
                                    <img
                                      src={
                                        visibleQuestion === 3
                                          ? "../../Assets/Linqskills_Icons/circle-btn-up.svg"
                                          : "../../Assets/Linqskills_Icons/circle-btn-down.svg"
                                      }
                                      class="cursor-pointer float-end"
                                      onClick={() =>
                                        toggleQuestionVisibility(3)
                                      }
                                    ></img>
                                    {/* <img src="../../Assets/Linqskills_Icons/circle-btn-down.svg" class="cursor-pointer"></img> */}
                                  </div>
                                </div>
                                {visibleQuestion === 3 && (
                                  <div class="table-score mb-3 col-11 m-auto">
                                    <table class="table mt-3">
                                      <tbody>
                                        {communicationSkills.map((item) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{item.skill}</td>
                                                <td>
                                                  {
                                                    summaryScore[
                                                      "communication_skills"
                                                    ][item.skill]
                                                  }
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* accordian four  */}
                      {technicalSkills.length > 0 ? (
                        <>
                          <div class="question-solved pt-4">
                            <div class="mb-2 ">
                              <div class="score-btns d-block col-md-11 m-auto accordion-header topic-header ">
                                <div class="col-12 d-flex justify-content-between">
                                  <div class="col-md-6 ">
                                    <h5 class="line-clamp-one w-140px">
                                      Technical Skills
                                    </h5>
                                  </div>
                                  <div class="col-md-6 justify-content-end d-flex">
                                    <h5 class="me-4 mt-1">
                                      {summaryScore.technical_skills_score}
                                    </h5>
                                    <img
                                      src={
                                        visibleQuestion === 4
                                          ? "../../Assets/Linqskills_Icons/circle-btn-up.svg"
                                          : "../../Assets/Linqskills_Icons/circle-btn-down.svg"
                                      }
                                      class="cursor-pointer float-end"
                                      onClick={() =>
                                        toggleQuestionVisibility(4)
                                      }
                                    ></img>
                                    {/* <img src="../../Assets/Linqskills_Icons/circle-btn-down.svg" class="cursor-pointer"></img> */}
                                  </div>
                                </div>
                                {visibleQuestion === 4 && (
                                  <div class="table-score mb-3 col-11 m-auto">
                                    <table class="table mt-3">
                                      <tbody>
                                        {technicalSkills.map((item) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{item.skill}</td>
                                                <td>
                                                  {
                                                    summaryScore[
                                                      "technical_skills"
                                                    ][item.skill]
                                                  }
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {writtenSkills.length > 0 ? (
                        <>
                          <div class="question-solved pt-4">
                            <div class="mb-2 ">
                              <div class="score-btns d-block col-md-11 m-auto accordion-header topic-header ">
                                <div class="col-12 d-flex justify-content-between">
                                  <div class="col-md-6 ">
                                    <h5 class="line-clamp-one w-140px">
                                      Written Skills
                                    </h5>
                                  </div>
                                  <div class="col-md-6 justify-content-end d-flex">
                                    <h5 class="me-4 mt-1">
                                      {summaryScore.written_skills_score}
                                    </h5>
                                    <img
                                      src={
                                        visibleQuestion === 5
                                          ? "../../Assets/Linqskills_Icons/circle-btn-up.svg"
                                          : "../../Assets/Linqskills_Icons/circle-btn-down.svg"
                                      }
                                      class="cursor-pointer float-end"
                                      onClick={() =>
                                        toggleQuestionVisibility(5)
                                      }
                                    ></img>
                                    {/* <img src="../../Assets/Linqskills_Icons/circle-btn-down.svg" class="cursor-pointer"></img> */}
                                  </div>
                                </div>
                                {visibleQuestion === 5 && (
                                  <div class="table-score mb-3 col-11 m-auto">
                                    <table class="table mt-3">
                                      <tbody>
                                        {writtenSkills.map((item) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{item.skill}</td>
                                                <td>
                                                  {
                                                    summaryScore[
                                                      "written_skills"
                                                    ][item.skill]
                                                  }
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="container-fluid body-wrapper bg-default">
                  <div class="container">
                    <div class={`course-indexcontent `}>
                      <div
                        className={`col-lg-9 col-sm-12 col-xs-12 mb-3 content-cards ${"sas"}`}
                      >
                        {" "}
                        <div className="primary-radius bg-white primary-radius mb-3">
                          {" "}
                          <div className="session-heading bg-body border-bottom">
                            <div className="d-flex">
                              <div class="pink-box">
                                <img
                                  src="../../Assets/Linqskills_Icons/assessment-multicolorRed.png"
                                  className=""
                                ></img>
                              </div>
                              <div class="heading-one">
                                <h5 className="mb-0 text-truncate">
                                  Assessment Score
                                </h5>
                              </div>
                            </div>
                          </div>
                          <CompletedTest
                            dontShowNavBtns={true}
                            ImgSrc="../../Assets/Linqskills_Icons/test-submited.svg"
                            Message="Your test score will be generated and shared with your college."
                            SecondMessage="To find out your score, please contact your college administrator"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
