
import React from 'react';
//import Message from './components/message';
import NavOne from '../NavOne';
import AdminCourseCard from './AdminCourseCard';
import SessionHeading from '../EligibilityCheck/SessionHeading';
import DropdownAdminbtn from './DropdownAdminbtn';

export default function AdminMeetingDetails() {

  return (
    <>
      
      <div className="col-lg-9 col-sm-12 col-xs-12 content-cards bg-body box-one">
        <SessionHeading/>

        <div class="pt-3">
        <nav class="navbar navbar-expand-lg navbar-light p-0 admin-navbar ">

                
<div class="navbar-collapse nav-show mb-3 " id="navbarSupportedContent">
        <ul class="navbar-nav justify-content-center mx-auto mb-lg-0 col-lg-9 false responsive-nav undefined border-bottom pb-2">
      <li class="nav-item menu-w text-lg-center text-sm-center ">
        <img src="../../Assets/Linqskills_Icons/performance-Grey.svg" class="cursor-pointer"></img>
          <a class="nav-link  m-0 p-0 hide-text ">My Performance</a>
          </li>
          <li class="nav-item menu-w text-lg-center text-sm-center display-desktop">
        <img src="../../Assets/Linqskills_Icons/Admin/candidate-grey.svg" class="cursor-pointer"></img>
          <a class="nav-link  m-0 p-0 hide-text ">Enrollment</a>
          </li>
          </ul>
          </div>

</nav>
      
      <div class="details-box mb-4">
          <div class="d-flex">
            <div class="col-md-6 lecture-details p-3">
              <h5 class="bold-500 mb-3">Online Lecture Session Duration</h5>
              <h6 class="bold-500">Choose the duration in minutes for the Online Lecture.</h6>
            </div>
            <div class="col-md-6 p-3">
              <div class="col-md-6">
              <DropdownAdminbtn/>
              </div>
          
            </div>
          </div>
        </div>


        <div class="details-box mb-4">
          <div class="d-flex">
            <div class="col-md-6 lecture-details p-3">
              <h5 class="bold-500 mb-3">Online Lecture Session Start Date and Time</h5>
              <h6 class="bold-500">Choose the date and time you'd like to commence the Online Lecture. This information will be visible to both participants and other users.</h6>
            </div>
            <div class="col-md-6 p-3">
            <form>
                <div class="labelWrap mb-3">
                  <input name="username" type="date" placeholder="Select Date" autocomplete="off" class="form-inputOne input-bg" value="" data-mdb-inline="true"></input>
                    <label>Login ID
                  <img src="../../Assets/Linqskills_Icons/Mandatory_24.svg" class="ms-1"></img>
                    </label></div>
                </form>
              <div class="col-md-6">
    
              <DropdownAdminbtn/>
              </div>
          
            </div>
          </div>
        </div>




        <div class="details-box mb-4">
          <div class="d-flex">
            <div class=" lecture-details p-3">
              <h5 class="bold-500 mb-3">Online Lecture Session Duration</h5>
              <h6 class="bold-500">Choose the duration in minutes for the Online Lecture.</h6>
            </div>
            <div class="col-md-6 p-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
              <label class="form-check-label" for="flexCheckDefault">
               When meeting is created
              </label>
        
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
              <label class="form-check-label" for="flexCheckDefault">
              at 8:00 am (IST) on Scheduled Date
              </label>
          
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input>
              <label class="form-check-label" for="flexCheckDefault">
              1 hr prior to Start Time
              </label>
          
            </div>
          
            </div>
          </div>
        </div>


        <div class=" mb-4">
          <div class="d-flex ">
            <div class="box-width details-box lecture-details bg-white p-3 me-3">
              <h5 class="bold-500 mb-3">Meeting Details for Trainer (Host)</h5>
              <h5 class="bold-500 color-change">Scheduled Date:<span class="">22 Feb 2023</span></h5>
              <h5 class="bold-500 color-change">Duration:<span class="">1 Hr 15 Mins</span></h5>
              <h5 class="bold-500 color-change">Sctart Time:<span class="">2:15PM</span></h5>
              <h5 class="bold-500 color-change">End Time:<span class="">3:30PM</span></h5>
            </div>
            <div class="box-width ms-5 p-3 details-box">
            <div class=" lecture-details bg-white">
              <h5 class="bold-500 mb-3">Meeting Details for Participants & other users</h5>
              <h5 class="bold-500 color-change">Scheduled Date:<span class="">22 Feb 2023</span></h5>
              <h5 class="bold-500 color-change">Duration:<span class="">1 Hr 15 Mins</span></h5>
              <h5 class="bold-500 color-change">Sctart Time:<span class="">2:15PM</span></h5>
              <h5 class="bold-500 color-change">End Time:<span class="">3:30PM</span></h5>
            </div>
            </div>
          </div>
        </div>
<div class="col-md-12 mb-3 text-center">
  <button class="primary-btn">Start Meeting</button>
</div>
        </div>

        </div>
          </>
      )
  
}


