
import React, { useState, useEffect } from "react";
//import { Redirect } from "react-router-dom";
//import TrainerMessage from '../../components/CourseIndex/TrainerMessage.js';
//import Translate from '../Shared/Translate';
//import Cookies from 'universal-cookie';
//import PopupOne from '../Shared/PopupOne';

import { variables } from '../Shared/Variables';

//import AssessmentInstructions from './AssessmentInstructions';



export default function AssignmentDemo() {
   
    return (
            <div className="col-lg-9 col-sm-12 col-xs-12 content-cards bg-body box-one">
                
                   
                    
                        
                        <div className="row">
                            <div className="session-heading bg-body border-bottom">
                                <div className="d-flex">
                                    <img src="../../Assets/Linqskills_Icons/LearningTopic.svg" className=""></img>

                                    <h5 className="mb-0 mt-1 ms-2 ps-1">Assignment</h5>
                                </div>
                                <div className="session-details pt-1 ps-1">
                                  <div className="d-flex me-2">
                                    <div >
                                    <img src="../../Assets/Linqskills_Icons/marks-grey.svg" className=""></img>
                                    </div>
                                    <div>
                                    <span className="h7 self-align-center mt-1 ms-1">
                                          105 marks</span>
                                    </div>
                                  </div>
                                  <div className="d-flex me-2">
                                    <div >
                                    <img src="../../Assets/Linqskills_Icons/marks-grey.svg" className=""></img>
                                    </div>
                                    <div>
                                    <span className="h7 self-align-center mt-1 ms-1">
                                          105 marks</span>
                                    </div>
                                  </div>
                                </div>
                                
                            </div>
                            <div className="assessment-instructions border-bottom">
                                <h6>Scheduled Date : <span className="b-text">22 October 2023</span></h6>
                                <h6>Scheduled Start Time :  <span className="b-text">11:00 AM</span></h6>
                                <h6>Completion Date:  <span className="b-text">122 October 2023</span></h6>
                                <h6>Scheduled End Time:  <span className="b-text">11:00 AM</span></h6>

                                <h6>Submission Type:  <span className="b-text">Self</span></h6>
                                <h6>Graded:  <span className="b-text">Yes</span></h6>


                            <p className="instruction-info">ss</p>
                            </div>
                            <div className="row text-center pt-3 pb-3 click-start">
                                <h6>Click on the “Start” button to proceed</h6>
                                <button className="primary-btn m-auto text-uppercase" >Start</button>
                            </div>
                        </div>
                

            </div>
    )

}


