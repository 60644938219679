import React from "react";
import {Link} from 'react-router-dom';


export default function DropdownAdminbtn() {
  return (
      <>
<div class="dropdown-btn cursor-pointer">
<div class="select-dropdown primary-btn cursor-pointer">
<img src="../../Assets/Linqskills_Icons/admin/batches-white.svg" class="ms-2 mt-1 batches-left"></img>

<img src="../../Assets/Linqskills_Icons/arrowdown-white.svg" class="float-end arrow-down cursor-pointer"></img>
  <select class="cursor-pointer image-padding">
    <option value="Option 1 text-center" data-title="../../Assets/Linqskills_Icons/video24_white.svg">
      View Details</option>
  
    <option value="Option 2">

      Register Participants</option>
    <option value="Option 3">Option Number 3</option>
  </select>
</div>
</div> 
{/* <Link to="/TrainingDelivery">Contact</Link> */}
{/* dropdown section should open onclick view details */}
{/* <div class="btndropdown-section">
<div class="mb-2 d-flex">
<span>    <img src="../../Assets/Linqskills_Icons/admin/registerParticipants-black.svg" class="me-2 "></img></span>
  <h5 class="mt-1"> 
    Register Participants</h5>
</div>

<div class="mb-2 d-flex">
<span>   
   <img src="../../Assets/Linqskills_Icons/admin/viewreport-grey.svg" class="me-2 "></img></span>
<h5 class="mt-1"> 
    View Report</h5>
</div>

<div class="mb-2 d-flex">
<span>   
  <img src="../../Assets/Linqskills_Icons/admin/editDetails-black.svg" class="me-2 "></img></span>
  <h5 class="mt-1"> 
    Edit Details</h5>
</div>

</div> */}
{/* dropdown section */}


{/* Form button(Used for forms and popups only) */}

                          {/* <div class="dropdown-btn cursor-pointer">
                                <div class="select-dropdown form-btn cursor-pointer">
                                        <img src="../../Assets/Linqskills_Icons/Admin/Navdown-default.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select class="cursor-pointer">
                                                <option value="Option 1 text-center" data-title="../../Assets/Linqskills_Icons/video24_white.svg">Participant</option><option value="Option 2">Option Number 2</option><option value="Option 3">Option Number 3</option></select>
                                                </div>
                             </div> */}
{/* Form button(Used for forms and popups only) */}

</>
    )

}

