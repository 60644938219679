import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: '',
  isLangUpdate : false,
};

const languageSlice = createSlice({
    name:"language",
    initialState,
    reducers:{
        updateLanguage : (state,action)=>{
            state.language = action.payload
        },
        isLanguageUpdated : (state,action)=>{
            state.isLangUpdate = action.payload
        },
    }
})

export const {updateLanguage,isLanguageUpdated} = languageSlice.actions;

export default languageSlice.reducer;