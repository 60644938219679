import React, { useEffect, useState, useRef } from "react";
import NavOne from '../../components/NavOne.js';
import TopicsList from "../Shared/TopicsList.js";
import { variables } from '../Shared/Variables';
import Breadcrumb from "../CourseIndex/Breadcrumb.js";
import TopicContent from "../Shared/TopicContent.js";
import { useParams } from "react-router-dom";
import HamburgerIcons from "../Shared/HamburgerIcons.js";
import AssessmentTopicContent from "../CourseIndex/AssessmentTopicContent.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateExtended, updategrandChild } from "../../features/breadcrumb/breadcrumbSlice";
import Cookies from "universal-cookie";
import { callingNextPrev, updateNodeIdForAssGetData, updateCallingGetTopicsList } from "../../features/CourseIndex/courseIndexSlice";

const TopicDetails = React.memo((props) => {
    var language = sessionStorage.getItem("prefferedLanguage");
    const shouldLog = useRef(true);
    const { name, id, batchId, deliveryMode, examMode } = useParams();
    const nodeName = useSelector(state => state.breadcrumb.grandchildNode);
    // // console.log("nodeName in topic list : ",nodeName);
    const isNextPrevCalled = useSelector(state => state.courseindex.isNextPrevCalled);
    const callingGetTopicsList = useSelector(state => state.courseindex.callingGetTopicsList);
    const nodeID = id;
    const batchID = batchId;
    const deliveryMODE = deliveryMode;
    const examMODE = examMode;
    const [listOfTopics, setTopicsList] = useState([]);
    const [isDisplayTopicContent, setTopicContentDisplay] = useState(false);
    const [topicContentData, setTopicContentData] = useState({});
    const [currentTopicContentData, setCurrentTopicContentData] = useState([]);
    const [updateTopicTimeSpent, setUpdateTopicTimeSpent] = useState(false);
    const [showMainDiv, setShowMainDiv] = useState(true);
    const [isTopicChanged, setIsTopicChanged] = useState(false);
    const [isBackBtnClicked, setIsBackBtnClicked] = useState(false);
    const [index, setIndex] = useState(0);
    // const [isIndexChanged, setIsindexChanged] = useState(-1);
    const dispatch = useDispatch();


    useEffect(() => {
        // // console.log("working after istopic outside changed")
        if (shouldLog.current) {
            shouldLog.current = false;
            // // console.log("working after istopic inside changed")
            GetTopicsList(nodeName, nodeID, batchID, true);
        }
    }, [updateTopicTimeSpent, isTopicChanged, index]);

    

    // const funcForCurrentTopic = async() =>{
    //     let resAfterFetchingNextPrev = await JSON.parse(sessionStorage.getItem("nextPrevData"));
    //     if(listOfTopics.length > 0){
    //         if(listOfTopics[index].sessionDeliveryMode == 2){
    //             for (let i = 0; i < resAfterFetchingNextPrev?.data.userDataList.length; i++) {
    //                if(resAfterFetchingNextPrev?.data.userDataList[i].nodeID == listOfTopics[index].nodeID){
    //                 // console.log("setCurrentTopicContentData : ",resAfterFetchingNextPrev?.data.userDataList[i]);
    //                 // console.log("setTopicContentData : ",listOfTopics[index]);
    //                 setCurrentTopicContentData(resAfterFetchingNextPrev?.data.userDataList[i]);
    //                 setTopicContentData(listOfTopics[index]);
    //                }
    //             }
    //         } else {
    //         }
    //     }
    // }
    // useEffect(()=>{
    //     // funcForCurrentTopic()
    // },[index])

    useEffect(() => {
        if (isNextPrevCalled) {
            GetTopicsList(nodeName, nodeID, batchID, true);
            dispatch(callingNextPrev(false));
        }
        // console.log("callingGetTopicsList:", callingGetTopicsList);
        if (callingGetTopicsList) {
            dispatch(updateCallingGetTopicsList(false));
            // alert("calling GetTopicsList in topicList.js after updating nextPrev")
            GetTopicsList(nodeName, nodeID, batchID, true);
            // // console.log("seeting toggle for updating ui");
            // setToggle(!toggle);
        }
    }, [isNextPrevCalled, callingGetTopicsList])

    // useEffect(()=>[
    //     // console.log("working after istopic changed")
    //     GetTopicsList(nodeName, nodeID, batchID, true);
    // ],[isTopicChanged])




    const GetTopicsList = (nodeName, nodeID, batchID, isDisplayContent) => {
        // console.log("calling GetTopicsList");
        var userID = sessionStorage.getItem("login-userId");
        userID = encodeURIComponent(userID);

        fetch(variables.API_URL + 'api/UserSelfStudy',
            {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    UserID: userID,
                    NodeID: nodeID,
                    BatchID: batchID,
                }),
            })
            .then(response => {
                if (response.statusText == "Unauthorized") {
                    this.setState({ isUnauthorizedUser: true });
                }
                else
                    return response.json();
            })
            .then(data => {
                // console.log("setTopicsList(data.dataList) : ", data)
                if (data.isSuccess) {
                    // alert(data.dataList)
                    setTopicsList(data.dataList);
                    if (isDisplayContent) {
                        var lists = data.dataList;
                        lists.map((l) => {
                            var conEng = l.contentInEnglish;

                            conEng = conEng.indexOf("amazonaws.com") < 0 ? conEng.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conEng;

                            conEng = conEng.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/")

                            var conHin = l.contentInHindi;

                            conHin = conHin.indexOf("amazonaws.com") < 0 ? conHin.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conHin;

                            conHin = conHin.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

                            var conTam = l.contentInTamil;

                            conTam = conTam.indexOf("amazonaws.com") < 0 ? conTam.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conTam;

                            conTam = conTam.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

                            var conBen = l.contentInBengali;

                            conBen = conBen.indexOf("amazonaws.com") < 0 ? conBen.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conBen;

                            conBen = conBen.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

                            var conTel = l.contentInTelugu;

                            conTel = conTel.indexOf("amazonaws.com") < 0 ? conTel.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conTel;

                            conTel = conTel.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");

                            var conOdi = l.contentInOdiya;

                            conOdi = conOdi.indexOf("amazonaws.com") < 0 ? conOdi.replaceAll("/Data/", variables.AWSPath + variables.AWSFolder + "/Data/") : conOdi;

                            conOdi = conOdi.replaceAll("/DATA/", variables.AWSPath + variables.AWSFolder + "/Data/");


                            const updatedItem = {

                                ...l.contentInEnglish = conEng,

                                ...l.contentInHindi = conHin,

                                ...l.contentInTamil = conTam,

                                ...l.contentInBengali = conBen,

                                ...l.contentInTelugu = conTel,

                                ...l.contentInOdiya = conOdi

                            }
                            return updatedItem;
                        });
                        // // console.log("working here in GetTopicsList : ",data.dataList )
                        DisplayTopicContent(lists);
                        dispatch(updateNodeIdForAssGetData(lists[0].nodeID));
                        // // console.log("lists[0].[`nodeName_${language}`] : ",lists[0][`nodeName_${language}`]);
                        dispatch(updategrandChild(lists[0][`sessionName_${language}`]));
                        sessionStorage.setItem("subNodeData", JSON.stringify(data.dataList[0]));
                    }
                }
            });
    }

    const DisplayTopicContent = async (dataList) => {
        // alert("DisplayTopicContent");
        // console.log("dataList in  DisplayTopicContent : ", dataList)
        let resAfterFetchingNextPrev = await JSON.parse(sessionStorage.getItem("nextPrevData"));
        if (dataList.length > 0) {
            if (dataList.length > 1) {
                var data = dataList.filter((topics) => topics.topicStatus == "Open");
                // console.log("data after filter open status : ", data)
                if (data.length >= 0) {
                    // console.log(`ppp ${index} : `, dataList[index]);
                    if (dataList[index].sessionDeliveryMode == 2) {
                        for (let i = 0; i < resAfterFetchingNextPrev?.data.userDataList.length; i++) {
                            if (resAfterFetchingNextPrev?.data.userDataList[i].nodeID == dataList[index].nodeID) {
                                // console.log("setTopicContentData : ", resAfterFetchingNextPrev?.data.userDataList[i]);
                                setCurrentTopicContentData(resAfterFetchingNextPrev?.data.userDataList[i]);
                                setTopicContentData(dataList[index]);
                            }
                        }
                    } else {
                    }
                }
                else {
                    // console.log("aaa")
                    setTopicContentData(dataList[0]);
                    setCurrentTopicContentData(dataList[0]);
                }
            }
            else {
                // console.log("rrr when only topic available : ", dataList[0])
                for (let a = 0; a < resAfterFetchingNextPrev?.data.userDataList.length; a++) {
                    if (resAfterFetchingNextPrev?.data.userDataList[a].nodeID == dataList[0].nodeID) {
                        setCurrentTopicContentData(resAfterFetchingNextPrev?.data.userDataList[a]);
                        setTopicContentData(dataList[0]);
                    }
                }
            }

            setTopicContentDisplay(true);
        }
    }

    const handleTopicStatusUpdate = () => {
        // alert("handleTopicStatusUpdate");
        setUpdateTopicTimeSpent(false);

        GetTopicsList(nodeName, nodeID, batchID, false);
    }

    const cookies = new Cookies();

    const handleTopicChange = (data, isChange) => {
        // // console.log("data in handleTopicChange : ",data);
        // // alert("handleTopicChange: "+ isChange);
        // setUpdateTopicTimeSpent(isChange);

        //// Invoke GetTopicsList() after 2secs, so that the setUpdateTopicTimeSpent is updated first and timespent of the current node gets updated
        //setTimeout(() => {  
        // // console.log(`function callled handleTopicChange from topic list ${data}  `)
        DisplayTopicContent(data);
        // // console.log("working after istopic changed calling GetTopicsList")
        // GetTopicsList(nodeName, nodeID, batchID, true);
        //}, 2000);
    }

    const DisplayHamburger = () => {
        setShowMainDiv(false);
    }

    const HandleHamburgerClick = (data) => {
        setShowMainDiv(data);
    }

    const CloseHamburger = () => {
        // alert("CloseHamburger");
        setShowMainDiv(true);

        // showMainDiv = true;
        //TestAlert();

    }

    useEffect(() => {
        dispatch(updateExtended("true"));
    }, [])

    //  // console.log("listOfTopics : ", listOfTopics)
    return (
        <>
            <NavOne mobileView={true} isDNone={true} />
            <div class="container-fluid body-wrapper bg-default">
                <div class="container p-0">
                    <Breadcrumb setIsBackBtnClicked={setIsBackBtnClicked} isBackBtnClicked={isBackBtnClicked} redirectLink="CourseIndex" hamburgerClassSow={showMainDiv} />
                </div>
                <div class="container">

                    {/* showHamburger={true} hideHamburger={true}  */}
                    <HamburgerIcons showHamburgerIcon={showMainDiv} DisplayHamburger={DisplayHamburger} CloseHamburger={CloseHamburger} hamburgerParentCallBack={HandleHamburgerClick} />
                    <div class={`course-indexcontent `}>
                        <TopicsList  setCurrentTopicContentData={setCurrentTopicContentData} sessionType="selfStudy" setIsTopicChanged={setIsTopicChanged} isTopicChanged={isTopicChanged} sessionName={nodeName} sessionId={nodeID} topicsListData={listOfTopics} onTopicClick={handleTopicChange} showHamburger={DisplayHamburger} hideHamburger={CloseHamburger} showHamburgerIcon={showMainDiv} setIndex={setIndex} index={index} />
                        {/* this is for self study */}
                        {
                            isDisplayTopicContent && deliveryMODE == 2 &&
                            /*  <div class={`${showMainDiv ? "sas" : "d-none"}`}  >  */
                            <TopicContent setCurrentTopicContentData={setCurrentTopicContentData} currentTopicContent={currentTopicContentData} topicContent={topicContentData} isBackBtnClicked={isBackBtnClicked} setIsBackBtnClicked={setIsBackBtnClicked} topicsListData={listOfTopics} setIsTopicChanged={setIsTopicChanged} isTopicChanged={isTopicChanged} ParentNodeId={nodeID} ParentNodeName={nodeName} onTopicClick={handleTopicChange} batchId={batchID} onTopicComplete={handleTopicStatusUpdate} isUpdateTopicTimeSpent={updateTopicTimeSpent} showMainDiv={showMainDiv} setIndex={setIndex} index={index} />
                            /* </div> */
                        }
                        {/* this is for assessment below */}
                        {
                            isDisplayTopicContent && deliveryMODE == 4 &&
                            <AssessmentTopicContent  index={index} showMainDiv={showMainDiv} topicContent={topicContentData} topicsListData={listOfTopics} ParentNodeId={nodeID} ParentNodeName={nodeName} batchId={batchID}
                                onTopicComplete={handleTopicStatusUpdate} isUpdateTopicTimeSpent={updateTopicTimeSpent} setIndex={setIndex} examMode={examMODE} />
                        }

                    </div>

                </div>
            </div>
        </>
    )
})

export default TopicDetails
