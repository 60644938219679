import React, { useState, useEffect } from "react";
import NavOne from '../NavOne';
import Translate from '../Shared/Translate';
import DropdownAdminbtn from '../Admin/DropdownAdminbtn';
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { variables } from './Variables';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustumLoader from "./CustumLoader";
import { HighestQualificationArray, CurrentDesignation, ClassesYouAreCurrentlyTeaching, SubjectsYouAreCurrentlyInstructing, Semester, OngoingPassedArray, CollegeNameArray, DepartmentArray } from "../generalFunctions/profileValues";


export default function WelcomeMenu(props) {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const userId = sessionStorage.getItem("login-userId")
  let isDevice = useSelector(state => state.courseindex.isDevice);
  const courseData = JSON.parse(sessionStorage.getItem("coursedata"));
  // console.log("courseData : ", courseData);
  const initialValues = {
    fullname: courseData?.firstName ? courseData?.firstName : "",
    email: "",
    GenderPreference: "",
    LanguagePreference: courseData?.preferredLanguageId ? courseData?.preferredLanguageId : "",
    CurrentWorkingStatus: "",
    CurrentJobPosition: "",
    SelectTheCountry: "",
    usediCode: "",
    state: "",
    district: "",
    schoolName: "",
  }
  const [tempArr, setTempArr] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  const [formType, setFormType] = useState(props.isProfile == true ? "organisation" : "personal"); //change this
  const [queIds, setQueIds] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  const [isUsediCodeCheck, setIsUsediCodeCheck] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [valid, setValid] = useState(false);

  const formHandler = (type) => {
    // console.log("working in formHandler : ");
    setFormType(type);
  }
  const [languageList, setLanguageList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [isUsediCodeVerified, setIsUsediCodeVerified] = useState("unchecked");
  const [isNameValidText, setIsNameValidText] = useState(true);
  const getAllStates = async () => {
    // let UserId = sessionStorage.getItem("login-userId")
    // // console.log("login-userId : ", UserId);
    const result = await axios.post(variables.API_URL + `api/GetSchoolList`, {
      "StateId": "",
      "DistrictId": ""
    });
    if (result.data.isSuccess && result.data.dataList.length > 0) {
      let resAfterFetchingSates = result.data.dataList;
      // console.log("resAfterFetchingSates : ", resAfterFetchingSates);
      setStateList(resAfterFetchingSates);
    } else {
      // console.log("error while fetching states");
    }

  }

  const getAllLanguages = async () => {
    // let UserId = sessionStorage.getItem("login-userId")
    // // console.log("login-userId : ", UserId);
    const queryParams = {
      isForFormRegister: true,
    };
    const result = await axios.get(variables.API_URL + `api/GetLanguages`, {
      params: queryParams,
    });
    if (result.data.isSuccess && result.data.dataList.length > 0) {
      let resAfterFetchingLangugaes = result.data.dataList;
      // console.log("resAfterFetchingLangugaes : ", resAfterFetchingLangugaes);
      setLanguageList(resAfterFetchingLangugaes);
    } else {
      // console.log("error while fetching Languages");
    }
  }

  const checkDataFromCorseData = () => {
    // console.log("checkDataFromCorseData calling : ", courseData)
    if (!props.isProfile) {

      setQueIds(courseData.formQuestionNo);
    }

    if (courseData?.firstName) {
      // console.log("who first")
      setFormValues({ ...formValues, "fullname": courseData.firstName });
    }
    if (courseData?.email) {
      setFormValues({ ...formValues, "email": courseData.email });
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(courseData?.email);
      // console.log("isValidEmail : ", isValidEmail)
      setValid(isValidEmail);
    }
    if (props.isProfile) {
      // console.log("its from profile dont set language id");
    } else {
      if (courseData?.preferredLanguageId == 1) {
        sessionStorage.setItem("prefferedLanguage", "English")
        cookies.set('language', "English");
      } else if (courseData?.preferredLanguageId == 2) {
        sessionStorage.setItem("prefferedLanguage", "Hindi")
        cookies.set('language', "Hindi");
      }
    }

  }

  useEffect(() => {
    if (props.isProfile) {
      setFormValues({ ...formValues, "district": "" });
      setFormValues({ ...formValues, "state": "" });
      setFormValues({ ...formValues, "schoolName": "" });
      setFormValues({ ...formValues, "usediCode": "" });
      setIsUsediCodeCheck(false);
    }
  }, [props.isShowSettings])


  useEffect(() => {
    // console.log("fetching states in useeffect");
    checkDataFromCorseData();
    getAllStates();
    getAllLanguages();
  }, [])
  

  const getAllDistricts = async (stateId) => {
    // console.log("calling getAllDistricts : ");
    const result = await axios.post(variables.API_URL + `api/GetSchoolList`, {
      "StateId": stateId,
      "DistrictId": ""
    });
    if (result.data.isSuccess && result.data.dataList.length > 0) {
      let resAfterFetchingDistricts = result.data.dataList;
      // console.log("resAfterFetchingDistricts : ", resAfterFetchingDistricts);
      setDistrictList(resAfterFetchingDistricts);
    } else {
      // console.log("error while fetching districts");
    }
  }
  const getAllSchools = async (districtId) => {
    // console.log("calling getAllSchools : ");
    const result = await axios.post(variables.API_URL + `api/GetSchoolList`, {
      "StateId": formValues.state,
      "DistrictId": districtId
    });
    if (result.data.isSuccess && result.data.dataList.length > 0) {
      let resAfterFetchingSchools = result.data.dataList;
      // console.log("resAfterFetchingSchools : ", resAfterFetchingSchools);
      setSchoolList(resAfterFetchingSchools);
    } else {
      // console.log("error while fetching schools");
    }
  }

  const handleFormValues = async (e, ind) => {
    let arr = tempArr;
    // console.log("e.target.value : ", e.target.value);
    // console.log("e.target.name : ", e.target.name);
    // console.log("value of index : ", ind);
    if (e.target.name == "LanguagePreference") {
      // console.log("event.target.options[event.target.selectedIndex].text : ", e.target.options[e.target.selectedIndex].text)
      sessionStorage.setItem("prefferedLanguage", e.target.options[e.target.selectedIndex].text)
      cookies.set('language', e.target.options[e.target.selectedIndex].text)
    }
    if (e.target.name == "CurrentState" || e.target.name == "state") {
      getAllDistricts(e.target.value)
    } else if (e.target.name == "district") {
      getAllSchools(e.target.value)
    }
    if (e.target.value == "") {
      let index = arr.indexOf(ind);
      if (index !== -1) {
        arr.splice(index, 1);
      }
      // console.log("arr inside : ", arr);
      setTempArr(arr);
    } else {
      if (!arr.includes(ind)) {
        arr.push(ind);
        setTempArr(arr);
      }
    }
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  }
  // console.log("formvalues : ", formValues);
  // console.log("tempArr : ", tempArr);

  const usediCheckBox = (e) => {
    // console.log("usidCheckBox : ", e.target.checked);
    setIsUsediCodeCheck(e.target.checked)
    setFormValues({ ...formValues, ["usediCode"]: "" });
  }
  const usediCodeHandler = (e) => {
    // console.log("usediCodeHandler : ", e.target.value);
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    setFormValues({ ...formValues, ["usediCode"]: numericValue })
    setIsUsediCodeVerified("unchecked");
  }
  const goToCoursePage = async () => {
    // console.log("calling goToCoursePage : ", formValues);
    setIsLoader(true)
    const body = {
      "UserID": userId,
      "Gender": formValues.GenderPreference,
      "LanguageID": parseInt(formValues.LanguagePreference),
      "UDISECode": isUsediCodeCheck ? "" : formValues.usediCode,
      "StateID": isUsediCodeCheck ? parseInt(formValues.state) : 0,
      "DistrictID": isUsediCodeCheck ? parseInt(formValues.district) : 0,
      "SchoolID": isUsediCodeCheck ? parseInt(formValues.schoolName) : 0,
      "FullName": formValues.fullname,
      "Email": formValues.email,
      "Type": props.isProfile == true ? "org" : "",
      "CurrentState":formValues.CurrentState,
      "CurrentDistrict": formValues.CurrentDistrict,
      "city": formValues.city,
      "pincode":formValues.pincode
    }

    // console.log("body for submit data : ", body);
    const result = await axios.post(variables.API_URL + `api/SaveCandidateRegistrationDetails`, body);
    // console.log("result after submit api : ", result);
    if (result.data.isSuccess && result.data.errorMsg == "Candidate details submitted successfully") {
      if (props.isProfile) {
        // console.log("Candidate details submitted successfully you can reset the form");
        setFormValues({ ...formValues, "district": "" });
        setFormValues({ ...formValues, "state": "" });
        setFormValues({ ...formValues, "schoolName": "" });
        setFormValues({ ...formValues, "usediCode": "" });
        setIsUsediCodeCheck(false);
        setIsLoader(false)
      } else {
        setIsLoader(false)
        if (courseData.activeStage === 1) {
          navigate('/mycourses')
        } else {
          if (isDevice == "mobile") {
            navigate('/courseIndexMiddlePage')
          } else {
            navigate('/CourseIndex')
          }
        }

      }
    } else {
      setIsLoader(false)
      // console.log("error while submitting details");
    }
  }


  const checkDisableForm1 = () => {
    console.log("formValues inisde fun : ", formValues);
    const elements = document.querySelectorAll('.personal');
    console.log("elements : ", elements)
    let temp = false
    if (formValues.fullname.length < 3) {
      temp = true
    } else {
      let name = formValues.fullname;
      const firstThreeLetters = name && name.slice(0, 3);
      // console.log("firstThreeLetters : ", firstThreeLetters);
      const areFirstThreeLettersNotSpaces = firstThreeLetters && !firstThreeLetters.includes(' ');
      // console.log("areFirstThreeLettersNotSpaces : ", areFirstThreeLettersNotSpaces);
      if (!areFirstThreeLettersNotSpaces) {
        temp = true;
      }
      const firstLetter = name && name.slice(0, 1);
      let regexForSpecialCharacter = /^[a-zA-Z0-9]+$/;
      const areFirstThreeLettersAlphanumeric = firstLetter && regexForSpecialCharacter.test(firstLetter);
      // console.log("areFirstLetterAlphanumeric : ", areFirstThreeLettersAlphanumeric);
      if (!areFirstThreeLettersAlphanumeric) {
        temp = true
      }
    }

    if (valid == false) {
      console.log("returning true beacause email is not valid")
      temp = true
    }
    if(queIds.includes(12)){
      if(formValues.pincode?.length !=6){
        temp = true
      }
    }
   
    elements.forEach(element => {
      // Do something with each element
      // console.log("element.textContent : ", element.value);
      if (element.value == "") {
        console.log("element.value == empty thats why temp is true");
        temp = true
      }
    });
    console.log("final value of temp returning : ", temp);
    return temp

    // if (formValues.GenderPreference != "" && formValues.LanguagePreference != "") {
    //   return false
    // } else {
    //   return true
    // }
  }

  const checkDisableForm2 = () => {
    // console.log("formValues inisde checkDisableForm2 : ", formValues);
    const elements = document.querySelectorAll('.organisation');
    // console.log("elements : ", elements)
    let temp = false
    if (isUsediCodeCheck) {
      if (formValues.state == "" || formValues.district == "" || formValues.schoolName == "") {
        temp = true
        // console.log("chamging temp inside isUsediCodeCheck");
      }
    } else {
      if (formValues.usediCode.length < 10 || isUsediCodeVerified == "invalid" || isUsediCodeVerified == "unchecked") {
        // console.log("chamging temp inside formValues.usediCode.length < 10");
        temp = true
      }
    }
    elements.forEach(element => {
      // Do something with each element
      // console.log("element.textContent and isUsediCodeCheck : ", element.name, isUsediCodeCheck);
      if (element.value == "") {
        if (isUsediCodeCheck && element.name == "udise") {
          if (formValues.state == "" || formValues.district == "" || formValues.schoolName == "") {
            temp = true
            // console.log("chamging temp inside isUsediCodeCheck");
          } else {
            temp = false
          }
        } else {
          temp = true
        }
        // console.log("chamging temp inside elements.forEach");
      }
    });
    return temp

  }

  const checkDisableUsediInput = () => {
    // console.log("isUsediCodeCheck : isUsediCodeVerified = ", isUsediCodeCheck + " : " + isUsediCodeVerified)
    if (!isUsediCodeCheck) {
      // console.log("return false")
      return false
      // if(!isUsediCodeVerified){
      // } else {
      //   // console.log("return true")
      //   return true
      // }
    } else {
      // console.log("return true")
      return true
    }
  }

  const verifyUsediCode = async () => {
    // console.log("verifyUsediCode calling :");
    const result = await axios.post(variables.API_URL + `api/CheckUdiseCode`, {
      "UDISECODE": formValues.usediCode,
    })
    // console.log("result after checking udise code : ", result);
    if (result.data.isSuccess && result.data.returnMessage == "Success") {
      setIsUsediCodeVerified("valid");
    } else {
      // console.log("udise code is invalid or something went wrong")
      setIsUsediCodeVerified("invalid")
    }
  }
  // console.log("isUsediCodeVerified isUsediCodeVerified : ", isUsediCodeVerified)

  const nameHandler = (e) => {
    // console.log("nameHandler calling : ", e.target.value)
    setIsNameValidText(true);
    const nameRegex = /^[a-zA-Z !`-]+$/;
    if (e.target.value == "") {
      setFormValues({ ...formValues, ["fullname"]: "" })
    } else {
      if (e.target.value.match(nameRegex)) {
        setFormValues({ ...formValues, ["fullname"]: e.target.value })
      } else {
        setFormValues({ ...formValues, ["fullname"]: formValues.fullname })
      }
    }
  }
  const emailHandler = (e) => {
    // console.log("emailHandler calling : ", e.target.value)
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
    // console.log("isValidEmail : ", isValidEmail)
    setValid(isValidEmail);
    if (e.target.value == "") {
      setFormValues({ ...formValues, ["email"]: "" })
    } else {
      setFormValues({ ...formValues, ["email"]: e.target.value })
    }
  }
  const cityHandler = (e) => {
    const city = e.target.value;
    
    // Use regex to remove any numeric characters
    const textValue = city.replace(/[^a-zA-Z\s]/g, '');
  
    setFormValues({ ...formValues, city: textValue });
  };
  
  const pincodeHandler = (e) => {
    const pincode = e.target.value;

    // Use regex to allow only digits and ensure the length is up to 6
    const numericValue = pincode.replace(/\D/g, ''); // Remove non-digit characters

    if (numericValue.length <= 6) {
      setFormValues({ ...formValues, pincode: numericValue });
    }
  };

  return (
    <>
      {
        props.isProfile == true ? "" :
          <>
            <NavOne mobileView={true} ></NavOne>
          </>
      }
      {/*  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoader}

      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {isLoader && <CustumLoader message="Loading..." />}

      {/* organisation tab for edit profile */}
      {
        props.isProfile ?
          <>
            <div class="p-3 pt-4 pb-4 border-bottom">
              <h4 class=""><Translate textKey="postregform009" />
              </h4>
              <div class="deatils-drop mb-3">
                <div class="col-md-8 col-sm-12 mt-0">

                  <h6 class="bold-500 mb-3"><Translate textKey="postregform011" />
                  </h6>



                  <div class="col-md-12 ">
                    <div class="d-flex ">
                      <div class="input-group mb-1 d-flex w-75 me-2">
                        <input type="text" name="udise" value={formValues.usediCode} onChange={usediCodeHandler} class="form-control organisation" disabled={checkDisableUsediInput()} placeholder="Enter Code" ></input>

                      </div>
                      <button onClick={verifyUsediCode} disabled={formValues.usediCode.length > 9 ? false : true} class={formValues.usediCode.length > 9 ? "primary-btn" : "disabled-btn"}>{isUsediCodeVerified == "valid" ? "Verified" : isUsediCodeVerified == "unchecked" ? "Verify" : "invalid"}</button>
                    </div>

                    {/* error msg one */}
                    {
                      isUsediCodeVerified == "invalid" ?
                        <>
                          <div class="d-flex">
                            <div>
                              <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                            </div>
                            <div class="pt-2">
                              <p class="login-error h7 mb-0 align-self-center"><Translate textKey="postregform013" /></p>
                            </div>
                          </div>
                        </> : ""
                    }
                    {/* error msg two
                                    <div class="d-flex">
                                    <div>
                                      <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>

                                      </div>
                                      <div class="pt-2">
                                        <p class="login-error h7 mb-0 align-self-center">There was a connection break, please resbmit the code.
                                        // <Translate textKey="postregform014" /> 
                                        </p>
                                        </div>
                                    </div> */}


                    <div class="form-check mt-2">
                      <input class="form-check-input" onChange={usediCheckBox} checked={isUsediCodeCheck} type="checkbox" value="" id="defaultCheck1"></input>
                      <label class="form-check-label" for="defaultCheck1">
                        <Translate textKey="postregform012" />
                      </label>
                    </div>
                  </div>
                </div>

              </div>



              {
                isUsediCodeCheck ?
                  <>
                    <div class="details-box mb-4">
                      <h4 class=""><Translate textKey="postregform015" /></h4>
                      <div class="deatils-drop">
                        <div class="col-md-6 col-sm-12 mt-0">

                          <h6 class="bold-500"><Translate textKey="postregform017" />
                          </h6>


                          <div class="col-md-12">
                            <div class="dropdown-btn cursor-pointer dropdown-options">
                              <div class="select-dropdown cursor-pointer">
                                <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                  <option value=""> <Translate textKey="postregform016" />
                                    {/* <Translate textKey="postregform016" /> */}
                                  </option>
                                  {stateList.map((item) => {
                                    return (
                                      <>
                                        <option value={item.stateId}>
                                          {item.stateName}</option>
                                      </>
                                    )
                                  })}

                                  {/* <option value="Option 3">Two</option> */}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="details-box mb-4">
                      <h4 class=""><Translate textKey="postregform018" />
                        {/* <Translate textKey="postregform018" /> */}
                      </h4>
                      <div class="deatils-drop">
                        <div class="col-md-6 col-sm-12 mt-0">

                          <h6 class="bold-500"><Translate textKey="postregform020" />
                            {/* <Translate textKey="postregform020" /> */}
                          </h6>

                          <div class="col-md-12">
                            <div class="dropdown-btn cursor-pointer dropdown-options">
                              <div class="select-dropdown cursor-pointer">
                                <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                <select value={formValues.district} name="district" onChange={(e) => handleFormValues(e, "7")} class="cursor-pointer organisation text-truncate">
                                  <option value=""> <Translate textKey="postregform019" />
                                    {/* <Translate textKey="postregform019" /> */}
                                  </option>
                                  {districtList.map((item) => {
                                    return (
                                      <>
                                        <option value={item.districtId}>
                                          {item.districtName}</option>
                                      </>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="details-box mb-4 pb-3">
                      <h4 class=""><Translate textKey="postregform021" />
                        {/* <Translate textKey="postregform021" /> */}
                      </h4>
                      <div class="deatils-drop detailsdrop-padding mb-3">
                        <div class="col-md-6 col-sm-12 mt-0">

                          <h6 class="bold-500"><Translate textKey="postregform023" />
                            {/* <Translate textKey="postregform023" /> */}
                          </h6>

                          <div class="col-md-12">
                            <div class="dropdown-btn cursor-pointer dropdown-options mb-2">
                              <div class="select-dropdown cursor-pointer mb-3">
                                <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                <select value={formValues.schoolName} name="schoolName" onChange={(e) => handleFormValues(e, "8")} class="cursor-pointer organisation text-truncate">
                                  <option value=""> <Translate textKey="postregform022" />
                                    {/* <Translate textKey="postregform022" /> */}
                                  </option>
                                  {schoolList.map((item) => {
                                    return (
                                      <>
                                        <option value={item.schoolId}>
                                          {item.schoolName}</option>
                                      </>
                                    )
                                  })}

                                </select>
                                {/* <div class="pb-3">
                                    <h6 class="bold-500 mt-2">If your school name is not available in the provided list, contact our administrative team
                                      <Translate textKey="postregform024" /> 
                                </h6> */}

                              </div>

                              <div class="d-block mt-2 ">
                                <h6 class="bold-500"><Translate textKey="postregform024" />
                                  {/* <Translate textKey="postregform024" />  */}
                                </h6>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </> : ""
              }

              <div class="col-md-12 pb-3 text-center p-3 pb-4 mt-4 border-top">
                {/* <p class="mb-0"><Translate textKey="postregform028" /></p>
                      <p>
                        <Translate textKey="postregform029" />
                      </p> */}
                <div class="m-auto justify-content-center welcome-btns">
                  {
                    props.isProfile == true ?
                      "" :
                      <button onClick={() => formHandler("personal")} class="secondary-btn me-3 mb-3"><Translate textKey="postregform030" /></button>
                  }
                  <button onClick={goToCoursePage} disabled={checkDisableForm2()} class={checkDisableForm2() ? "disabled-btn" : "primary-btn"}>Submit</button>
                </div>
              </div>

            </div>
          </> :
          <>
            <div className={props.isProfile == true ? "content-cards bg-body box-one welcome-menu" : "col-lg-9 col-sm-12 col-xs-12 content-cards bg-body box-one welcome-menu"}>
              <div class={props.isProfile == true ? "p-0" : "pt-3"}>
                <div class="d-flex text-center m-auto justify-content-center welcome-heading pb-3 pt-3">
                  {
                    props.isProfile == true ?
                      "" :
                      <div class="me-3 border-bottom">
                        <img src={formType == "personal" ? "../../Assets/Linqskills_Icons/b-icons-24_Personal-filled.svg" : "../../Assets/Linqskills_Icons/b-icons-24_Personal-Default.svg"} class="cursor-pointer "></img>
                        <h6 class={formType == "personal" ? "active-text" : ""}> <Translate textKey="postregform001" />
                        </h6>
                      </div>
                  }
                  {
                    queIds.includes(3) ?
                      <>
                        <div class="me-3 border-bottom">
                          <img src={formType == "organisation" ? "../../Assets/Linqskills_Icons/b-icons-24_Organisation-filled.svg" : "../../Assets/Linqskills_Icons/b-icons-24_Organisation-Default.svg"} class="cursor-pointer"></img>

                          {/*for active <img src= class="cursor-pointer"></img> */}
                          <h6 class={formType == "organisation" ? "active-text" : ""}><Translate textKey="postregform002" />
                          </h6>
                        </div>
                      </> : ""
                  }
                </div>

                {
                  formType == "personal" ?
                    <>
                      <div>
                        {
                          queIds.includes(7) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="registration025" />
                                      {/* <Translate textKey="postregform009" /> */}
                                    </h5>
                                    <h6 class="bold-500"> <Translate textKey="postregform026" />
                                      {/* <Translate textKey="postregform011" /> */}
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" value={formValues.fullname} onChange={nameHandler} class="form-control personal" placeholderkey="registration026" ></input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    isNameValidText == false ?
                                      <>
                                        <div class="d-flex">
                                          <div>
                                            <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                          </div>
                                          <div class="pt-2">
                                            <p class="login-error h7 mb-0 align-self-center"></p>
                                          </div>
                                        </div>
                                      </> : ""
                                  }
                                </div>
                              </div>
                            </> : ""
                        }

                        {
                          queIds.includes(1) ?
                            <>
                              <div id='1' class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"> <Translate textKey="postregform003" />
                                    </h5>
                                    <h6 class="bold-500"><Translate textKey="postregform005" />
                                    </h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options">
                                        <div class="select-dropdown cursor-pointer">
                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select value={formValues.GenderPreference} onChange={(e) => handleFormValues(e, "1")}
                                            name="GenderPreference" class="cursor-pointer personal text-truncate">
                                            <option value="" class=""> <Translate textKey="postregform004" /></option>
                                            <option value="M">
                                              Male</option>
                                            <option value="F">Female</option>
                                            <option value="O">Others</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes(2) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="postregform006" />
                                    </h5>
                                    <h6 class="bold-500"><Translate textKey="postregform008" />
                                    </h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options">
                                        <div class="select-dropdown cursor-pointer">
                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select value={formValues.LanguagePreference} onChange={(e) => handleFormValues(e, "2")}
                                            name="LanguagePreference" class="cursor-pointer personal text-truncate">

                                            <option value=""> <Translate textKey="postregform007" /></option>
                                            {languageList.map((item) => {
                                              return (
                                                <>
                                                  <option value={item.languageId}>
                                                    {item.languageName}</option>
                                                </>
                                              )
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        }

                        {
                          queIds.includes(8) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="registration006" />
                                      {/* <Translate textKey="registration006" /> */}
                                    </h5>
                                    <h6 class="bold-500"><Translate textKey="postregform027" />
                                      {/* <Translate textKey="postregform027" /> */}
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" value={formValues.email} onChange={emailHandler} class="form-control personal" placeholderkey="registration026" ></input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        }
                        {queIds.includes(9) ? <>  <div class="details-box mb-4 border">
                          <div class="deatils-drop">
                            <div class="col-md-6 lecture-details p-3">
                              <h5 class="bold-500 mb-3"><Translate textKey="postregform016" />
                              </h5>
                              <h6 class="bold-500"><Translate textKey="postregform015" />
                              </h6>
                            </div>
                            <div class="col-md-6 p-3">
                              <div class="col-md-12">
                                <div class="dropdown-btn cursor-pointer dropdown-options">
                                  <div class="select-dropdown cursor-pointer">
                                    <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                    <select value={formValues.ResidingState} onChange={(e) => handleFormValues(e, "9")}
                                      name="CurrentState" class="cursor-pointer personal text-truncate">

                                      <option value=""> <Translate textKey="postregform015" /></option>
                                      {stateList.map((item) => {
                                        return (
                                          <>
                                            <option value={item.stateId}>
                                              {item.stateName}</option>
                                          </>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </> : ""}
                        {queIds.includes(10) ? <>  <div class="details-box mb-4 border">
                          <div class="deatils-drop">
                            <div class="col-md-6 lecture-details p-3">
                              <h5 class="bold-500 mb-3"><Translate textKey="postregform019" />
                              </h5>
                              <h6 class="bold-500"><Translate textKey="postregform018" />
                              </h6>
                            </div>
                            <div class="col-md-6 p-3">
                              <div class="col-md-12">
                                <div class="dropdown-btn cursor-pointer dropdown-options">
                                  <div class="select-dropdown cursor-pointer">
                                    <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                    <select value={formValues.CurrentDistrict} onChange={(e) => handleFormValues(e, "9")}
                                      name="CurrentDistrict" class="cursor-pointer personal text-truncate">

                                      <option value=""> <Translate textKey="postregform018" /></option>
                                      {districtList.map((item) => {
                                        return (
                                          <>
                                            <option value={item.districtId}>
                                              {item.districtName}</option>
                                          </>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </> : ""}
                        {
                          queIds.includes(11) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="city" />
                                      {/* <Translate textKey="postregform009" /> */}
                                    </h5>
                                    <h6 class="bold-500"> <Translate textKey="enter city name" />
                                      {/* <Translate textKey="postregform011" /> */}
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" value={formValues.city} onChange={cityHandler} class="form-control personal" placeholderkey="city" ></input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes(12) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3"><Translate textKey="pincode" />
                                      {/* <Translate textKey="postregform009" /> */}
                                    </h5>
                                    <h6 class="bold-500"> <Translate textKey="enter pincode" />
                                      {/* <Translate textKey="postregform011" /> */}
                                    </h6>

                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12 ">
                                      <div class="d-flex">
                                        <div class="input-group mb-1 d-flex w-75 me-2">
                                          <input type="text" inputMode="numeric" pattern="\d{0,6}" value={formValues.pincode} onChange={pincodeHandler} class="form-control personal" placeholderkey="pincode" ></input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes(33) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3">Current Working Status

                                    </h5>
                                    <h6 class="bold-500"><Translate textKey="postregform005" />

                                    </h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options">
                                        <div class="select-dropdown cursor-pointer">
                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select onChange={(e) => handleFormValues(e, "3")}
                                            name="CurrentWorkingStatus" class="cursor-pointer personal text-truncate">
                                            <option value=""> Select your current position</option>
                                            <option value="Option 2">
                                              One</option>
                                            <option value="Option 3">Two</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes(44) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3">Current Job Position</h5>
                                    <h6 class="bold-500"><Translate textKey="postregform005" /></h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options">
                                        <div class="select-dropdown cursor-pointer">


                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select onChange={(e) => handleFormValues(e, "4")}
                                            name="CurrentJobPosition" class="cursor-pointer text-truncate">
                                            <option value=""> Select your current position</option>
                                            <option value="Option 2">
                                              One</option>
                                            <option value="Option 3">Two</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes(55) ?
                            <>
                              <div class="details-box mb-4 border">
                                <div class="deatils-drop">
                                  <div class="col-md-6 lecture-details p-3">
                                    <h5 class="bold-500 mb-3">Select the Country</h5>
                                    <h6 class="bold-500">Choose the service provider to establish a connection for the online meeting.</h6>
                                  </div>
                                  <div class="col-md-6 p-3">
                                    <div class="col-md-12">
                                      <div class="dropdown-btn cursor-pointer dropdown-options">
                                        <div class="select-dropdown cursor-pointer">
                                          <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                          <select onChange={(e) => handleFormValues(e, "5")}
                                            name="SelectTheCountry" class="cursor-pointer text-truncate">
                                            <option value="" class=""> Select The Country

                                            </option>
                                            <option value=""> INDIA</option>
                                            <option value="Option 2">One</option>
                                            <option value="Option 3">Two</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : ""
                        }
                        {
                          queIds.includes(3) ?
                            <div class="col-md-12 pb-4 text-center">
                              <button disabled={checkDisableForm1()} onClick={() => formHandler("organisation")} class={checkDisableForm1() ? "disabled-btn" : "primary-btn"}>Proceed Next</button>
                            </div> :
                            <div class="col-md-12 pb-4 text-center">
                              <button onClick={goToCoursePage} disabled={checkDisableForm1()} class={checkDisableForm1() ? "disabled-btn" : "primary-btn"}><Translate textKey="postregform031" /></button>
                            </div>
                        }

                      </div>
                    </>
                    : formType == "organisation" ?
                      <>
                        <div class="organisation-tabs">
                          {
                            queIds.includes(13) ?
                              <>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform017" />
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform016" />
                                                {/* <Translate textKey="postregform016" /> */}
                                              </option>
                                              {HighestQualificationArray.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.id}>
                                                      {item.value}</option>
                                                  </>
                                                )
                                              })}
                                              {/* <option value="Option 3">Two</option> */}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </> : ""
                          }

                          {
                            queIds.includes(14) ?
                              <>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform017" />
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform016" />
                                                {/* <Translate textKey="postregform016" /> */}
                                              </option>
                                              {CurrentDesignation.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.id}>
                                                      {item.value}</option>
                                                  </>
                                                )
                                              })}
                                              {/* <option value="Option 3">Two</option> */}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </> : ""
                          }

                          {
                            queIds.includes(3) ?
                              <>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform009" />
                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform011" />
                                      </h6>

                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12 ">
                                        <div class="d-flex">
                                          <div class="input-group mb-1 d-flex w-75 me-2">
                                            <input type="text" name="udise" value={formValues.usediCode} onChange={usediCodeHandler} class="form-control organisation" disabled={checkDisableUsediInput()} placeholder="Enter Code" ></input>

                                          </div>
                                          <button onClick={verifyUsediCode} disabled={formValues.usediCode.length > 9 ? false : true} class={formValues.usediCode.length > 9 ? "primary-btn" : "disabled-btn"}>{isUsediCodeVerified == "valid" ? "Verified" : isUsediCodeVerified == "unchecked" ? "Verify" : "invalid"}</button>
                                        </div>

                                        {/* error msg one */}
                                        {
                                          isUsediCodeVerified == "invalid" ?
                                            <>
                                              <div class="d-flex">
                                                <div>
                                                  <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>
                                                </div>
                                                <div class="pt-2">
                                                  <p class="login-error h7 mb-0 align-self-center"><Translate textKey="postregform013" /></p>
                                                </div>
                                              </div>
                                            </> : ""
                                        }
                                        {/* error msg two
                                    <div class="d-flex">
                                    <div>
                                      <img src="../../Assets/Linqskills_Icons/LoginError.svg" class=""></img>

                                      </div>
                                      <div class="pt-2">
                                        <p class="login-error h7 mb-0 align-self-center">There was a connection break, please resbmit the code.
                                        // <Translate textKey="postregform014" /> 
                                        </p>
                                        </div>
                                    </div> */}


                                        <div class="form-check">
                                          <input class="form-check-input" onChange={usediCheckBox} checked={isUsediCodeCheck} type="checkbox" value="" id="defaultCheck1"></input>
                                          <label class="form-check-label" for="defaultCheck1">
                                            <Translate textKey="postregform012" />
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </> : ""
                          }

                          {
                            isUsediCodeCheck ?
                              <>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform017" />
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform016" />
                                                {/* <Translate textKey="postregform016" /> */}
                                              </option>
                                              {stateList.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.stateId}>
                                                      {item.stateName}</option>
                                                  </>
                                                )
                                              })}

                                              {/* <option value="Option 3">Two</option> */}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform018" />
                                        {/* <Translate textKey="postregform018" /> */}
                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform020" />
                                        {/* <Translate textKey="postregform020" /> */}
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.district} name="district" onChange={(e) => handleFormValues(e, "7")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform019" />
                                                {/* <Translate textKey="postregform019" /> */}
                                              </option>
                                              {districtList.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.districtId}>
                                                      {item.districtName}</option>
                                                  </>
                                                )
                                              })}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="details-box mb-4 border">
                                  <div class="deatils-drop detailsdrop-padding">
                                    <div class="col-md-6 lecture-details p-3">
                                      <h5 class="bold-500 mb-3"><Translate textKey="postregform021" />
                                        {/* <Translate textKey="postregform021" /> */}
                                      </h5>
                                      <h6 class="bold-500"><Translate textKey="postregform023" />
                                        {/* <Translate textKey="postregform023" /> */}
                                      </h6>
                                    </div>
                                    <div class="col-md-6 p-3 pb-5 pt-0">
                                      <div class="col-md-12">
                                        <div class="dropdown-btn cursor-pointer dropdown-options">
                                          <div class="select-dropdown cursor-pointer">
                                            <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                            <select value={formValues.schoolName} name="schoolName" onChange={(e) => handleFormValues(e, "8")} class="cursor-pointer organisation text-truncate">
                                              <option value=""> <Translate textKey="postregform022" />
                                                {/* <Translate textKey="postregform022" /> */}
                                              </option>
                                              {schoolList.map((item) => {
                                                return (
                                                  <>
                                                    <option value={item.schoolId}>
                                                      {item.schoolName}</option>
                                                  </>
                                                )
                                              })}

                                            </select>
                                            {/* <div class="pb-3">
                                    <h6 class="bold-500 mt-2">If your school name is not available in the provided list, contact our administrative team
                                      <Translate textKey="postregform024" /> 
                                </h6> */}

                                          </div>

                                          <div class="d-block mt-2">
                                            <h6 class="bold-500"><Translate textKey="postregform024" />
                                              {/* <Translate textKey="postregform024" />  */}
                                            </h6>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </> : ""
                          }

                          {
                            queIds.includes(3) ?
                              <>
                                {
                                  queIds.includes(15) ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" />
                                                      {/* <Translate textKey="postregform016" /> */}
                                                    </option>
                                                    {ClassesYouAreCurrentlyTeaching.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    {/* <option value="Option 3">Two</option> */}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </> : ""
                                }

                                {
                                  queIds.includes(16) ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" />
                                                      {/* <Translate textKey="postregform016" /> */}
                                                    </option>
                                                    {SubjectsYouAreCurrentlyInstructing.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    {/* <option value="Option 3">Two</option> */}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </> : ""
                                }
                              </> :
                              <>
                                {
                                  queIds.includes(17) ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3">College Name
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" />
                                                      {/* <Translate textKey="postregform016" /> */}
                                                    </option>
                                                    {CollegeNameArray.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    {/* <option value="Option 3">Two</option> */}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </> : ""
                                }
                                {
                                  queIds.includes(18) ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3">Department
                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" />
                                                      {/* <Translate textKey="postregform016" /> */}
                                                    </option>
                                                    {DepartmentArray.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    {/* <option value="Option 3">Two</option> */}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </> : ""
                                }
                                {
                                  queIds.includes(19) ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" />
                                                      {/* <Translate textKey="postregform016" /> */}
                                                    </option>
                                                    {Semester.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    {/* <option value="Option 3">Two</option> */}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </> : ""
                                }

                                {
                                  queIds.includes(20) ?
                                    <>
                                      <div class="details-box mb-4 border">
                                        <div class="deatils-drop">
                                          <div class="col-md-6 lecture-details p-3">
                                            <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

                                            </h5>
                                            <h6 class="bold-500"><Translate textKey="postregform017" />
                                            </h6>
                                          </div>
                                          <div class="col-md-6 p-3">
                                            <div class="col-md-12">
                                              <div class="dropdown-btn cursor-pointer dropdown-options">
                                                <div class="select-dropdown cursor-pointer">
                                                  <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
                                                  <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
                                                    <option value=""> <Translate textKey="postregform016" />
                                                      {/* <Translate textKey="postregform016" /> */}
                                                    </option>
                                                    {OngoingPassedArray.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item.id}>
                                                            {item.value}</option>
                                                        </>
                                                      )
                                                    })}
                                                    {/* <option value="Option 3">Two</option> */}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </> : ""
                                }
                              </>
                          }

                          <div class="col-md-12 pb-3 text-center p-3 pt-0 pb-4 mt-3">
                            <p class="mb-0"><Translate textKey="postregform028" /></p>
                            <p>
                              <Translate textKey="postregform029" />
                            </p>
                            <div class="m-auto justify-content-center welcome-btns">
                              {
                                props.isProfile == true ?
                                  "" :
                                  <button onClick={() => formHandler("personal")} class="secondary-btn me-3 mb-3"><Translate textKey="postregform030" /></button>
                              }
                              <button onClick={goToCoursePage} disabled={checkDisableForm2()} class={checkDisableForm2() ? "disabled-btn" : "primary-btn"}><Translate textKey="postregform031" /></button>
                            </div>
                          </div>
                        </div>
                      </>
                      : ""
                }
                {
                  queIds.includes(1) ?
                    <>
                    </> : ""
                }
              </div>
            </div>
          </>
      }
    </>
  )

}



// {
//   queIds.includes(14) ?
//     <>
//       <div class="details-box mb-4 border">
//         <div class="deatils-drop">
//           <div class="col-md-6 lecture-details p-3">
//             <h5 class="bold-500 mb-3"><Translate textKey="postregform015" />

//             </h5>
//             <h6 class="bold-500"><Translate textKey="postregform017" />
//             </h6>
//           </div>
//           <div class="col-md-6 p-3">
//             <div class="col-md-12">
//               <div class="dropdown-btn cursor-pointer dropdown-options">
//                 <div class="select-dropdown cursor-pointer">
//                   <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
//                   <select value={formValues.state} name="state" onChange={(e) => handleFormValues(e, "6")} class="cursor-pointer organisation text-truncate">
//                     <option value=""> <Translate textKey="postregform016" />
//                       {/* <Translate textKey="postregform016" /> */}
//                     </option>
//                     {CurrentDesignation.map((item) => {
//                       return (
//                         <>
//                           <option value={item.id}>
//                             {item.value}</option>
//                         </>
//                       )
//                     })}
//                     {/* <option value="Option 3">Two</option> */}
//                   </select>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </> : ""
// }

// {
//   queIds.includes(88) ?
//     <>
//       <div class="details-box mb-4 border">
//         <div class="deatils-drop">
//           <div class="col-md-6 lecture-details p-3">
//             <h5 class="bold-500 mb-3">Which Subjects do you teach</h5>
//             <h6 class="bold-500"> <Translate textKey="postregform005" />
//             </h6>
//           </div>
//           <div class="col-md-6 p-3">
//             <div class="col-md-12">
//               <div class="dropdown-btn cursor-pointer dropdown-options">
//                 <div class="select-dropdown cursor-pointer">
//                   <img src="../../Assets/Linqskills_Icons/DropdownArrow-purple.svg" class="float-end arrow-down cursor-pointer"></img>
//                   <select class="cursor-pointer text-truncate">
//                     <option value="Option 1"> Select your current position</option>
//                     <option value="Option 2">
//                       One</option>
//                     <option value="Option 3">Two</option>
//                   </select>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </> : ""
// }