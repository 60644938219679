




import React, { useEffect, useState, useRef } from "react";
import { Redirect, useSearchParams } from "react-router-dom";
import TrainerMessage from '../../components/CourseIndex/TrainerMessage.js';
import Translate from '../Shared/Translate';
import Cookies from 'universal-cookie';
import SessionHeading from './SessionHeading';
import TopicHeader from './TopicHeader';
import Certificate from '../Shared/Certificate';
import { variables } from '../Shared/Variables'
import Accordion from "../Shared/Accordion.js";

//import PopupOne from '../Shared/PopupOne';

export default function EligibilityCheckContent(props) {

    const [data, setData] = useState([])
    const [isLoader, setIsLoader] = useState(false);
    var coursedata = JSON.parse(sessionStorage.getItem('coursedata'));
    const getData = async () => {
        var UserId=sessionStorage.getItem("login-userId");
        setIsLoader(true)
        const data = await fetch(variables.API_URL + 'api/GetEGATests', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                UserId: UserId,              
                ProjectId: coursedata.projectId,
                CourseId: coursedata.courseId,
                lessionplanid: coursedata.lessionPlanId,
                CourseNodeId: coursedata.courseNodeId
                
               
            
        })
        })
        const res = await data.json();
        const result = res.dataList  
        // console.log("result : ",result);       
        setData(result)     
        setIsLoader(false);
    }
    const shouldLog = useRef(true);
    useEffect(() => {
        if (shouldLog.current) {  //added for useeffect calling function twice
            shouldLog.current = false;
            getData();
        }
    },[])

        

    return (
       
            <div class={`col-lg-9 col-sm-12 col-xs-12 content-cards ${props.showMainDiv ? "sas" : "d-none"}`}>

                 <TrainerMessage pageName="Eligibility" text="Before you begin, watch the video on EGA flow" title="eligibility001" />
               
                <Accordion courseId={coursedata.courseId} isLoader={isLoader} courseName={coursedata.courseName} courseNodeId={coursedata.courseNodeId} lessionplanid={coursedata.lessionPlanId} data={data} />
         

            </div>
        
    )

}

  {/* <SessionHeading />*/}
{/*  <TopicHeader/>*/ }
{/*  <Certificate/> */ }




