import React, { useState, useEffect, useRef } from "react";
import Translate from "../Shared/Translate";
import Cookies from "universal-cookie";
import { Modal } from 'react-bootstrap';
import axios from "axios";
import { variables } from '../Shared/Variables';
import { updateLanguage,isLanguageUpdated } from "../../features/Language/languageSlice";
import { updateChild, updategrandChild, updateggChild } from "../../features/breadcrumb/breadcrumbSlice";
import { current } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";


export default function LanguagePopup(props) {

  /* 
  
  There are total three language parameters
  
  1. prefferedLanguage - (present in session storage) ==> refers to language in database
  2. language - (present in cookies ) ==> manipulates the language in applicaton level
  3. the same language value is also present in redux. Use as necessary.
  
  -> If you choose the radio button and click ok in language popup. 
    you set the language parameter in application level.
    (same language value is present in cookie and redux)
   Your application displays content in the same language.
   if content is not available in that language, then it will display in preffered language that is stored in session storage
   in case preffered language is also not available, content will be shown in english/default
  
   -> if you check the box and click ok in language popup . 
      you set the preffered language in database.
      application will run accordingly.
  */
  const dispatch = useDispatch();
  const cookies = new Cookies();
  var Lan = cookies.get("language");
  Lan = Lan != undefined ? Lan : "EN";
  const [selected, setSelected] = useState(cookies.get("language"));
  const [isValSelected, setIsValSelected] = useState("");
  const [language, setValue] = useState(Lan);
  const [isChecked, setIsChecked] = useState(false);

  //for dynamic content lan
  const [contentLanguages, setContentLanguages] = useState([]);
  const [selectedContentLan, setSelectedContentLan] = useState(props.selectedLanguageId);
  const isFromTopicContent = useState(props.isFromTopicContent == undefined ? false : props.isFromTopicContent);
  const shouldLog = useRef(true);

  // console.log("props.availableLanguages : ",props.availableLanguages);

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      if (props.ContentLanguages != "" && props.ContentLanguages != undefined) {
        if (props.ContentLanguages.indexOf(',') !== -1) {
          setContentLanguages(props.ContentLanguages.split(','));
        } else {
          setContentLanguages(current => [...current, props.ContentLanguages]);
        }
      }
    }
  }, []);

  const translate = (language) => {
    let translation = '';
    switch (language) {
      case 'English':
        translation = 'English';
        break;
      case 'Hindi':
        translation = 'हिंदी';
        break;
      case 'Tamil':
        translation = 'தமிழ்';
        break;
      default:
        break;
    }
    return translation;
  };

  //// console.log(props.data,"data props")
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleChange = (event) => {

    setValue(event.target.value);
    setSelected(event.target.value);
    setIsValSelected(event.target.value);
    
    // cookies.set("language", event.target.value, { path: "/" });

  };
  const languageValueHandleForReg = (id) => {
     // console.log("languageValueHandleForReg calling : ",props.formValues)
     // console.log("id of language : ",id)
     if(id == "Hindi"){
      // sessionStorage.setItem("prefferedLanguage","Hindi");
      // cookies.set('language',"Hindi")
      props.setMainLangId(2);
      // props.setFormValues({ ...props.formValues, "languageId": 2 })
     } else if(id == "English"){
      // sessionStorage.setItem("prefferedLanguage","English");
      // cookies.set('language',"English")
      props.setMainLangId(1);
      // props.setFormValues({ ...props.formValues, "languageId": 1 })
     }
  }
  const handleContentLanChange = (event) => {
    setSelectedContentLan(event.target.value);
    props.setTestLanguage(event.target.value);
    setIsChecked(true);
    sessionStorage.setItem("userSelectedTestLan", event.target.value);


  };

  /* convert language to language id */
  const languageToIdMap = {
    'English': 1,
    'Hindi': 2,
    'Tamil': 3,
    'Bengali': 4,
    'Telugu': 5,
    'Odiya': 6
  };

  const convertLanguageToId = (languageName) => {
    const languageId = languageToIdMap[languageName];
    return languageId ? languageId : 0; // If languageName is not found in the map, return 0 as a default value.
  };

  
  /* we saved node data and subnode data in session storage. we are fetching the data so that we can change breadcrumb according to language */
  const nodeData = JSON.parse(sessionStorage.getItem("nodeData"));
  const subNodeData = JSON.parse(sessionStorage.getItem("subNodeData"))
  const topicData = JSON.parse(sessionStorage.getItem("topicDetails"));
  // console.log("topicData in lang popup : ",topicData)
  const handleOK = (event) => {
    if(props.loginLangPopUp){
      cookies.set("language", language, { path: "/" });
      sessionStorage.setItem("prefferedLanguage", language)
      languageValueHandleForReg(language)
    }
    if(props.forRegVideo == "true"){
      cookies.set("language", language, { path: "/" });
      sessionStorage.setItem("prefferedLanguage", language)
      languageValueHandleForReg(language)
    }
    /* update  breadcrumb on language change */
    // console.log("language in ok : ",language)
    // console.log("selected in ok : ",selected)
    dispatch(updateLanguage(language))
    dispatch(isLanguageUpdated(true))
    if (props.extendedBC) {
      // console.log("in extended bc ............")
      /* 
      language translation of breadcrumb last node should be fixed */
      if (topicData[`nodeName_${cookies.get("language")}`] != undefined) {
        const breadcrumbLastNode = topicData[`nodeName_${cookies.get("language")}`]
        dispatch(updateggChild(breadcrumbLastNode))
      }
      // // console.log("breadcrumbLastNode:" , breadcrumbLastNode)
      dispatch(updateChild(nodeData != undefined ? nodeData[`nodeTitle_${language}`] : 'Content'))
      dispatch(updategrandChild(subNodeData[`nodeName_${language}`]))
    }
    sessionStorage.setItem("prefferedLanguage", selected)
    cookies.set("language", language, { path: "/" });
    updatePrefferedLanguage();
    // if (isChecked) {
    //   // props.closeEvent()
    // }
    //cookies.set("default-language", language, { path: "/" });
  };

  const handleClosePopUp = () => {
    // console.log("calling close popup Func")
    props.closeEvent();
  }

  // // console.log("contentLanguages.length : ",contentLanguages.length)

  const updatePrefferedLanguage = async () => {
    // // console.log("function called for updating default lang in db")
    const lan = await axios.post(variables.API_URL + 'api/UpdatePreferredLanguage', {
      userId: sessionStorage.getItem("login-userId"),
      languageId: convertLanguageToId(language)
    })
    // console.log("lan: ", lan)

  }

  // console.log("contentLanguages : ", contentLanguages);

  return (
    <>
      {
        props.type == "center" ?
          <>
            <div className='popup-lamguage-modal' >
              <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='couponAppliedpop'
                backdrop={false}
              >
                <Modal.Body>
                  {contentLanguages.length > 0 ?
                    <div className="popup-center  text-lg-left select-medium">
                      <div className="row">
                        <h6 class="mb-3">{props.title}</h6>

                        <div className="d-flex mb-3">
                          <div>
                            <img
                              src="../../Assets/Linqskills_Icons/globe.svg"
                              className="me-1"
                            ></img>
                          </div>
                          <div>
                            <span className="m-0 h7 align-self-center">
                              {props.header}
                            </span>
                          </div>
                        </div>

                      </div>
                      {
                        contentLanguages.map((lan, index) => {
                          const lanResult = lan.split('-');
                          return (
                            <div className="ps-4">
                              <div className="form-check mb-2 ">
                                <div class="label-width">
                                  <label
                                    className="form-check-label form-check-active cursor-pointer"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    <input
                                      className="form-check-input me-2 cursor-pointer"
                                      type="radio"
                                      name="flexRadioDefault"
                                      checked={parseInt(sessionStorage.getItem("userSelectedTestLan")) == lanResult[0]}
                                      onChange={handleContentLanChange}
                                      value={lanResult[0]}
                                      id="flexRadioDefault1"
                                    ></input>
                                    {lanResult[1]}
                                  </label>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        )
                      }

                      <div className="d-flex float-end mt-3"  >

                        <h6
                          className="pe-3 cta-ok cursor-pointer"
                          onClick={() => {
                            // handleOK();
                            props.okEvent();
                          }}
                        >
                          <Translate textKey="registration015" />
                        </h6>

                        <h6 className="cursor-pointer" onClick={props.closeEvent}>
                          <Translate textKey="registration016" />
                        </h6>
                      </div>
                    </div>
                    :
                    /* language popup to select vedio language */
                    <div className="popup-center  text-lg-left select-medium" >
                      <div className="row">
                        <h6 class="mb-3">{props.title != "" && props.title != undefined ? props.title : "Select Language"}</h6>
                        {/*<div className="d-flex mb-4">*/}
                        {/*    <h6 className="m-0">*/}
                        {/*        <Translate textKey="registration001" />*/}
                        {/*    </h6>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex mb-3">*/}
                        {/*    <img src="../../Assets/Linqskills_Icons/ph_globe.svg" className="me-2"></img>*/}
                        {/*    <p className="h7 m-0">*/}
                        {/*        <Translate textKey="registration002" />*/}
                        {/*    </p>*/}
                        {/*</div>*/}

                        <div className="d-flex mb-3">
                          <div>
                            <img
                              src="../../Assets/Linqskills_Icons/globe.svg"
                              className="me-1"
                            ></img>
                          </div>
                          <div>
                            <span className="m-0 h7 align-self-center">
                              {
                                props.header != "" && props.header != undefined ? props.header :
                                  <Translate textKey={props.headerKey} />
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ps-4" >
                        <div className="form-check mb-2 ">
                          <div class="label-width">
                            <label
                              className="form-check-label form-check-active cursor-pointer"
                              htmlFor="flexRadioDefault1"
                            >
                              <input
                                className="form-check-input me-2 cursor-pointer"
                                type="radio"
                                name="flexRadioDefault"
                                checked={selected === "English"}
                                onChange={handleChange}
                                value="English"
                                id="flexRadioDefault1"
                              ></input>
                              English
                            </label>
                          </div>
                        </div>
                        <div className="form-check mb-2 d-flex">
                          <div class="label-width">


                            <label
                              className="form-check-label cursor-pointer mt-1"
                              htmlFor="flexRadioDefault2"
                            >
                              <input
                                className="form-check-input me-2 cursor-pointer"
                                type="radio"
                                name="flexRadioDefault"
                                checked={selected === "Hindi"}
                                onChange={handleChange}
                                value="Hindi"
                                id="flexRadioDefault2"
                              ></input>
                              हिन्दी
                              {/* <Translate textKey="Language002" />*/}
                            </label>
                          </div>
                          <div class="language-translation ">
                            <label class="m-0">Hindi</label>
                          </div>
                        </div>
                      </div>
                      <div class="row ps-3 pt-3">
                        <div class="form-check">
                          <input
                            class="form-check-input mt-1 me-2"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          ></input>
                          <label
                            class="form-check-label bold-500 cursor-pointer"
                            for="defaultCheck1"
                          >
                            Set this option as my default language
                          </label>
                        </div>
                      </div>

                      <div className="d-flex float-end mt-3">
                        {props.forRegVideo ? (
                          <h6
                            className="pe-3 cta-ok cursor-pointer"
                            onClick={() => {

                              props.okEvent();
                              handleOK();

                            }}
                          >
                            <Translate textKey="registration015" />
                          </h6>
                        ) : (
                          <button
                            disabled={props.isUserNew && isChecked == false ? true : false}
                            className={props.isUserNew && isChecked == false ? "ok-disabled pe-3 ok-btn" : "pe-3 cta-ok cursor-pointer ok-btn"} 
                            onClick={() => {
                              handleOK();
                              props.okEvent();

                            }}
                          >
                            <Translate textKey="registration015" />
                          </button>
                        )}
                        {
                          props.isUserNew ? <></> : 
                          <>
                          <h6 className="cursor-pointer" onClick={props.closeEvent}>
                          <Translate textKey="registration016" />
                        </h6>
                          </> 
                        }
                        
                      </div>
                    </div>
                  }
                </Modal.Body>
              </Modal>
            </div>
          </> :

          <div className="row m-2"  >
            {/* language popup in eligibility course index page */}
            <div className="popup-one text-lg-left select-medium" >
              {/*<img src="../../Assets/Linqskills_Icons/popup-close.svg" className="float-end cross-icon2" onClick={props.closeEvent}></img>*/}
              <div className="row">
                <h6 class="mb-3">{props.title != "" && props.title != undefined ? props.title : "Select Language"}</h6>
                {/*<div className="d-flex mb-4">*/}
                {/*    <h6 className="m-0">*/}
                {/*        <Translate textKey="registration001" />*/}
                {/*    </h6>*/}
                {/*</div>*/}
                {/*<div className="d-flex mb-3">*/}
                {/*    <img src="../../Assets/Linqskills_Icons/ph_globe.svg" className="me-2"></img>*/}
                {/*    <p className="h7 m-0">*/}
                {/*        <Translate textKey="registration002" />*/}
                {/*    </p>*/}
                {/*</div>*/}

                <div className="d-flex mb-3">
                  <div>
                    <img
                      src="../../Assets/Linqskills_Icons/globe.svg"
                      className="me-1"
                    ></img>
                  </div>
                  <div>
                    <span className="m-0 h7 align-self-center">
                      {
                        props.header != "" && props.header != undefined ? props.header :
                          <Translate textKey={props.headerKey} />
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="ps-4" >
                {
                  props.loginPage &&
                  <>
                    <div className="form-check mb-2 ">
                      <label
                        className="form-check-label form-check-active cursor-pointer"
                        htmlFor="flexRadioDefault1"
                      >
                        <input
                          className="form-check-input me-2 cursor-pointer"
                          type="radio"
                          name="flexRadioDefault"
                          checked={selected === "English"}
                          onChange={handleChange}
                          value="English"
                          id="flexRadioDefault1"
                        ></input>
                        English
                      </label>
                    </div>
                    <div className="form-check mb-2 d-flex">
                      <div class="label-width">
                        <label
                          className="form-check-label cursor-pointer mt-1"
                          htmlFor="flexRadioDefault2"
                        >
                          <input
                            className="form-check-input me-2 cursor-pointer"
                            type="radio"
                            name="flexRadioDefault"
                            checked={selected === "Hindi"}
                            onChange={handleChange}
                            value="Hindi"
                            id="flexRadioDefault2"
                          ></input>

                          हिन्दी

                          {/* <Translate textKey="Language002" />*/}
                        </label>
                      </div>
                      <div class="language-translation">
                        <label class="m-0">Hindi</label>
                      </div>
                    </div>
                  </>
                }
                {props.availableLanguages?.length > 0 ?
                  <>
                    {props.availableLanguages.map((lan) => {
                      const lang = lan.trim()
                      return (
                        <div className="form-check mb-2 d-flex">
                          <div class="label-width">

                            <label
                              className="form-check-label cursor-pointer"
                              htmlFor={`flexRadioDefault_${lang}`}
                            >
                              <input
                                className="form-check-input me-2 cursor-pointer"
                                type="radio"
                                name="flexRadioDefault"
                                checked={selected === lang}
                                onChange={handleChange}
                                value={lang}
                                id={`flexRadioDefault_${lang}`}
                              ></input>

                              {translate(lang)}
                            </label>
                          </div>
                          {lan != "English" && <div class="language-translation ms-5">
                            <label class="m-0" htmlFor={`flexRadioDefault_${lang}`}>{lan}</label>
                          </div>}

                        </div>
                      )
                    })}
                  </> :
                  <>
                    <div className="form-check mb-2 ">
                      <label
                        className="form-check-label form-check-active cursor-pointer"
                        htmlFor="flexRadioDefault1"
                      >
                        <input
                          className="form-check-input me-2 cursor-pointer"
                          type="radio"
                          name="flexRadioDefault"
                          checked={selected === "English"}
                          onChange={handleChange}
                          value="English"
                          id="flexRadioDefault1"
                        ></input>
                        English
                      </label>
                    </div>
                    <div className="form-check mb-2 d-flex">
                      <div class="label-width">
                        <label
                          className="form-check-label cursor-pointer mt-1"
                          htmlFor="flexRadioDefault2"
                        >
                          <input
                            className="form-check-input me-2 cursor-pointer"
                            type="radio"
                            name="flexRadioDefault"
                            checked={selected === "Hindi"}
                            onChange={handleChange}
                            value="Hindi"
                            id="flexRadioDefault2"
                          ></input>

                          हिन्दी

                          {/* <Translate textKey="Language002" />*/}
                        </label>
                      </div>
                      <div class="language-translation">
                        <label class="m-0">Hindi</label>
                      </div>
                    </div>
                  </>
                }

              </div>
              {/* {!props.hideDefaultCheck &&
                <div class="row ps-3 pt-3">
                  <div class="form-check">
                    <input
                      class="form-check-input mt-1"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    ></input>

                    <label
                      class="form-check-label bold-500 cursor-pointer"
                      for="defaultCheck1"
                    >
                      Set this option as my default language
                    </label>

                  </div>
                </div>
              } */}

              <div className="d-flex float-end mt-3">
                {props.forRegVideo ? (
                  <button
                    disabled={isValSelected == "" ? true : false}
                    className={isValSelected == "" ? "ok-disabled pe-3 ok-btn" : "pe-3 cta-ok cursor-pointer"}  
                    onClick={() => {
                      handleOK();

                      props.okEvent();
                    }}
                  >
                    <Translate textKey="registration015" />
                  </button>
                ) : (
                  // {props.formValues.languageId != 0}
                  <h6
                    className="pe-3 cta-ok cursor-pointer"
                    onClick={() => {
                      handleOK();
                      props.okEvent();
                    }}
                  >
                    <Translate textKey="registration015" />
                  </h6>
                )}
                {
                  props.dontShowClose == "true" ?
                  "" : <h6 className="cursor-pointer" onClick={handleClosePopUp}>
                  <Translate textKey="registration016" />
                </h6> 
                }
                
              </div>
            </div>
          </div>
      }
      <section className="modal overlay"></section>
      {/* <style>
      {`
      .modal-content {
        border-radius: 16px !important;
        max-width: 400px;
        margin: 0 auto;
      }
      `}
    </style> */}
    </>
  );

}