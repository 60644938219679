

import React, { useState, useEffect } from "react";
import SessionHeading from "../EligibilityCheck/SessionHeading";
import PdfComponent from "../Shared/pdfComponent";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { variables } from "../Shared/Variables";
import CompletedReminder from "../MyCourses/CompletedReminder";
import moment from "moment";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateDataForCertificate, updateCertificateFrom } from "../../features/CourseIndex/courseIndexSlice";

export default function ProfileContent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem('login-userId');
  const batchId = sessionStorage.getItem("batchIdsList");
  const allCertificateData = JSON.parse(sessionStorage.getItem("allCertificateData"));
  // console.log("allCertificateData from seesion in profile : ", allCertificateData)
  const [allCertificates, setAllCertificates] = useState(allCertificateData);

  // const getAllCertificates = async () => {
  //   const bodyForAllCertificates = {
  //     userId: userId,
  //     batchId: batchId
  //   }
  //   // // console.log("bodyForAllCertificates : ",bodyForAllCertificates);
  //   const resAfterFecthingAllCertificates = await axios.post(variables.API_URL + 'api/GetCertificate', bodyForAllCertificates);
  //   // // console.log("resAfterFecthingAllCertificates : ",resAfterFecthingAllCertificates);
  //   if (resAfterFecthingAllCertificates.data.isSuccess) {
  //     setAllCertificates(resAfterFecthingAllCertificates.data.data);
  //   }
  // }


  const goToDownloadCertificate = (item) => {
    // console.log("inside goToDownloadCertificate item val : ", item);
    // dispatch(updateDataForCertificate(item));
    sessionStorage.setItem("certificateData", JSON.stringify(item));
    sessionStorage.setItem("certificateFrom", "ProfileContent.js");
    // dispatch(updateCertificateFrom("ProfileContent.js"));
    navigate('/DownloadCertificate');
  }

  useEffect(() => {
    // getAllCertificates()
  }, [])


  return (
    <>


      <div className="col-lg-9 col-sm-12 col-xs-12 content-cards mb-5 pb-2">

        <div className="profile-heading primary-radius">
          <SessionHeading iconType="myProfile" type="profile" name="My Profile" />
          <div class="p-3 pb-0">
            <h4 class="bold-500 ms-2">Certificates</h4>
          </div>
          <div class="row ViewCertificate pt-3 p-4">
            {
              allCertificates.map((item) => {
                // console.log("item in allCertificates : ", item);
                return (
                  <>
                    <div class="col-md-4 certifiate-box p-0" >
                      <div class=" certificate-pdf " >
                        {/* <PdfComponent url="../../Assets/Linqskills_Icons/Aakanksha Jain_Certificate.pdf" /> */}
                        <h4 class="m-3 line-clamp-four">{item.courseName}</h4>
                        <div class="certificate-content p-3">
                          <CompletedReminder txtValue="Course Completed On"></CompletedReminder>
                          <div class="calendar-text mt-2">
                            <img src="../../Assets/Linqskills_icons/calendar-default.svg" class="me-1" />
                            <h6>{item.courseCompletedOn == null ? "" : moment(item.courseCompletedOn).format('DD MMM YYYY | hh:mm A')}</h6>
                        </div>
                          {/* add component */}
                          <div class=" text-center">
                            <button onClick={() => goToDownloadCertificate(item)} class="primary-btn mt-3">
                              <img src="../../Assets/Linqskills_Icons/Certificate-white.svg" className=""></img>
                              View Certificate</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>

          {/* <div class="col-md-12 text-center p-3 view-certificates">
            <h6 class="cursor-pointer">View More</h6>
          </div> */}
        </div>
      </div>
    </>
  )
}



{/* <div className=" session-heading bg-body border-bottom">
                                <div className="d-flex">
                                    <img src="../../Assets/Linqskills_Icons/LearningTopic.svg" className=""></img>
                                    <h5 className="mb-0 mt-1 ms-2 ps-1 ">My Profile</h5>
                                </div>
                            </div> */}


{/* <div class="col-md-4">
                                <img src="../../Assets/Linqskills_Icons/Certificatebg.svg" className="pe-1 w-100"></img>
                                </div> */}

