import React, { useState, useEffect } from "react";
import { Redirect, useSearchParams } from "react-router-dom";
import NavOne from "../../components/NavOne.js";
import Translate from "../Shared/Translate";
import Cookies from "universal-cookie";
import EligibilityCoursecard from "./EligibilityCoursecard";
import EligibilityCheckContent from "./EligibilityCheckContent";
import Breadcrumb from "../CourseIndex/Breadcrumb";
import TrainerMessage from "../CourseIndex/TrainerMessage";
import LanguagePopup from "../Shared/LanguagePopup";
import HamburgerIcons from "../Shared/HamburgerIcons.js";


import { variables } from "../Shared/Variables";
import Accordion from "../Shared/Accordion.js";
import { updateChild, updateExtended, updateParent } from "../../features/breadcrumb/breadcrumbSlice";
import { useDispatch } from "react-redux";
/*import PopupOne from '../Shared/PopupOne';*/

export default function Eligibility() {
  var course = JSON.parse(sessionStorage.getItem("coursedata"));
  // alert("course value if Eligibility")
  console.log("course value if Eligibility : ",course)

  const [openLanguage, setopenLanguage] = useState(false);
  const [showMainDiv, setShowMainDiv] = useState(true);


  const cookies = new Cookies();
  //cookies.set('language', language, { path: '/' });
  var Lan = cookies.get("language");
  const defaultLanguage =  cookies.get('default-language')
  Lan = Lan != undefined ? Lan : "English";
  function closeLanguage() {
    setopenLanguage(false);
  }

  const dispatch = useDispatch();


    useEffect(()=>{
      dispatch(updateExtended("false"))
     dispatch(updateParent('My Proficiency Test'))
     dispatch(updateChild('Eligibility Check'))
     setopenLanguage(true);
    },[])

    const DisplayHamburger = () => {
      setShowMainDiv(false);
    }
  
    const HandleHamburgerClick = (data) => {
      setShowMainDiv(data);
    }
  
    const CloseHamburger = () => {
      // alert("CloseHamburger");
      setShowMainDiv(true);
  
      // showMainDiv = true;
      //TestAlert();
    }

  return (
    <>
      {/*   <PopupOne/>*/}
    
         
                        <NavOne></NavOne>
                 

      <div class="container-fluid body-wrapper bg-default">
        <div class="container p-0">
        <Breadcrumb redirectLink="mycourses" hamburgerClassSow={showMainDiv}  />
        </div>
        <div class="container">
    
          <HamburgerIcons showHamburgerIcon={showMainDiv} hamburgerParentCallBack={HandleHamburgerClick} />

              {/*    {openLanguage && !defaultLanguage && (
              <LanguagePopup
                closeEvent={closeLanguage}
                okEvent={closeLanguage}
                headerKey="Login014"
              />
            )  
            } */}
             <div className="course-indexcontent">    
              <EligibilityCoursecard
                sectorName={course.sectorName}
                courseName={course.courseName}
                description={course.description}
                duration = {course.courseDuration}
                courseIcon={
                  variables.AWSPath + variables.AWSFolder + course.icon
                }
                showHamburger={DisplayHamburger} hideHamburger={CloseHamburger} showHamburgerIcon={showMainDiv} 
              />
            
            <EligibilityCheckContent showMainDiv={showMainDiv} />
       
          </div>
        </div>
      </div>
    </>
  );
}
