
import React, { useState } from "react";
import Translate from '../Shared/Translate';
export default function CustumLoader(props) {

  return (
    <>



<div class="container-fluid custum-loader">
<section class="modal overlay "></section>
<div class="loader-child col-md-4 bg-none">
<section class="loader col-md-5 m-auto mb-3 justify-content-center">


<span class="dot"></span>
<span class="dot"></span>
<span class="dot"></span>
<span class="dot"></span>

</section>
<div class="mt-3">
<span>Loading...</span>
</div>
    </div>

</div>






    </>


  )

}


