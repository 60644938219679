import React, { useState } from "react";

import ChatbotQuery from "../ChatBox/Chotbot";
import chatIcon from '../../Assets/img/chatbot.svg';


export default function Chatbot()  {
    

  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };


  
    return (
      <div>
      
        <img src={chatIcon} alt="Chat Button"  className="chat-button"   onClick={toggleChatbot}/>
        <ChatbotQuery isOpen={isChatbotOpen} onClose={toggleChatbot} />

      </div>
    );
  };
