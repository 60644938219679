import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie"
import { Link, useLocation } from "react-router-dom";
import { variables } from '../Shared/Variables';
import { useSelector, useDispatch } from "react-redux";
import { callingDispose,updateFilterRetained,updateFilterVal,updateNodeVal,updateIsUserLoggedIn,updateAccordianNo,updateOnlineLec,updateIsDevice,updateVideoIndex, updateActiveStage1List,updateActiveStage4List } from "../../features/CourseIndex/courseIndexSlice";
import { updateggChild } from "../../features/breadcrumb/breadcrumbSlice";


export default function Logout() {
  
  const dispatch = useDispatch();
  // const profilePic = sessionStorage.getItem("profilePic");
  const [userProfileData, setUserProfileData] = useState();
  const cookies = new Cookies();
  const navigate = useNavigate();

  useEffect(()=>{
    gettingUserProfile()
  },[])

  const gettingUserProfile = () =>{
    let UserId = sessionStorage.getItem("login-userId")
    // // console.log("login-userId : ", UserId);
    fetch(variables.API_URL + `api/GetUserData/${UserId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // console.table("data of user profile : ", JSON.stringify(data));
        if (data.isSuccess) {
          setUserProfileData(data.data);
          sessionStorage.setItem("profilePic", data.data.profilePic);
        }
      });
  }

  // console.log("userProfileData in logout : ",userProfileData)

  const Logout = async () => {
    dispatch(callingDispose(true));
    dispatch(updateFilterRetained(true));
    dispatch(updateFilterVal(""));
    dispatch(updateNodeVal([]));
    dispatch(updateOnlineLec([]));
    dispatch(updateIsUserLoggedIn(false));
    dispatch(updateAccordianNo(0));
    dispatch(updateVideoIndex("-1"));
    dispatch(updateggChild(""))
    dispatch(updateIsDevice("finding"));
    dispatch(updateActiveStage1List([]));
    dispatch(updateActiveStage4List([]));

    try {
      let url = variables.API_URL+'api/UpdateUserLogoutDetails'
      const data = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          SessionId: cookies.get('sessionId'),
          UserId: sessionStorage.getItem('login-userId')
        })
      })

      const res = await data.json();
      const logoutUser = res.isSuccess

      // console.log(logoutUser, "log")
      if (logoutUser) {
        sessionStorage.clear();
        localStorage.clear();
        cookies.remove('language');
        cookies.remove('default-language');
        cookies.remove('sessionId');
        navigate('/');
      }
      else {
        // console.log('logout unsuccessful')
      }
    } catch (error) {
      // console.log(error)
    }
  }

  return (

    <>
      <div class="logout-box">
        <div class="profile text-center border-bottom pb-3">
          <div class="placeholder-profile mb-3">
            {userProfileData?.profilePic? 
            <img src={`${variables.AWSPath}${userProfileData?.profilePic}`} className="mt-3"></img>
            :
            <img src="../../Assets/Linqskills_Icons/logout-profileicon.svg" className="mt-3"></img> 
            }
          </div>


          <h5 class="line-clamp-two">{userProfileData?.fullName}</h5>
          <h6>Participant / Student</h6>
        </div>

        <div class="coursecard-list pt-3">
          {/* <div class="d-flex pt-2 pb-2 ps-2 me-0 cursor-pointer courselist-hover">
            <Link to={'../UserProfile'} state={"home"} >
              <img src="../../Assets/Linqskills_Icons/editprofile-default.svg" class="pe-2"></img>
              <span>Edit Profile</span>
            </Link>
          </div> */}
          {/* <div class="d-flex pt-2 pb-2 ps-2 me-0 cursor-pointer courselist-hover">
            <Link to={'../UserProfile'} state={"setting"} >
              <img src="../../Assets/Linqskills_Icons/settings-default.svg" class="pe-2"></img>

              <span>Settings</span>
            </Link>
          </div> */}
          <div class="courselist-hover d-flex pt-2 pb-2 ps-2 me-0 cursor-pointer" onClick={() => Logout()}  >
            <img src="../../Assets/Linqskills_Icons/logout-active.svg" class="pe-2"></img>
            {/* <img src="../../Assets/Linqskills_Icons/logout-default.svg" class="pe-2"></img> */}
            <span>Logout</span>
          </div>
        </div>
      </div>
    </>
  )
}
