import React, { useEffect, useState, useRef } from "react";
import LanguagePopup from "../Shared/LanguagePopup";
import Cookies from "universal-cookie";
import { variables } from "../Shared/Variables";
import ViewTutorialPopUp from "../CourseIndex/viewContentPopUp";
import SessionHeading from "../EligibilityCheck/SessionHeading";
import { Navigate, useNavigate } from "react-router-dom";
import CompletedTest from "../Shared/CompletedTest";
import { useDispatch, useSelector } from "react-redux";
import { updateggChild } from "../../features/breadcrumb/breadcrumbSlice";
import { Link, useLocation } from "react-router-dom";
import NavigationBtns from "../Shared/NavigationBtns";
import {
  updateNextPrevUpdated,
  updateNodeVal,
} from "../../features/CourseIndex/courseIndexSlice";
import moment from "moment";

export default function AssessmentTopicContent(props) {
  const cookies = new Cookies();
  var nodeValue = useSelector((state) => state.courseindex.nodeData);
  const nodeIdForAssesmentGetdata = useSelector(
    (state) => state.courseindex.nodeIdForAssesmentGetdata
  );
  // // console.log("nodeIdForAssesmentGetdata : ",nodeIdForAssesmentGetdata)
  const [languagePopupClicked, setLanguagePopupClicked] = useState(
    sessionStorage.getItem("prefferedLanguage") == "" ? true : false
  );
  const [topicUpdated, setTopicUpdated] = useState(0);
  // console.log("props in AssessmentTopicContent : ", props);
  const nodeID = props.topicContent.nodeID;
  // // console.log("nodeID from prop topicContent : ", nodeID);
  const totalTimeSpentByCandidate = props.topicContent.timeSpentInTopic;
  var timeSpentCnt = totalTimeSpentByCandidate;
  // var finalTimeSpentCnt = timeSpentCnt;
  const [isShowModal, setIsShowModal] = useState(false);
  const [extensionType, setExtensionType] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [startTest, setStartTest] = useState(false);
  const [startEval, setStartEval] = useState(false);
  const [resId, setresId] = useState(0);
  const [testDuration, setTestDuration] = useState(0);
  const [negativeMarks, setNegativeMarks] = useState();
  const [reAttemptsNo, setReAttemptNo] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [testName, setTestName] = useState("");
  const [submittedTest, setsubmittedTest] = useState("");
  const [attemptsCount,setattemptsCount]= useState(0);
  const langArray = [
    { value: "1", label: "English" },
    { value: "2", label: "Hindi" },
    { value: "3", label: "Tamil" },
    { value: "4", label: "Bengali" },
    { value: "5", label: "Telugu" },
    { value: "6", label: "Odiya" },
  ];
  // console.log("props?.topicContent : ", props?.topicContent);

  const shouldLog = useRef(true);
  const dispatch = useDispatch();

  // // console.log("props in assesmentTopicContent.js : ", props);

  let availableLanguages = [];
  useEffect(() => {
    if (Object.keys(props?.topicContent).length > 0) {
      const Languages = props?.topicContent?.contentLanguages.split(",");
      availableLanguages = Languages.filter((lan) => lan != "");
    }
  }, [props]);

  useEffect(() => {
    sessionStorage.setItem("topicDetails", JSON.stringify(props.topicContent));
    const nodeName =
      props.topicContent[
        `nodeName_${sessionStorage.getItem("prefferedLanguage")}`
      ] ||
      props.topicContent[
        `nodeName_${sessionStorage.getItem("prefferedLanguage")}`
      ] ||
      props.topicContent.nodeName;

    dispatch(updateggChild(nodeName));
    handleStartTest();
    if (shouldLog.current) {
      shouldLog.current = false;
    }
    document.addEventListener("click", aClick);
  }, [nodeID]);

  // if (topicUpdated == 1) {
  //     var el = document.getElementById("hdnTopicUpdated");
  //     el.click();
  //     StartTimer();
  // }

  const aClick = (event) => {
    if (event.target.className === "aCls") {
      if (
        event.target.getAttribute("fullUrl") == undefined ||
        event.target.getAttribute("fullUrl") == "" ||
        event.target.getAttribute("fullUrl") == null
      ) {
        var hrefAttrVal = event.target.getAttribute("href");
        event.target.setAttribute("fullUrl", hrefAttrVal);
      }

      event.target.setAttribute("href", "javascript:void(0);");
      var hrefVal = event.target.getAttribute("fullUrl");
      var hrefStringVal = hrefVal.toString();
      setLinkUrl(hrefStringVal);
      var extension = hrefStringVal.split(".").pop();
      setExtensionType(extension);
      DisplayContentInModal();
    }
  };

  const DisplayContentInModal = () => {
    setIsShowModal(true);
  };

  const CloseModalPopup = () => {
    setIsShowModal(false);
  };

  const StartTimer = () => {
    if (props.topicContent.sessionDeliveryMode === 2) {
      var timeSpentCnt = totalTimeSpentByCandidate;
      cookies.set("nodeTimeSpentCnt", timeSpentCnt);
      // Increase the time by 1 every second
      const timer = setInterval(() => {
        timeSpentCnt = ++timeSpentCnt;
        //finalTimeSpentCnt = timeSpentCnt;
        cookies.set("nodeTimeSpentCnt", timeSpentCnt);
        // console.log(timeSpentCnt);

        // When the time spent reaches 30s, unlock the immediate next topic
        if (timeSpentCnt === 30) {
          UpdateTopicStatus();
        }
      }, 1000);

      // Cleanup the interval on component unmount or re-render
      return () => {
        clearInterval(timer);
      };
    }
  };

  const UpdateTopicStatus = () => {
    
    var userID = sessionStorage.getItem("login-userId");
    userID = encodeURIComponent(userID);
    if (props.topicContent.sessionDeliveryMode == 2) {
      fetch(variables.API_URL + "api/UpdateTopicStatus", {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserID: userID,
          NodeID: nodeID,
          BatchID: props.batchId,
          TimeSpent: cookies.get("nodeTimeSpentCnt"), //timeSpentCnt
        }),
      })
        .then((response) => {
          if (response.statusText == "Unauthorized") {
            this.setState({ isUnauthorizedUser: true });
          } else return response.json();
        })
        .then((data) => {
          if (data.isSuccess) {
            let nextPrevData = JSON.parse(
              sessionStorage.getItem("nextPrevData")
            );
            let nodeVal = JSON.parse(sessionStorage.getItem("nodeVal"));
            for (let i = 0; i < nextPrevData.data.userDataList.length; i++) {
              if (nextPrevData.data.userDataList[i].nodeID == nodeID) {
               /*   console.log(
                  "changing current node topic status in assesment topic content"
                ); */
                nextPrevData.data.userDataList[i].topicStatus = "Completed";
                if (
                  nextPrevData.data.userDataList[i + 1].topicStatus == "Locked"
                ) {
                  // console.log("opening next session in assesmenttopiccontent");
                  alert("opening next session in assesmenttopiccontent");
                  nextPrevData.data.userDataList[i + 1].topicStatus = "Open";
                }
                for (let k = 0; k < nodeVal.length; k++) {
                  // // console.log("inside 270 line ; ",nodeValue[k].sessionID + " ;" + nextPrevData.data.userDataList[i].sessionID + " ;" + nodeValue[k].nodeClassification)
                  if (
                    nodeVal[k].sessionID ==
                      nextPrevData.data.userDataList[i].sessionID &&
                    nodeVal[k].nodeClassification == "Session"
                  ) {
                    nodeVal[k].completionStatus = "Completed";

                    if (
                      nodeVal[k + 1].completionStatus == "Locked" &&
                      nodeVal[k + 1].nodeClassification == "Session" &&
                      nodeVal[k + 1].deliveryMode != 1
                    ) {
                      nodeVal[k + 1].completionStatus = "Open";
                    } else if (
                      nodeVal[k + 2].completionStatus == "Locked" &&
                      nodeVal[k + 2].nodeClassification == "Session" &&
                      nodeVal[k + 2].deliveryMode != 1
                    ) {
                      nodeVal[k + 2].completionStatus = "Open";
                    }

                    // console.log("nodeVal after updating : ", nodeVal);
                    dispatch(updateNodeVal(nodeVal));
                    alert(
                      "updating course index at assesmentTopicContent after submission of assessment"
                    );
                    sessionStorage.setItem("nodeVal", JSON.stringify(nodeVal));
                  }
                }
              }
            }
            dispatch(updateNextPrevUpdated(true));
            sessionStorage.setItem(
              "nextPrevData",
              JSON.stringify(nextPrevData)
            );
            if (!props.isUpdateTopicTimeSpent) {
              var el = document.getElementById("hdnTopicUpdated");
              el.click();
              shouldLog.current = true;
            } else {
              shouldLog.current = false;
            }
          }
        });
    }
  };

  if (props.isUpdateTopicTimeSpent) {
    if (shouldLog.current) {
      shouldLog.current = false;

      UpdateTopicStatus();
      StartTimer();
      // cookies.set("nodeTimeSpentCnt", timeSpentCnt);
    }
  }

  function closeLanguage() {
    setLanguagePopupClicked(false);
    StartTimer();
  }

  function okLanguage() {
    setLanguagePopupClicked(false);
    StartTimer();
  }
  // console.log("test:"+props.testCompletedOn)
  const userId = sessionStorage.getItem("login-userId");
  const coursedata = JSON.parse(sessionStorage.getItem("coursedata"));
  const fetchAssessmentDetails = async () => {
    try {
      const response = await fetch(
        `${variables.API_URL}api/GetSubmittedAssessmentDetail?userID=${parseInt(
          userId
        )}&batchID=${coursedata.batchId}&nodeID=${nodeID}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log({ data });
      setsubmittedTest(data.data);
    } catch (error) {
      console.error("Error fetching assessment details:", error);
    }
  };

  const setTopicDuartion = () => {
    var durationInString = "";

    if (props.topicContent.topicDurationInMins < 60)
      durationInString = props.topicContent.topicDurationInMins + " min";
    else {
      const minutes = props.topicContent.topicDurationInMins % 60;
      const hours = Math.floor(props.topicContent.topicDurationInMins / 60);
      durationInString = hours + " hr " + minutes + " min";
    }

    return durationInString;
  };

  // console.log("setTopicDuartion() : ", setTopicDuartion());

  const DisplayLanguagePopup = () => {
    setLanguagePopupClicked(true);
  };
  function handleStartTest() {
    var LangaugeId = langArray
      .filter((data) =>
        data.label.includes(sessionStorage.getItem("prefferedLanguage"))
      )
      .map((val) => val.value);
    // console.log("LangaugeId : ", LangaugeId);
    var userID = sessionStorage.getItem("login-userId");
    userID = encodeURIComponent(userID);
    // console.log(`parameter in url nodeId ${nodeID}`);
    fetch(
      variables.API_URL +
        "api/GetAssessmentTestData/" +
        userID +
        "/" +
        props.batchId +
        "/" +
        nodeIdForAssesmentGetdata +
        "/" +
        LangaugeId,
      {
        method: "GET",
        headers: {
          //'Authorization': 'Bearer ' + sessionStorage.getItem("JWTToken")
        },
      }
    )
      .then((response) => {
        if (response.statusText == "Unauthorized") {
          this.setState({ isUnauthorizedUser: true });
        } else return response.json();
      })
      .then((result) => {
        if (result.isSuccess) {
          var res = result.data;
          // console.log("res after getting test data : ", res);
          const data = {
            UserId: res.userId,
            TestId: res.testId,
            ProjectId: res.projectId,
            CourseId: res.courseId,
            templateId: res.templateId,
            totalMarks: res.totalMarks,
            addOnCourseId: res.addOnCourseId,
            duration: res.duration,
            LanguageId: res.languageId,
            LessionPlanId: res.lessionPlanId,
            testName: res.testName,
            resultId: res.resultId,
            IsAssessment: true,
            ParentNodeId: props.ParentNodeId,
            ParentNodeName: props.ParentNodeName,
            BatchId: props.batchId,
            DeliveryMode: 4,
            ExamMode: props.examMode,
            attemptsCount:res.attemptsCount,
            reAttemptsNo: res.reAttemptsNo
          };
          // sessionStorage.setItem("TestData1", null);
          sessionStorage.setItem("TestData1", JSON.stringify(data));

          var TestData = JSON.stringify(res);
          setTestDuration(res.duration);
          setNegativeMarks(res.negativeMarks);
          setReAttemptNo(res.reAttemptsNo);
          setTotalQuestions(res.totalQuestions);
          setTestName(res.testName);
          setresId(res.resultId);
          setattemptsCount(res.attemptsCount)
          if (res.resultId != 0) {
            fetchAssessmentDetails();
          }
        }
      });
  }

  if (startEval) {
    // console.log("startEval startEval startEval");
    return <Navigate to="/UploadEvaluation" />;
  } else if (startTest) {
    // console.log("startTest startTest startTest");
    return <Navigate to="/quizquestion" />;
  } else {
    // // console.log("props.topicContent for assesment", props.topicContent)
    // // console.log("props.topicContent.completedOn for assesment", props.topicContent.completedOn)
    return (
      <>
        {languagePopupClicked && (
          <LanguagePopup
            closeEvent={closeLanguage}
            okEvent={okLanguage}
            header="Select the preferred language to view the content"
            title="Select Medium of Instruction"
            // type="center"
            availableLanguages={availableLanguages}
            assessment="true"
            extendedBC={true}

            // ContentLanguages={props.ContentLanguagesID}
            // selectedLanguageId={props.DefaultLanguageId}
            //setTestLanguage={props.DefaultLanguageName}
          />
        )}
        <div
          className={`col-lg-9 col-sm-12 col-xs-12 mb-3 content-cards ${
            props.showMainDiv ? "sas" : "d-none"
          }`}
        >
          <div className="primary-radius bg-white primary-radius mb-3">
            {/* onChange={topicUpdated === 1 ? props.onTopicComplete : javascript:void(0);} */}
            <input
              type="hidden"
              value={topicUpdated}
              id="hdnTopicUpdated"
              onClick={props.onTopicComplete}
            />
            <SessionHeading
              name={
                props.topicContent[
                  `nodeName_${sessionStorage.getItem("prefferedLanguage")}`
                ] ||
                props.topicContent[
                  `nodeName_${sessionStorage.getItem("prefferedLanguage")}`
                ] ||
                props.topicContent.nodeName
              }
              duration={props.topicContent.topicDurationInMins}
              languagesAvailable={props.topicContent.contentLanguages}
              languagesMoreThanOne={availableLanguages.length > 1}
              deliveryMode={props.topicContent.sessionDeliveryMode}
              onLanguageClick={DisplayLanguagePopup}
            ></SessionHeading>

            {props.topicContent.assessmentId != 0 &&
            (props.topicContent.topicStatus == "Completed" ||
              props.topicContent.topicStatus == "Submitted") ? (
              <CompletedTest
                index={props.index}
                nodeId={nodeID}
                dontShowNavBtns={false}
                topicsListData={props.topicsListData}
                isShowGoToIndexBtn={true}
                isDateTime={true}
                testCompletedOn={props.topicContent.completedOn}
                Message={
                  props.topicContent.topicStatus == "Submitted"
                    ? "The test was submitted on"
                    : "The test was completed on"
                }
                ImgSrc="../../Assets/Linqskills_Icons/test-submited.svg"
                redirectMess={
                  "To navigate back to the index page and proceed to the next session, simply click/tap on the purple-highlighted back arrow located in the breadcrumb trail."
                }
              />
            ) : (
              <div class="col-md-12">
                <>
                  {(attemptsCount === 0 )? (
                    <div className="assessment-instructions border-bottom">
                      {props.topicContent.assessmentId != 0 && (
                        <>
                          {" "}
                          <h6>
                            Time limit to complete :{" "}
                            <span className="b-text">
                              {testDuration / 60} Min.
                            </span>
                          </h6>
                          <h6>
                            No of questions to attempt :{" "}
                            <span className="b-text">{totalQuestions} </span>
                          </h6>
                          <h6>
                            Negative marking :{" "}
                            <span className="b-text">{negativeMarks}</span>
                          </h6>
                          <h6>
                            Attempts allowed :{" "}
                            <span className="b-text">{reAttemptsNo}</span>
                          </h6>
                        </>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            sessionStorage.getItem("prefferedLanguage") ==
                            "Hindi"
                              ? props.topicContent.contentInHindi
                              : sessionStorage.getItem("prefferedLanguage") ==
                                "English"
                              ? props.topicContent.contentInEnglish
                              : sessionStorage.getItem("prefferedLanguage") ==
                                "Tamil"
                              ? props.topicContent.contentInTamil
                              : sessionStorage.getItem("prefferedLanguage")
                              ? props.topicContent.contentInBengali
                              : sessionStorage.getItem("prefferedLanguage")
                              ? props.topicContent.contentInTelugu
                              : sessionStorage.getItem("prefferedLanguage")
                              ? props.topicContent.contentInOdiya
                              : "",
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div class="test-submitted mb-4 text-center">
                        <img
                          src="../../Assets/Linqskills_Icons/test-submited.svg"
                          class="m-auto mt-3"
                        ></img>
                        <div class="p-3 pt-0">
                          <h6 class="sasas">
                            <span class="bold-500 mb-2 line-height-adjustment">
                              The test was submitted on{" "}
                            </span>
                            <br></br>
                            {moment(submittedTest.submittedOn).format("DD MMM YYYY")}  |  {moment(submittedTest.submittedOn).format("hh:mm A")}`

                          </h6>
                        </div>
                        <h5 class="mt-2">
              Your score for this Assessment is
              <span class="">
                &nbsp;{submittedTest?.marksobtained} out of{" "}
                {submittedTest?.totalmarks}
              </span>
            </h5>
                      </div>
                    </>
                  )}
                  {props.topicContent.topicStatus == "Open" && (
                    <div className="row text-center pt-3 pb-3 ">
                      <h6>Click on the “                          {resId != 0 ? "Retake Test" : "Start"}
                      ” button to proceed</h6>
                      <div className="d-flex justify-content-center mt-3">
                        {/* <Link to={'../CourseIndex'} >
                                                        <button class="secondary-btn me-4">
                                                            Go To Index Page
                                                        </button>
                                                    </Link> */}
                        <button
                          className="primary-btn text-uppercase"
                          onClick={
                            props.topicContent.assessmentId != 0
                              ? () => {
                                  setStartTest(true);
                                }
                              : () => {
                                  setStartEval(true);
                                }
                          }
                        >
                          {resId != 0 ? "Retake Test" : "Start"}
                        </button>
                      </div>
                    </div>
                  )}

                  <NavigationBtns
                    index={props.index}
                    topicsListData={props.topicsListData}
                  />
                </>
              </div>
            )}
          </div>
        </div>

        {isShowModal && (
          <ViewTutorialPopUp
            show={isShowModal}
            onHide={CloseModalPopup}
            src={linkUrl}
            extensionType={extensionType}
            showHeading={false}
          />
        )}
      </>
    );
  }
}
