import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';

//import PopupOne from '../Shared/PopupOne';

export default function CompletedReminder(props) {
    return (
        <>
            <div className="reminder-completed">

                <img src="../../Assets/Linqskills_Icons/certificate-hat.svg" class="" />
                <span className="">{props.txtValue}</span>

            </div>
        </>
    )
}