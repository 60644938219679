import moment from "moment";
import { TimerFormat } from "../generalFunctions/timeFormat";
import Translate from '../Shared/Translate';
const VcAccordian = ({vcAccordianName,acc2forPastVc,setAcc2forPastVc, vcList,updateContentBreadCrumb}) => {
  const handleToggle = () => {
    if(vcAccordianName != "Online Lectures"){
      setAcc2forPastVc(true);
    }
  }
  return (
    <div className="accordion mb-4 p-0" id="accordionExample">
      <div class="accordion-item box-shadow">
        <div>
          <div class="accordion-header topic-header s" id="headingOne">
            <button onClick={handleToggle} class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <div class="pink-box me-2"><img src="../../Assets/Linqskills_Icons/VC-24-MulticolorRed.svg" class="me-1"></img></div>
              <h5 class="m-0 text-truncate">{vcAccordianName}</h5>
            </button>
          </div>
          <div
            id={acc2forPastVc?"collapseOne" : ""}
            class={acc2forPastVc?"accordion-collapse collapse show" : "accordion-collapse collapse"}
            aria-labelledby="headingOne"
            data-bs-parent="accordionExample"
          >
            <div class="accordion-body border-top ">
              {
                vcList != null && vcList.length > 0 ? <>
                  {vcList.map((subnode, index) => {
                    // console.log(`subnode in vcList map ${index} : `, subnode);
                    let meetingStatus = "";
                    const meetingDataArr = subnode.sessionStart.split(" ");
                    // // console.log("meetingDataArr : ", meetingDataArr);
                    let targetTime = new Date();
                    targetTime.setMinutes(targetTime.getMinutes() - subnode.duration - 5);
                    targetTime = targetTime.toLocaleString({ hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
                    const targetTimeArr = targetTime.split(" ");
                    // // console.log("targetTimeArr : ", targetTimeArr);

                    function isToday(date) {
                      // console.log(`checking date is today or not func called at ${index}`)
                      const today = new Date();

                      // 👇️ Today's date
                      // console.log(today);

                      if (today.toDateString() === date.toDateString()) {
                        return true;
                      }

                      return false;
                    }

                    if (isToday(new Date(meetingDataArr[0]))) {
                      // console.log(`its today meeting at ${index}`);
                      meetingStatus = "todayMeeting"
                    }

                    // const compareDates = (d1, d2) => {
                    //     let date1 = new Date(d1).getTime();
                    //     let date2 = new Date(d2).getTime();
                    //     // // console.log(`date1 : at ${index} `,date1)
                    //     // // console.log(`date2 : at ${index} `,date2)
                    //     if (date1 < date2) {
                    //         // console.log(`${d1} is less than ${d2} at ${index}`);
                    //         meetingStatus = "past"
                    //     } else if (date1 > date2) {
                    //         // console.log(`${d1} is greater than ${d2}  at ${index}`);
                    //         meetingStatus = "todayMeeting"
                    //     } else {
                    //         meetingStatus = "todayMeeting"
                    //         // console.log(`Both dates are equal  at ${index}`);
                    //     }
                    // };
                    // compareDates(meetingDataArr[0], targetTimeArr[0]);
                    // // console.log(`final meeting status index at ${index} : ${meetingStatus}`)
                    // console.log(`meetingStatus for i = ${index} == ${meetingStatus}`);
                    return (
                      <div class="row" key={index}>
                        <div class="col-md-12 col-lg-12 d-flex pb-4">
                          <div class="col-lg-1 left-contentIcon ">
                            {subnode.meetingStatus == "upcoming" ? meetingStatus == "todayMeeting" ?
                              < img src="../../Assets/Linqskills_Icons/sessionstatus-open.svg" class="pe-2"></img> : < img src="../../Assets/Linqskills_Icons/sessionstatus-locked.svg" class="pe-2"></img> :
                              <img src="../../Assets/Linqskills_Icons/sessionstatus-completed.svg"
                                class="pe-2" ></img>
                            }
                          </div>

                          <div class="col-lg-11 col-sm-10 ps-2 mt-6p">
                            <h5 class="bold-500 mb-1 text-capitalized">
                              {/* {((cookies.get("default-language")) ? subnode[`nodeTitle_${(cookies.get("default-language"))}`] : subnode[`nodeTitle_${(cookies.get("language"))}`]) || subnode.nodeName} */}
                              {subnode.topic}

                            </h5>

                            <div class="col-lg-12 session-details">
                              <div class="me-2 d-flex">
                                <div>
                                  <img
                                    src="../../Assets/Linqskills_Icons/virtual-multicolorgreen.svg"
                                    class="pe-1"
                                  ></img>
                                </div>
                                <div>
                                  <span class="h7 align-self-center">

                                    {
                                      subnode.meetingStatus == "upcoming" ? TimerFormat(subnode.duration) : "Completed"
                                    }
                                  </span>
                                </div>

                                <div class="d-flex me-2">
                                  <div class="ps-1">
                                    <img src="../../Assets/Linqskills_Icons/calendartime-multicolorgreen.svg" class="me-1" alt=""></img>
                                  </div>
                                  <div>
                                    <span class="h7 align-self-center ">
                                      {subnode.sessionEnd != "1900-01-01T00:00:00" && subnode.meetingStatus == "past" ? <>
                                        {`${moment(subnode.sessionEnd).format("DD MMM YYYY")}  |  ${moment(subnode.sessionEnd).format("HH:mm A")}`}
                                      </> : subnode.completedOn != "1900-01-01T00:00:00" && subnode.meetingStatus == "past" ? <>
                                        {`${moment(subnode.completedOn).format("DD MMM YYYY")}  |  ${moment(subnode.completedOn).format("hh:mm A")}`}
                                      </>
                                      :<>
                                        {`${moment(subnode.sessionStart).format("DD MMM YYYY")}`} | {subnode.startTime}
                                      </>
                                      }</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            meetingStatus == "todayMeeting" || subnode.meetingStatus == "past" ? <div
                              class="col flex-shrink-1"
                              onClick={() => updateContentBreadCrumb(subnode, subnode.meetingStatus)}
                            >
                              <img
                                src="../../Assets/Linqskills_Icons/forward-actionblue.svg"
                                class="float-end cursor-pointer"
                              ></img>
                            </div> : ""
                          }

                        </div>
                      </div>
                    );
                  })}
                </>
                  : <div class="row pb-3 ps-2"  >

                    <p class="bold-500">There are no {vcAccordianName == "Upcoming Online Lectures" ? "upcoming Online Lectures" : vcAccordianName} scheduled for this course</p>
                  </div>

              }

            </div>
          </div>
        </div >
      </div>
    </div>
  )
}

export default VcAccordian;