import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import LogoutRgsa from "./LogoutRgsa";



export default function HeaderMenuRgsa() {
  const [showLogout,setshowLogout]= useState(false)

    return (

        <>
        <div class="container-fluid pe-3 ps-3 header-menuRgsa">
          <div class="conatiner pt-3">
            <div class="col-md-12 p-0 d-flex mb-3">
                <div class="col-2 logo-rgsa p-0">
                <img src="../../Assets/media/logos/rgsa-logo.svg" class="w-100 text-center"></img>
                </div>
                <div class="col-10">
                <div class="csearch-bar">
                  </div>
                  <div class="float-end ps-3" onClick={()=>setshowLogout(!showLogout)}>
                    <img src="../../Assets/Linqskills_Icons/profile.svg" class="cursor-pointer"></img>
                      <img src="../../Assets/Linqskills_Icons/profile-drop.svg" class="cursor-pointer"></img>
                        </div>
                        {showLogout && <LogoutRgsa/>}
                   </div>           
                     
            </div>
            </div>
                   
          </div>


            
        </>
    )
}

                                  
{/* <div className="container d-flex login-footer mobilefooter">
                                   
<div className="col-6 col-sm-12 ps-3 poweredby">
    <span>f <span className="span-bold">
      sss</span></span>
    <span className="ps-3 footertext">
    sdsd <span className="span-bold">
        dsdsss</span></span>
</div>
<div className="col-6 text-right pe-3 footerdetails display-desktop">
    <span className="ps-3">
    sdsd</span>
</div>

</div> */}