export const LevelRole = {
  levels: [
    { id: 1, name: "State", roles: [1] },
    { id: 2, name: "District", roles: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19] },
    { id: 3, name: "Block", roles: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34] },
  ],
  roles: [
    { id: 1, name: "Master Trainer" },
    { id: 2, name: "Elected Representative(DP)" },
    { id: 3, name: "Pramukh (DP)" },
    { id: 4, name: "Karobari Chairman" },
    { id: 5, name: "Education Committee Chairman" },
    { id: 6, name: "WCD Committee Chairman" },
	{ id: 7, name: "Construction Committee Chairman"}, 
	{ id: 8, name: "Social Justice Committee Chairman"}, 
	{ id: 9, name: "Agriculture Committee Chairman"}, 
	{ id: 10, name: "Health Committee Chairman"}, 
	{ id: 11, name: "Heath Officer"}, 
	{ id: 12, name: "PO-ICDS"}, 
	{ id: 13, name: "Animal Husbandary"}, 
	{ id: 14, name: "Agricultural Officer"}, 
	{ id: 15, name: "Taulka Development Officer (TDO)"}, 
	{ id: 16, name: "DC-SBM"}, 
	{ id: 17, name: "DDPC NREGA"}, 
	{ id: 18, name: "DLM GLPC"}, 
	{ id: 19, name: "Chitnish"},
	{ id: 20, name: "Sarpanch"}, 
	{ id: 21, name: "Up Sarpanch"}, 
	{ id: 22, name: "Elected Representative(GP)"}, 
	{ id: 23, name: "Block Level Master Trainer"}, 
	{ id: 24, name: "Block Samajik Samiti Chairman"}, 
	{ id: 25, name: "Block Karobari Samiti Member"}, 
	{ id: 26, name: "Talati"}, 
	{ id: 27, name: "Field Functionaries RD/PR"}, 
	{ id: 28, name: "Asha Worker"}, 
	{ id: 29, name: "Anganwadi Worker"}, 
	{ id: 30, name: "Line Dept Officials / Field Functionaries"}, 
	{ id: 31, name: "SHG Member"}, 
	{ id: 32, name: "PRI Member"}, 
	{ id: 33, name: "Women Elected Representative (GP)"}, 
	{ id: 34, name: "Women Elected Representative (BP)"},
],
};