import React from 'react';
//import Message from './components/message';



export default function MobileMenu() {

  return (
    <>
      
<div class="Mobile-menu bg-white p-3 pb-4 pt-4 display-mobile">
  <div class="d-flex justify-content-between">
  <div>
  <img src="../../Assets/Linqskills_Icons/ArrowLeft-Purple.svg" class="cursor-pointer"></img>

  </div>
<div class="language-select text-uppercase">
  <img src="../../Assets/Linqskills_Icons/Language_16.svg" class="pe-1 mb-1px"></img>
    <span class="align-self-center cursor-pointer">EN</span>
    </div>
    <div>
  <img src="../../Assets/Linqskills_Icons/Hamburger-24-Purple.svg" class=" cursor-pointer"></img>

  </div>
  </div>
</div>


          </>
      )
  
}


