import React from 'react';
//import Message from './components/message';
import NavOne from '../NavOne';
import AdminCourseCard from './AdminCourseCard';
import Footer from '../Shared/Footer';
import DeliveryModel from './DeliveryModel';
import SearchPanel from './SearchPanel';
import DropdownAdminbtn from './DropdownAdminbtn';
import AdminNavbarTwo from './AdminNavbarTwo';
import AdminMeetingDetails from './AdminMeetingDetails';
import LeftCard from './LeftCard';
import AdminAccordion from './AdminAccordion';
import TopicsList from '../Shared/TopicsList';

export default function AdminVcSession() {

  return (
    <>
      
          <div className="p-0">
     
                      <NavOne></NavOne>
                  
              <div class="container-fluid body-wrapper bg-default">
    
                vcsession
              <div class="container pt-3 pb-3 p-0">
                <div class="row">
                  {/* Kindly add TopicsList component here I have added but not been mapped  */}
                {/* <TopicsList/> */}
                <LeftCard/>

<AdminMeetingDetails/>
          
                
                </div>
</div>
                </div>
             
          </div>
         <Footer/>
          </>
      )
  
}


