import React from "react";


export default function DeliveryModel() {

  return (
    <>



<div class="container p-3">
                 
<h2 class="text-center">Trainer Delivery Model</h2>
   <div class="col-md-7 justify-content-around m-auto tdm-details">
        <div class="details-box">

                <div class="m-3 text-center">
                <img src="../../Assets/Linqskills_Icons/Admin/tdmTotal-multicolorgreen.svg" class="" />
                    <h6 class="tdm-text">Total</h6>
                    <h4>70</h4>
                </div>
                <div class="m-3 text-center">
                <img src="../../Assets/Linqskills_Icons/Admin/tdmActive-multicolorgreen.svg" class="" />
                    <h6 class="tdm-text">Ongoing</h6>
                    <h4>33</h4>
                </div>
        </div>
        <div class="details-box">
                <div class="total m-3 text-center">
                <img src="../../Assets/Linqskills_Icons/Admin/tdmCompleted-multicolorgreen.svg" class="" />
                    <h6 class="tdm-text">Completed</h6>
                    <h4>50</h4>
                </div>
                <div class="total m-3 text-center">
                <img src="../../Assets/Linqskills_Icons/Admin/tdmDraft-multicolorRed.svg" class="" />
                    <h6 class="tdm-text">Not Started</h6>
                    <h4>7</h4>
                </div>
      </div>

    </div>
</div>
</>

)

}
