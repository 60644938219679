import * as React from 'react';
//import Message from './components/message';
import Courseslist from './courseslist';
import NavOne from '../NavOne';
import Footer from '../Shared/Footer';
import { abort } from 'process';
import SkeletonCard from '../Shared/SkeletonCard';


class mycourses extends React.Component {
    render() {
        return (
            <>
      
        
            <div className="p-0">
         
                        <NavOne footer={true} ></NavOne>
               
                {/*<div className="container-fluid body-wrapper bg-default p-0">*/}
                {/*    <div className="display-mobile col-sm-12 col-xs-12 p-2">*/}
                {/*        */}{/* <Message></Message>*/}
                {/*    </div>*/}

                {/*</div>*/}

           

                <Courseslist></Courseslist>

            </div>
           <Footer/>
            </>
        )
    }
}
export default mycourses;

