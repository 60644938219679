import React, { useState, useEffect } from "react";
import { Redirect, useNavigate } from "react-router-dom";
import FooterRgsa from "./FooterRgsa";
import axios from "axios";
import { variables } from "../../components/Shared/Variables";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Translate from "../../components/Shared/Translate";
import LogoutRgsa from "../rgsaLogin/LogoutRgsa";
import LanguagePopupRgsa from "./languagePopupRgsa";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import ProgressBar from "./ProgressBar";
import Chatbot from "../CourseIndexRgsa/Chatbot";
import { apiCallingForCourseStarted } from "../../components/generalFunctions/apiCalling.js";
import {
  updateFilterVal,
  updateNodeVal,
  updateFilterRetained,
  updateIsBlockCourseIndexAPi,
  updateAccordianNo,
  updateIsContentNodeVal,
  updateRgsaResourceData
} from "../../features/CourseIndex/courseIndexSlice.js";

export default function WelcomeRgsa() {
  const [showLogout, setshowLogout] = useState(false)
  const nodeValue = useSelector((state) => state.courseindex.nodeData);
  // console.log("nodeValue : ", nodeValue);
  const [courselist, setCourseList] = useState([])
  const [totalLanguages, setTotalLanguages] = useState([]);
  const [batchID, setBatchID] = useState("8574");
  const [courseIndexSessions, setCourseIndexSessions] = useState([]);

  const [modules, setModules] = useState([]);
  const [langId, setLangId] = useState(2);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const isProject = useSelector(state => state.project.isProject);
  var Lan = cookies.get('language');
  // console.log("cookies.get('language') in Rgsa login page : ", cookies.get('language'));
  Lan = Lan != undefined ? Lan.substring(0, 2) : isProject.defaultLanguageID == 1 ? "EN" : isProject.defaultLanguageID == 2 ? "HI" : isProject.defaultLanguageID == 7 ? "GU" : "EN";

  var logindata = sessionStorage.getItem("login-userId");
  var UserFirstName = sessionStorage.getItem("login-firstName");
  var userId = logindata;
  const encodedValue = encodeURIComponent(userId);
  const [isLoader, setIsLoader] = useState(false);
  const [openLanguage, setopenLanguage] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(88);


  const getCourses = () => {
    // setIsLoader(true)
    axios.get(variables.API_URL + `api/MyRGSACourses?userid=${userId}&timeZone=330`, {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem("JWTToken")
      }
    })
      .then(response => {
        if (response.status === 404 || response.status === 401) {
          // logout
          // console.log("logging out with response: ")
          navigate('/')
        }
        // setIsLoader(false)
        // console.log(response?.data?.dataList); // Logging the response data
        setCourseList(response?.data?.dataList);
        sessionStorage.setItem("courseinfo", JSON.stringify(response?.data?.dataList));
      })
      .catch(error => {
        // setIsLoader(false)
        console.error('Error fetching data:', error);
      });
  }

  const nextPrevData = async () => {
    const nodeValFromLocalStorage = JSON.parse(sessionStorage.getItem("nodeVal") || "[]");
    const allTopics = nodeValFromLocalStorage.filter((it) => {
      return it.deliverymodename != ""
    })
    // // console.log("allTopics : ",allTopics);
    let nodeIds = "";
    const nodeIdsArr = allTopics.map((i) => {
      return i.nodeId
    })
    nodeIds = nodeIdsArr.join(",");
    let bodyForNextPrev = {
      "userId": userId,
      "batchId": batchID,
      "nodeids": nodeIds
    }
    // console.log("body for next prev : ", bodyForNextPrev);
    let resAfterFetchingNextPrev = await axios.post(variables.API_URL + `api/GetUserSessionv1`, bodyForNextPrev);
    // console.log("resAfterFetchingNextPrev : ", resAfterFetchingNextPrev);
    if (resAfterFetchingNextPrev?.data?.isSuccess) {
      sessionStorage.setItem("nextPrevData", JSON.stringify(resAfterFetchingNextPrev));
    }
    setIsLoader(false);
  }

  const getcourseIndex = async () => {
    var userId = await sessionStorage.getItem("login-userId");
    if (nodeValue.length > 0) {
      // console.log("inside nodeValue.length > 0 : ", nodeValue)
      let tempdataList = nodeValue.filter(
        (node) => node.nodeClassification === "Session"
      );
      // console.log("tempdataList : ", tempdataList);
      setCourseIndexSessions(tempdataList)
      var modules = nodeValue.filter((it) => {
        return (
          it.nodeClassification == "Module"
        );
      });
      setModules(modules);
    } else {
      setIsLoader(true);
      fetch(variables.API_URL + "api/RGSACourseIndex", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + sessionStorage.getItem("JWTToken")

        },
        body: JSON.stringify({
          courseNodeId: "18921",
          userId: userId,
          batchId: "8574",
        }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          // console.log("course api result : ", data.dataList);
          if (data?.isSuccess) {
            let tempdataList = data.dataList.filter(
              (node) => node.nodeClassification === "Session"
            );
            // console.log("tempdataList : ", tempdataList);
            setCourseIndexSessions(tempdataList)
            dispatch(updateNodeVal(data.dataList));
            await sessionStorage.setItem("nodeVal", JSON.stringify(data?.dataList));
            var modules = data.dataList.filter((it) => {
              return (
                it.nodeClassification == "Module"
              );
            });
            setModules(modules);
            var isAnyTopicCompleted = data.dataList.filter((it) => {
              return (
                it.completionStatus == "Completed" &&
                it.nodeClassification == "Session"
              );
            });
            if (isAnyTopicCompleted.length > 0) {
              apiCallingForCourseStarted(userId, batchID);
            }
            nextPrevData();
          } else {
            setIsLoader(false);
            throw new Error("Data is not successful");
          }
        })
        .catch((error) => {
          // console.log("error : ", error);
          setIsLoader(false);
        })
    }
  };


  const getCourseIndexData = () => {
    // setIsLoader(true)
    fetch(variables.API_URL + "api/RGSAContentCourseIndex", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + sessionStorage.getItem("JWTToken")

      },
      body: JSON.stringify({
        courseNodeId: "18921",
        userId: userId,
        batchId: "8574",
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data?.isSuccess) {
          // console.log("RGSAContentCourseIndex result : ", data?.dataList);
          let tempdataList = data?.dataList.filter(
            (node) => node.nodeClassification === "Sub"
          );
          // console.log("RGSAContentCourseIndex sessions : ", tempdataList);
          dispatch(updateRgsaResourceData(tempdataList));
        } else {
          setIsLoader(false);
          throw new Error("Data is not successful");
        }
      })
      .catch((error) => {
        // console.log("error : ", error);
        setIsLoader(false);
      })

  }


  const navigate = useNavigate()

  const goToIndexPage = (e, data) => {
    e.preventDefault();
    sessionStorage.setItem("coursedata", JSON.stringify(data));
    sessionStorage.setItem("isCourseStarted", data?.isCourseStarted)
    sessionStorage.setItem("moduleId",data.nodeId);
    navigate(`/RgsaCourseIndex/${data.nodeId}`)
    ApiCallingForAuto(data);
  }

  function closeLanguage() {
    setopenLanguage(false)
  }

  const ApiCallingForAuto = (data) => {
    // console.log("Api calling for auto certificate generation : ", data);
    var userId = sessionStorage.getItem("login-userId");
    if (data.isCourseStarted == "True" && data.courseCompleted == 1 && data.markedCompleted == 0 && data.generateCertificate == "Auto") {
      // alert("calling api");
      const body = {
        "userId": userId,
        "courseId": data.courseId,
        "projectId": data.projectId,
        "batchId": data.batchId
      }
      // alert("api/GenearateCertificate_v1 calling");
      axios.post(variables.API_URL + 'api/GenearateCertificate_v1', body).then((res) => {
        // console.log("response from api ApiCallingForAuto : ", res);
      }).catch((err) => {
        // console.log("error from api ApiCallingForAuto : ", err);
      });
    } else {
      // console.log("no need to call api");
    }
  }

  const goToCertificate = async (e, data) => {
    // console.log("goToCertificate calling : ");
    e.preventDefault();
    await sessionStorage.setItem("coursedata", JSON.stringify(data));
    navigate('/RgsaCertificate')
  }

  useEffect(() => {
    getCourses();
    getcourseIndex();
    getCourseIndexData();
  }, [])

  // console.log("courselist : ",courselist);
  return (
    <>
      <div class="rgsa-wrapper">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoader}

        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {openLanguage ?
          <LanguagePopupRgsa loginLangPopUp={true} setLangId={setLangId} closeEvent={closeLanguage} show={true} okEvent={closeLanguage} availableLanguages={totalLanguages} headerKey="Login014" hideDefaultCheck="true" />
          : ""}


        <div class="container-fluid p-0 welcome-rgsa">
          <div class="conatiner profile-rgsa ">
            <div className="col-md-12 p-3 d-flex">
              <div class="col-6">
                <div className="language-select rgsa-lan float-start text-uppercase" onClick={() => setopenLanguage(true)}>
                  <img src="../../Assets/Linqskills_Icons/Language_16.svg" className="pe-1 mb-1px"></img><span class="align-self-center cursor-pointer">{Lan}</span>
                </div>
              </div>

              <div class="col-6">
                <div class="csearch-bar">
                </div>
                <div class="float-end ps-3" onClick={() => setshowLogout(!showLogout)}>
                  <img src="../../Assets/Linqskills_Icons/rgsa-profile.svg" class="cursor-pointer"></img>
                  <img src="../../Assets/Linqskills_Icons/rgsaProfile-DrownArrow.svg" class="cursor-pointer"></img>
                </div>
                {showLogout && <LogoutRgsa />}
              </div>

            </div>


            <div className="logo-rgsa text-center mt-2">
              <img
                src="../../Assets/media/logos/rgsa-Logo.svg"
                className="text-center"
              ></img>

            </div>
            <Chatbot></Chatbot>
            <div class="backdrop-box">
              <div class="pb-2 pe-3 ps-3 text-center">
                <span class="heading-one-rgsa mb-4 orange-rgsa">
                  <Translate textKey="mycourse004" /> {UserFirstName != null && UserFirstName != "" ? UserFirstName : ""}
                </span>
                <h4 class="heading-two-rgsa">    <Translate textKey="mycourse010" /></h4>

              </div>

              {
                courselist[0]?.certificate != "" && courselist.length > 0  ?
                  <div class="certificate-btn p-3 pt-2">
                    <button  onClick={(e)=>goToCertificate(e,courselist)}  class="rgs-primarybtn">  <Translate textKey="mycourse007" />
                      <img src="../../Assets/Linqskills_Icons/arrowright-rgsa-active.svg" class="w-auto float-end"></img>
                    </button>
                  </div> : ""
              }
              <div class="p-3 pb-2 d-flex">
                <div class="row">
                  <h4 class="heading-two-rgsa"> <Translate textKey="mycourse011" /></h4>

                  {
                    modules.length > 0 ?
                      <>
                        {
                          modules.map((item, idx) => {
                            let moduleCompleted = 0;
                            // // console.log(`co : `, courseIndexSessions[idx]);
                            let sessionsOfModule = courseIndexSessions.filter((it) => {
                              return it.parentNodeId == item.nodeId
                            })
                            // console.log("final sessions for a module : ", sessionsOfModule.length);
                            let completedSessionsOfModule = courseIndexSessions.filter((it) => {
                              return it.parentNodeId == item.nodeId && it.completionStatus == "Completed"
                            })
                            // console.log("completedSessionsOfModule : ", completedSessionsOfModule.length)
                            moduleCompleted = Math.round((completedSessionsOfModule.length / sessionsOfModule.length) * 100)
                            // console.log("moduleCompleted percentage : ", moduleCompleted);
                            return (
                              <>
                                <div class="course-box-rgsa mb-2 p-3 bg-white " onClick={(e) => goToIndexPage(e, item)}>
                                  <div class="text-start">
                                    <h4 class="heading-two-rgsa">{item.nodeName}</h4>
                                  </div>
                                  <div class="text-center">
                                    {/* <img src="../../Assets/Linqskills_Icons/course-img2.svg" class="w-auto"></img> */}
                                    <img src={item.nodethumbnailurl != "" ? `${variables.AWSPath}${variables.AWSFolder}${item.nodethumbnailurl}` : "../../Assets/Linqskills_Icons/course-img2.svg" } class="w-100"></img>
                                  </div>
                                  <div class="d-flex d-flex justify-content-between mt-2">
                                    <div class="d-flex w-100 align-items-center">
                                      <h5 class="progress-text m-0">{moduleCompleted}%</h5>
                                      <ProgressBar completionPercentage={moduleCompleted} />
                                    </div>
                                    <div>
                                      <img src="../../Assets/Linqskills_Icons/rgsa-rightbtn.svg" class="w-auto "></img>
                                      
                                    </div>
                                  </div>
                                  {/* <ProgressBar completionPercentage={completionPercentage} /> */}
                                </div>
                              </>
                            )
                          })
                        }
                      </> : ""
                  }


                  {/* <div class="course-box-rgsa p-2 mb-2  bg-white ">
                    <div class="text-start">
                      <h4 class="heading-two-rgsa">પંચાયત વિકાસ યોજના તાલીમ</h4>
                    </div>
                    <div class="text-center">
                      <img src="../../Assets/Linqskills_Icons/Development-plan.svg" class="w-auto"></img>

                    </div>

                    <div class="d-flex d-flex justify-content-between mt-2">
                      <div class="d-flex w-100 align-items-center">
                        <h5 class="progress-text m-0">88%</h5>
                        <div class="progress w-100 ms-2 me-2">

                          <div class="progress-bar w-50" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>

                      <div>
                        <img src="../../Assets/Linqskills_Icons/rgsa-rightbtn.svg" class="w-auto "></img>

                      </div>

                    </div>

                  </div>
                  <div class="course-box-rgsa mb-2 p-2  bg-white ">
                    <div class="text-start">
                      <h4 class="heading-two-rgsa">ડિજિટલ સાક્ષરતા</h4>
                    </div>
                    <div class="text-center">
                      <img src="../../Assets/Linqskills_Icons/Digital-Litracy.svg" class="w-auto"></img>

                    </div>

                    <div class="d-flex d-flex justify-content-between mt-2">
                      <div class="d-flex w-100 align-items-center">
                        <h5 class="progress-text m-0">88%</h5>
                        <div class="progress w-100 ms-2 me-2">

                          <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>

                      <div>
                        <img src="../../Assets/Linqskills_Icons/rgsa-rightbtn.svg" class="w-auto "></img>

                      </div>

                    </div>

                  </div> */}
                </div>
                {/* <div class="col-md-6 ms-2 w-100">
                  <div class="course-box-rgsa mb-2 p-2  bg-white ">
                    <div class="text-start">
                      <h4 class="heading-two-rgsa">કાર્બન તટસ્થતા</h4>
                    </div>
                    <div class="text-center">
                      <img src="../../Assets/Linqskills_Icons/Carbon-neutrality.svg" class="w-auto"></img>

                    </div>

                    <div class="d-flex d-flex justify-content-between mt-2">
                      <div class="d-flex w-100 align-items-center">
                        <h5 class="progress-text m-0">88%</h5>
                        <div class="progress w-100 ms-2 me-2">

                          <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>

                      <div>
                        <img src="../../Assets/Linqskills_Icons/rgsa-rightbtn.svg" class="w-auto "></img>

                      </div>

                    </div>

                  </div>
                  <div class="course-box-rgsa p-2 mb-2  bg-white ">
                    <div class="text-start">
                      <h4 class="heading-two-rgsa">ઇ- ગવર્નનેસ</h4>
                    </div>
                    <div class="text-center">
                      <img src="../../Assets/Linqskills_Icons/e-governance.svg" class="w-auto"></img>

                    </div>
                    <div class="d-flex d-flex justify-content-between mt-2">
                      <div class="d-flex w-100 align-items-center">
                        <h5 class="progress-text m-0">88%</h5>
                        <div class="progress w-100 ms-2 me-2">

                          <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>

                      <div>
                        <img src="../../Assets/Linqskills_Icons/rgsa-rightbtn.svg" class="w-auto "></img>

                      </div>

                    </div>

                  </div>
                  <div class="course-box-rgsa mb-2 p-2  bg-white ">
                    <div class="text-start">
                      <h4 class="heading-two-rgsa">પોતાના સ્ત્રોત આવક</h4>
                    </div>
                    <div class="text-center">
                      <img src="../../Assets/Linqskills_Icons/Revenue.svg" class="w-auto"></img>

                    </div>

                    <div class="d-flex d-flex justify-content-between mt-2">
                      <div class="d-flex w-100 align-items-center">
                        <h5 class="progress-text m-0">88%</h5>
                        <div class="progress w-100 ms-2 me-2">

                          <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>

                      <div>
                        <img src="../../Assets/Linqskills_Icons/rgsa-rightbtn.svg" class="w-auto "></img>

                      </div>

                    </div>

                  </div>
                </div> */}
              </div>
            </div>


            {/* {courselist.map((course) => {
              return <div class="coursecard-rgsa pb-4">
                <div class="mb-4" onClick={(e) => goToIndexPage(e, course)}>
                  <img
                    src={`${variables.AWSPath}${variables.AWSFolder}${course.icon}`}
                    class="cursor-pointer w-100 course-img"
                  ></img>
                </div>
                <div class="pe-3 ps-3">
                  <div class="details-g d-flex mb-2">
                    <div class="me-2">
                      <img
                        src="../../Assets/Linqskills_icons/content-gujrathi.svg"
                        class=""
                      ></img>
                    </div>
                    <div>
                      <span class="heading-one-rgsa line-clamp-one">
                        {course.courseName}
                      </span>
                    </div>
                  </div>
                  <div class="details-gtime d-flex mb-4">
                    <div class="me-2">
                      <img
                        src="../../Assets/Linqskills_icons/time-gujrathi.svg"
                        class=""
                      ></img>
                    </div>
                    <div>
                      <span>{course.courseDuration}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-2 ps-3 pe-3">
                  <button class="rgs-primarybtn" onClick={(e) => goToIndexPage(e, course)}>
                    {course.isCourseStarted === "False" ? "કોર્સ શરૂ કરો" : "કોર્સ ચાલુ રાખો"}
                    <img
                      src="../../Assets/Linqskills_icons/arrowright-24-white.svg"
                      class="float-end"
                    ></img>
                  </button>
                </div>
                {(course.markedCompleted == 1 || course.courseCompleted == 1 || course.batchCompleted == 1) && <div class="col-md-12 mb-2 ps-3 pe-3">
                  <button onClick={(e) => goToCertificate(e, course)} class="rgs-primarybtn">
                    સરતિફિકેટ જુઓ
                    <img
                      src="../../Assets/Linqskills_icons/arrowright-24-white.svg"
                      class="float-end"
                    ></img>
                  </button>
                </div>}
              </div>
            })} */}
            <div class="text-center pb-5 rgsa-background">
              <img src="../../Assets/media/logos/rgsa-panchayat.svg" ></img>
            </div>
          </div>
        </div>
        
        <FooterRgsa> </FooterRgsa>
      </div>
    </>
  );
}

{
  /* <div className="container d-flex login-footer mobilefooter">
                                   
<div className="col-6 col-sm-12 ps-3 poweredby">
    <span>f <span className="span-bold">
      sss</span></span>
    <span className="ps-3 footertext">
    sdsd <span className="span-bold">
        dsdsss</span></span>
</div>
<div className="col-6 text-right pe-3 footerdetails display-desktop">
    <span className="ps-3">
    sdsd</span>
</div>

</div> */
}
