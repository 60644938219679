
import React, { useState,useEffect } from "react";
import { Redirect } from "react-router-dom";
import Translate from '../Shared/Translate';
import Cookies from 'universal-cookie';
import Accordion from "../Shared/Accordion";
import { title } from "process";
//import PopupOne from '../Shared/PopupOne';

export default function CourseIndexleftbox(props) {
  

    const titleHandler = (val) =>{
        props.setTitle(val);
    }
    return (
        <>
        <div class="mb-3 col-lg-3 col-sm-12 col-xs-12">
        {/* <input id="menu__toggle" type="checkbox"  />
        {
            !props.showHamburgerIcon ?
        <img src="../../Assets/Linqskills_Icons/popup-close.svg" class="display-mobile float-end hamburger-close" onClick={props.hideHamburger}></img>
        // : <label class="menu__btn display-mobile" for="menu__toggle" onClick={props.showHamburger}>
        //       <span></span>
        //       </label>
                : <img src="../../Assets/Linqskills_Icons/LeftMenu-hamburger.svg" class="display-mobile float-end leftmenu-hamburger" onClick={props.showHamburger}></img>
} */}
      {/* <div class="col-lg-3 col-sm-12 col-xs-12 course-indexCard display-desktop">  */}
  
           
                <div id="" class={`card primary-radius card-modify h-auto ${props.showHamburgerIcon ? "leftmenu-none" : ""}`}>
                    <img src={props.courseIcon}/*"../../Assets/images/image1465.png "*/  class="display-desktop card-img"></img>
                    <div class="card-body cardbody-height h-auto">
                        {/*<span class="course-type">{ props.sectorName}</span>*/}
                        {/* <img src="../../Assets/Linqskills_Icons/More_24.svg" class="display-mobile float-end"></img> */}
                        <h4 class="mt-2 display-desktop">{ props.courseName}</h4>

                        {/* <div class="duration m-0">
                            <img src="../../Assets/Linqskills_Icons/time.svg" class="" /><p class="mt-6px">12:00 Min</p>
                        </div>

                        <p class="line-clamp-three m-0 h7 mt-1  pb-3">
                            This course includes: 6 Virtual Session, 4 Practical, 9 Self study, 3 Assessment, 2 Assignment, 3 Feedback session.
                        </p>
           */}
                    </div>

         
                    <div class="coursecard-list ps-3 pb-3">

                  <div  class={`d-flex pb-2 ps-2 cursor-pointer courselist-hover ${props.selectedMenuItem === 'CO' ? 'active-topic' : 'pt-2 me-3'}`} onClick={() => {titleHandler("Content");props.onMenuItemSelect('CO')}} >
                            <img src={`../../Assets/Linqskills_Icons/content-${props.selectedMenuItem === 'CO' ? 'active' : 'grey'}.svg`} class="pe-2"></img>
                            <span class="cursor-pointer"><Translate textKey="courseindex003" /> </span>
                        </div> 
                        {/* <div class={`d-flex pb-2 ps-2 cursor-pointer courselist-hover ${props.selectedMenuItem === 'RL' ? 'active-topic' : 'pt-2 me-3'}`} onClick={() => props.onMenuItemSelect('RL')}>
                            <img src={`../../Assets/Linqskills_Icons/resource-${props.selectedMenuItem === 'RL' ? 'purple' : 'grey'}.svg`} class="pe-2"></img>
                            <span class="cursor-pointer">Resource Library</span>
                        </div>  */}
                       
                       <div   class={`d-flex pb-2 ps-2 cursor-pointer courselist-hover ${props.selectedMenuItem === 'OL' ? 'active-topic' : 'pt-2 me-3'}`} onClick={() => {titleHandler("Online Lectures");props.onMenuItemSelect('OL')}}>
                            <img src={`../../Assets/Linqskills_Icons/VC-24-${props.selectedMenuItem === 'OL' ? 'purple' : 'Default'}.svg`} class="pe-2"></img>
                            <span class="cursor-pointer"><Translate textKey="courseindex004" /> </span>
                        </div> 
                       {/*  <div class={`d-flex pb-2 ps-2 cursor-pointer courselist-hover ${props.selectedMenuItem === 'MS' ? 'active-topic' : 'pt-2 me-3'}`} onClick={() => props.onMenuItemSelect('MS')}>
                            <img src={`../../Assets/Linqskills_Icons/messages-${props.selectedMenuItem === 'MS' ? 'active' : 'grey'}.svg`} class="pe-2"></img>
                            <span class="cursor-pointer">Messages</span>
                        </div> 
                        <div class={`d-flex pb-2 ps-2 cursor-pointer courselist-hover ${props.selectedMenuItem === 'NO' ? 'active-topic' : 'pt-2 me-3'}`} onClick={() => props.onMenuItemSelect('NO')}>
                            <img src={`../../Assets/Linqskills_Icons/notes-${props.selectedMenuItem === 'NO' ? 'active' : 'grey'}.svg`} class="pe-2"></img>
                            <span class="cursor-pointer">Notes</span>
                        </div>
                        <div class={`d-flex pb-2 ps-2 cursor-pointer courselist-hover ${props.selectedMenuItem === 'BM' ? 'active-topic' : 'pt-2 me-3'}`} onClick={() => props.onMenuItemSelect('BM')}>
                            <img src={`../../Assets/Linqskills_Icons/bookmark-${props.selectedMenuItem === 'BM' ? 'active' : 'grey'}.svg`} class="pe-2"></img>
                            <span class="cursor-pointer">Bookmarks</span>
                        </div> */}
                        <div class={`d-flex pb-2 ps-2 cursor-pointer courselist-hover ${props.selectedMenuItem === 'CT' ? 'active-topic' : 'pt-2 me-3'}`} onClick={() => props.onMenuItemSelect('CT')}>
                            <img src={`../../Assets/Linqskills_Icons/certificate-${props.selectedMenuItem === 'CT' ? 'purple' : 'grey'}.svg`} class="pe-2"></img>
                            <span class="cursor-pointer"><Translate textKey="courseindex005" /></span>
                        </div>
                        {/* <div class={`d-flex pb-2 ps-2 cursor-pointer courselist-hover ${props.selectedMenuItem === 'PR' ? 'active-topic' : 'pt-2 me-3'}`} onClick={() => props.onMenuItemSelect('PR')}>
                            <img src={`../../Assets/Linqskills_Icons/performance-${props.selectedMenuItem === 'PR' ? 'purple' : 'Grey'}.svg`} class="pe-2"></img>
                            <span class="cursor-pointer">Performance</span>
                        </div> */}

                    </div>
                </div>
              </div>

{/* 
          there is a div */}

{/* 
            <div class="hamburger-menu display-mobile">
              {/*This is hidden till demo*
              <input id="menu__toggle" type="checkbox" onClick={props.onHamburgerClick}></input>
               <label class="menu__btn" for="menu__toggle">
                  <span></span>
              </label>
       
                <div class="col-lg-3 col-sm-12 col-xs-12 course-indexCard menu__box">
                    <section class="modal overlay"></section>
                    <div class="card primary-radius">
                        <img src="../../Assets/images/image1465.png"></img>
                        <div class="card-body cardbody-height h-auto">
                            <span class="course-type">Automotive</span>
                            <img src="../../Assets/Linqskills_Icons/More_24.svg" class="display-mobile float-end"></img>
                            <h4 class=" mt-2">Mastering the Art of Sales</h4>

                            <div class="duration m-0">
                                <img src="../../Assets/Linqskills_Icons/time.svg" class="" /><p class="mt-6px">12:00 Min</p>
                            </div>

                            <p class="line-clamp-three m-0 h7 mt-1">
                                This course includes: 6 Virtual Session, 4 Practical, 9 Self study, 3 Assessment, 2 Assignment, 3 Feedback session.
                            </p>

                        </div>
                        <div class="coursecard-list ps-3 pb-3">

                            <div class="d-flex pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/content-grey.svg" class="pe-2"></img>
                                <span>Content</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/resource-grey.svg" class="pe-2"></img>
                                <span>Resource Library</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/messages-disabled.svg" class="pe-2"></img>
                                <span>Messages</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2 active-topic">
                                <img src="../../Assets/Linqskills_Icons/notes-active.svg" class="pe-2"></img>
                                <span>Notes</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/bookmarks-disabled.svg" class="pe-2"></img>
                                <span>Bookmarks</span>
                            </div>
                            <div class="d-flex pt-2 pb-2 ps-2">
                                <img src="../../Assets/Linqskills_Icons/certificate-diabled.svg" class="pe-2"></img>
                                <span>Certificates</span>
                            </div>

                        </div>
                    </div>
                  

                </div>
            </div> */}
        </>
    )

}


