import React from "react";



//import PopupOne from '../Shared/PopupOne';

export default function OnGoing() {
    return (
        <>
            <div class="reminder mb-3">
                {/* <img src="../../Assets/Linqskills_Icons/reminder-pending.svg" class="" /> */}
                <img src="../../Assets/Linqskills_Icons/admin/ongoing.svg" class="me-1 ms-1" />
                <span className="self-align-center">On Going</span>
            </div>
        </>
    )

}


